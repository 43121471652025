import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ImportManagerContactToolbar from './ImportManagerContactToolbar';
import {
  setQuery, downloadFilteredContacts, recalculateContact, setFilters, resetFilters,
  blacklistContactsSelection, unblacklistContactsSelection,
} from '../../../../services/store/features/contacts/actions';
import { downloadAllContactFromSource } from '../../../../services/store/features/sources/actions';

const mapStateToProps = (state) => ({
  query: state.contacts.query,
  filters: state.contacts.filters,
  accounts: Array.from(new Set(state.sources?.sources
    ?.find((project) => project.id === state.contacts.active)?.accounts
    ?.reduce((acc, curr) => [...acc, ...curr.issuer?.map((i) => i.id)], []))) || [],
  languages: state.contacts.contacts
    .find((project) => project.id === state.contacts.active)?.languages || [],
  segments: Array.from(new Set(state.contacts.contacts
    .find((project) => project.id === state.contacts.active)?.data
    ?.reduce((acc, curr) => [...acc, curr.segment], []))),
  shouldBlacklist: (state.contacts.contacts
    ?.find((project) => project.id === state.contacts.active)?.data
    ?.filter((contact) => contact.isSelected === true)
    ?.reduce((acc, curr) => {
      if (curr.segment === acc) {
        return acc;
      }
      return '1';
    }, '7')) === '1',
  selectedContacts: state.contacts.contacts
    ?.find((project) => project.id === state.contacts.active)?.data
    ?.filter((contact) => contact.isSelected === true).length,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setQuery,
    downloadAllContactFromSource,
    downloadFilteredContacts,
    recalculateContact,
    setFilters,
    resetFilters,
    blacklistContactsSelection,
    unblacklistContactsSelection,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportManagerContactToolbar);
