const SEGMENTS = {
  1: 'Default',
  2: 'Ouvert ou Click > 2',
  3: 'En cours d\'investissement',
  4: 'Interessé',
  5: 'A investi',
  6: 'Unsubscribe',
  7: 'Blacklist p2p',
  8: 'Soft bounce',
  9: 'Bounce',
  10: 'Blacklist Ayomi',
};

export const SEGMENTS_TYPE = {
  0: 'owner',
  1: 'associate',
  2: 'ambassador',
  3: 'undefined',
};

export const USERS_MAIL_STATE = {
  0: 'Abonné',
  1: 'Bounce',
  2: 'Désabonné',
  3: 'A investi',
};

export default SEGMENTS;
