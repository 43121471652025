import React, { useState } from 'react';
import { Error, FileCopy } from '@material-ui/icons';
import AccountLoading from '../../../../components/library/Loading';
import styles from './LogInPopUp.module.css';
import Button from '../../../../components/library/Button/Button';
import ErrorAnimation from '../../../../components/library/ErrorAnimation/ErrorAnimation';
import copyTextToClipboard from '../../../../utils/copyToClipboard';

export default function LogInPopUp({
  status, close, error, url,
}) {
  const [isCopy, setIsCopy] = useState(false);
  const [isCopySupported, setIsCopySupported] = useState(true);

  const handleUrlCopy = () => {
    const onSuccess = () => {
      setIsCopy(true);
      window.setTimeout(() => setIsCopy(false), 1000);
    };
    const onError = (err) => {
      setIsCopy(false);
      setIsCopySupported(false);
      console.error('[ERROR]: ', err);
    };
    copyTextToClipboard(url, onSuccess, onError);
  };

  return (
    <div className={styles.background}>
      <section className={styles.modal}>
        {
          (status === 'LOADING')
          && (
            <>
              <h3 className={styles.title}> Login as user ongoing ... </h3>
              <AccountLoading />
              <Button
                variant="outline"
                label="Cancel"
                onButtonClick={close}
                className={styles.button}
              />
            </>
          )
        }
        {
          (status === 'FAILED')
          && (
            <>
              <h3 className={styles.title}>
                Login as user failed
              </h3>
              <p className={styles.description}>
                { error }
              </p>
              <ErrorAnimation />
              <Button
                label="OK"
                onButtonClick={close}
                className={styles.button}
              />
            </>
          )
        }
        {
          (status === 'SUCCEEDED')
          && (
            <>
              <h3 className={styles.title}>
                Login as user succeeded
              </h3>
              <p className={styles.description}>
                Open a new incognito window and paste the following link
              </p>
              {
                (isCopySupported)
                  ? (
                    <Button
                      type="custom"
                      label={(!isCopy) ? 'Copy the url' : 'Copied !'}
                      variant="ghost"
                      icon={<FileCopy className={styles.icon} />}
                      className={styles.custom}
                      disabled={isCopy}
                      onButtonClick={handleUrlCopy}
                    />
                  )
                  : (
                    <p className={styles.error}>
                      <span className={styles.lineHeight}>
                        <Error className={styles.icon} />
                      </span>
                      <span>
                        Direct copy to clipboard is not supported yet by your browser.
                        Please copy directly the following link by hand.
                      </span>
                    </p>
                  )
              }
              <p className={styles.token}>
                { url }
              </p>
              <Button
                label="Close"
                className={styles.button}
                onButtonClick={close}
              />
            </>
          )
        }
      </section>
    </div>
  );
}
