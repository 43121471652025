import React, { useState, useEffect } from 'react';

import Button from '../../../components/library/Button/Button';
import ContactDetails from '../../../components/ContactDetails';
import * as statuses from '../../../services/store/status';
import styles from './ImportManagerContactData.module.css';

export default function ImportManagerContactData({
  contactStatus, contacts, details, id, actions,
}) {
  const [selectedMail, setSelectedMail] = useState('');
  useEffect(() => {
    actions.setActiveProject(id);
    if (contactStatus === statuses.IDLE) {
      actions.fetchContactsByProjectId(id);
    }
  }, []);
  return (
    <main className={styles.details}>
      <div className={styles.toolbar}>
        <input
          list="contact-list"
          placeholder="Selectionner un contact"
          value={selectedMail}
          onChange={(e) => setSelectedMail(e.target.value)}
        />
        <datalist id="contact-list">
          {
          contacts && contacts.map((contact) => (
            <option
              key={contact.emailGeneral}
              value={contact.emailGeneral}
            >
              { contact.emailGeneral }
            </option>
          ))
        }
        </datalist>
        <Button
          label="Aficher les données du contact"
          onButtonClick={() => actions.setSelectedContactsByProject(selectedMail, id)}
        />
      </div>
      {
        (details) && <ContactDetails details={details} />
      }
    </main>
  );
}
