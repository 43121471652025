import React, { useState, useEffect } from 'react';

import styles from './MailFinder.module.css';

export default function MailFinder({
  id, contacts, setExchangeId, actions,
}) {
  const [tabToDisplay, setTabToDisplay] = useState('contacts');
  const [mailToDisplay, setMailToDisplay] = useState('all');
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (!contacts || contacts?.status === 'IDLE') {
      actions.fetchContactsByProjectId(id);
    }
  }, [contacts]);

  const getMailsToDisplay = () => {
    if (mailToDisplay === 'all') {
      return contacts.data
        .reduce((acc, curr) => [
          ...acc,
          ...(Array.isArray(curr.exchanges) ? curr.exchanges : []),
        ], [])
        .filter((mail) => {
          let mailStr = `${mail.uuid} ${mail.date}`;
          mailStr = mailStr.toLowerCase();
          return (mailStr.search(query.toLowerCase()) !== -1);
        });
    }
    const activeContact = contacts.data.find((contact) => contact.emailGeneral === mailToDisplay);
    return (activeContact?.exchanges.length > 0)
      ? activeContact.exchanges.filter((mail) => {
        let mailStr = `${mail.uuid} ${mail.date}`;
        mailStr = mailStr.toLowerCase();
        return (mailStr.search(query.toLowerCase()) !== -1);
      })
      : null;
  };

  const handleTabChange = (value) => {
    setTabToDisplay(value);
    setQuery('');
  };

  return (
    <div className={styles.finder}>
      <div className={styles.row}>
        <button
          type="button"
          className={(tabToDisplay === 'contacts') ? styles.selected : styles.button}
          onClick={() => handleTabChange('contacts')}
        >
          Contacts
        </button>
        <button
          type="button"
          className={(tabToDisplay === 'mails') ? styles.selected : styles.button}
          onClick={() => handleTabChange('mails')}
        >
          Mails
        </button>
      </div>
      {
        (contacts && contacts?.data?.length !== 0 && contacts?.status === 'SUCCEEDED')
        && (
          <input
            placeholder={`Rechercher un ${(tabToDisplay === 'contacts') ? 'contact' : 'mail'}`}
            className={styles.searchbar}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        )
      }
      <div className={styles.container}>
        { (contacts && contacts?.data?.length === 0 && contacts?.status === 'SUCCEEDED')
          && (
          <p>
            {`Aucun ${tabToDisplay} disponible`}
          </p>
          )}
        {
            (contacts?.status === 'SUCCEEDED' && tabToDisplay === 'contacts')
            && (
              <ul className={styles.list}>
                {contacts.data
                  .filter((contact) => {
                    let contactToStr = `${contact.emailGeneral} ${contact.firstName} ${contact.lastName} ${contact.segment}`;
                    contactToStr = contactToStr.toLowerCase();
                    return (contactToStr.search(query.toLowerCase()) !== -1);
                  })
                  .map((contact) => (
                    <li key={contact.id}>
                      <button
                        type="button"
                        onClick={() => {
                          setMailToDisplay(contact.emailGeneral);
                          handleTabChange('mails');
                        }}
                      >
                        { contact.emailGeneral }
                      </button>
                    </li>
                  ))}
              </ul>
            )
          }
        {
              (contacts?.status === 'SUCCEEDED' && tabToDisplay === 'mails')
              && (
                <ul className={styles.list}>
                  { getMailsToDisplay()
                    ?.map((exchange) => (
                      <li key={exchange.uuid}>
                        <button
                          type="button"
                          className={styles.listButton}
                          onClick={() => setExchangeId(exchange.uuid)}
                        >
                          <p className={styles.date}>{ exchange.date }</p>
                          <p className={styles.id}>{ exchange.uuid }</p>
                        </button>
                      </li>
                    )) || <p> Aucun échange trouvé </p>}
                </ul>
              )
            }
      </div>
    </div>
  );
}
