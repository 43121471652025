import React from 'react';

import Loading from '../library/Loading';
import ContactDetailsLog from './ContactDetailsLog';
import * as statuses from '../../services/store/status';

import styles from './ContactDetails.module.css';

export default function ContactDetails({ details }) {
  if (details.status === statuses.FAILED) {
    return (
      <div>
        <p>
          Une erreur est survenue lors du chargement des données
        </p>
        <p>
          { details.error }
        </p>
      </div>
    );
  }
  if (details.status === statuses.SUCCEEDED) {
    return (
      <div className={styles.details}>
        <div className={styles.header}>
          <h2>
            { (details.profil?.firstName && details.profil?.firstName !== '')
              ? `${details.profil?.firstName} ${details.profil?.lastname}`
              : details.profil?.emailGeneral}
          </h2>
          <div className={styles.row}>
            <p>
              Salaire :
              {' '}
              <b>{ details.profil.salary.threshold }</b>
            </p>
            <p>
              language :
              {' '}
              <b>{ details.profil.language }</b>
            </p>
            <p>
              Emails :
              {' '}
              <b>{ details.profil.emails.join(' / ') }</b>
            </p>
          </div>
        </div>
        <div className={styles.main}>
          <div>
            <h3 className={styles.subtitle}>
              Segmentation
            </h3>
            <div className={styles.grid}>
              {
                Object.keys(details.segmentation).map((detail) => {
                  if (detail === 'exchanges') return null;
                  if (detail === 'proximity') {
                    return (
                      <div
                        className={styles.segmentation}
                        key={detail}
                      >
                        <p>
                          proximity_threshold
                        </p>
                        <b>
                          { details.segmentation[detail].threshold}
                        </b>
                      </div>
                    );
                  }
                  if (detail === 'mailSegment') {
                    return null;
                  }
                  if (details.segmentation[detail] === '<non-serializable>') {
                    return null;
                  }
                  return (
                    <div
                      className={styles.segmentation}
                      key={detail}
                    >
                      <p>
                        { detail }
                      </p>
                      <b>
                        { details.segmentation[detail] }
                      </b>
                    </div>
                  );
                })
            }
              {
                Object.keys(details?.segmentation?.mailSegment).map((i) => (
                  <div
                    className={styles.segmentation}
                    key={i}
                  >
                    <p>
                      { i }
                    </p>
                    <b>
                      { details.segmentation.mailSegment[i] }
                    </b>
                  </div>
                ))
            }
            </div>
          </div>
          <hr className={styles.separator} />
          <div>
            <h3 className={styles.subtitle}>
              Event
            </h3>
            {
              (details['event-track'].actions && details['event-track'].actions.length > 0)
                ? (
                  details['event-track'].actions.map((action, index) => (
                    <ContactDetailsLog
                      key={action.date}
                      action={action}
                      index={index}
                    />
                  ))
                )
                : <p> Aucun évènement enregistré </p>
            }
          </div>
          <hr className={styles.separator} />
          <div>
            <h3 className={styles.subtitle}>
              Log
            </h3>
            {
              (details.log.actions && details.log.actions.length > 0)
                ? (
                  details.log.actions.map((action, index) => (
                    <ContactDetailsLog
                      key={action.date}
                      action={action}
                      index={index}
                    />
                  ))
                )
                : <p> Aucun log enregistré </p>
            }
          </div>
          <hr className={styles.separator} />
          <div>
            <h3 className={styles.subtitle}>
              Téléphone
            </h3>
            <p className={styles.highlight}>
              Numéro p2p
            </p>
            {
              (details.phone.p2p && details.phone.p2p.length > 0)
                ? (
                  <p>
                    { details.phone.p2p.join(' / ') }
                  </p>
                )
                : <p> Aucun numéro p2p enregistré </p>
            }
            <p className={styles.highlight}>
              Numéro pipl
            </p>
            {
              (details.phone.pipl && details.phone.pipl.length > 0)
                ? (
                  <p>
                    { details.phone.pipl.join(' / ') }
                  </p>
                )
                : <p> Aucun numéro pipl enregistré </p>
            }
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <Loading />
    </div>
  );
}
