import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as status from '../../../services/store/status';
import ImportManagerSourceToolbar from './ImportManagerSourceToolbar';
import ImportManagerSourceTable from './ImportManagerSourceTable';

import styles from './ImportManagerSource.module.css';
import DeletionPopUp from './DeletionPopUp';
import LogInPopUp from './LogInPopUp';

export default function ImportManagerSource({
  id, source, timer, login, actions,
}) {
  const [isOpen, setIsOpen] = useState({ status: false, function: null });
  useEffect(() => {
    if (id && !source) {
      actions.setActiveSource(id);
    }
    if (source && id !== source.id) {
      if (timer) {
        actions.clearTimer();
        actions.setTimer(window.setInterval(() => actions.fetchSourcesById(id), 60 * 1000));
      }
      actions.setActiveSource(id);
    }
  }, [id]);

  useEffect(() => {
    if (!source) {
      actions.fetchSourcesById(id);
      const interval = window.setInterval(() => actions.fetchSourcesById(id), 60 * 1000);
      actions.clearTimer();
      actions.setTimer(interval);
    }
  }, [source]);

  useEffect(() => () => {
    actions.clearTimer();
  }, []);

  return (
    <main className={styles.source}>
      {
        (isOpen.status)
        && (
          <DeletionPopUp
            close={() => setIsOpen({ status: false, function: null })}
            deleteAccount={isOpen.function}
          />
        )
      }
      {
        (login.status !== 'IDLE')
        && (
          <LogInPopUp
            status={login.status}
            close={actions.resetLogin}
            error={login.error}
            url={login.url}
          />
        )
      }
      <ImportManagerSourceToolbar id={id} handleDelete={setIsOpen} />
      {
        (source && source.status !== status.FAILED)
        && <ImportManagerSourceTable id={id} source={source} handleDelete={setIsOpen} />
      }
    </main>
  );
}

ImportManagerSource.defaultProps = {
  source: undefined,
};

ImportManagerSource.propTypes = {
  id: PropTypes.number.isRequired,
  source: PropTypes.shape({
    status: PropTypes.oneOf(
      [status.IDLE, status.LOADING, status.SUCCEEDED, status.FAILED],
    ),
    accounts: PropTypes.arrayOf(PropTypes.shape({
      account: PropTypes.string,
      blacklist: PropTypes.number,
      mails: PropTypes.arrayOf(
        PropTypes.shape({
          mail: PropTypes.string,
          contacts: PropTypes.number,
          type: PropTypes.string,
          status: PropTypes.string,
          progress: PropTypes.number,
        }),
      ),
    })),
  }),
  actions: PropTypes.shape({
    setActiveSource: PropTypes.func.isRequired,
    fetchSourcesById: PropTypes.func.isRequired,
  }).isRequired,
};
