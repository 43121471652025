export const statusList = {
  skip: 'blue',
  waiting: 'blue',
  invalid_user_password: 'red',
  invalid_token: 'red',
  server_imap_not_found: 'red',
  besoin_d_un_export: 'red',
  to_import_with_gmail: 'red',
  empty: 'red',
  process_stats: 'green',
  run: 'green',
  end: 'green',
};

export const statusArray = Object.keys(statusList)
  .map((key) => ({ value: key, color: statusList[key] }));
