import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { blacklistContact, unblacklistContact } from '../../../../../services/store/features/contacts/actions';
import { setSelectedContactsByProject } from '../../../../../services/store/features/contactDetails/actions';

import ImportManagerContactTableRow from './ImportManagerContactTableRow';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    blacklist: blacklistContact,
    unblacklist: unblacklistContact,
    setSelectedContactsByProject,
  }, dispatch),
});

export default connect(null, mapDispatchToProps)(ImportManagerContactTableRow);
