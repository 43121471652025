import React, { useEffect } from 'react';
import Proptypes from 'prop-types';
import { Error } from '@material-ui/icons';

import ImportManagerContactToolbar from './ImportManagerContactToolbar';
import ImportManagerContactTable from './ImportManagerContactTable';

import * as state from '../../../services/store/status';

import styles from './ImportManagerContact.module.css';
import ImportManagerContactSegmentation from './ImportManagerContactSegmentation';
import AccountLoading from '../../../components/library/Loading';

export default function ImportManagerContact({
  id, status, contacts, accounts, languages, hasMore, actions,
  openContactDetails, isAllSelected, total, error,
}) {
  useEffect(() => {
    actions.setActiveProject(id);
  }, []);

  useEffect(() => {
    if (status === state.IDLE) {
      actions.fetchContactsByProjectId(id);
    }
  }, [status]);

  return (
    <main className={styles.contacts}>
      <ImportManagerContactToolbar />
      <ImportManagerContactSegmentation id={id} />
      {
        (status === state.LOADING)
        && (
          <>
            <p> Chargement de la liste des contact en cours ...</p>
            <AccountLoading />
          </>
        )
      }
      {
        (status === state.FAILED)
        && (
          <div className={styles.error}>
            <Error className={styles.icon} />
            <div>
              <p className={styles.title}>
                L&apos;erreur suivante est survenue lors du chargement des contacts :
              </p>
              <p className={styles.information}>
                {`${error}`}
              </p>
            </div>
          </div>
        )
      }
      {
        (status === state.SUCCEEDED)
        && (
        <>
          <div className={styles.row}>
            <p className={styles.contactsNumber}>
              Nombre de contacts dans la liste :
              <span>
                { total }
              </span>
            </p>
            {
              (contacts.filter((contact) => contact.isSelected === true).length > 0)
              && (
                <p className={styles.contactsNumber}>
                  Nombre de contacts selectionnés :
                  <span>
                    { contacts.filter((contact) => contact.isSelected === true).length }
                  </span>
                </p>
              )
            }
          </div>
          <ImportManagerContactTable
            accounts={accounts}
            contacts={contacts}
            actions={actions}
            languages={languages}
            hasMore={hasMore}
            openContactDetails={openContactDetails}
            isAllSelected={isAllSelected}
          />
        </>
        )
      }
    </main>
  );
}

ImportManagerContact.defaultProps = {
  contacts: [],
  status: state.IDLE,
  accounts: [],
  languages: [],
  hasMore: false,
};

ImportManagerContact.propTypes = {
  id: Proptypes.number.isRequired,
  contacts: Proptypes.arrayOf(
    Proptypes.shape({
      mail: Proptypes.string,
    }),
  ),
  status: Proptypes.oneOf([state.IDLE, state.LOADING, state.SUCCEEDED, state.FAILED]),
  accounts: Proptypes.arrayOf(
    Proptypes.string,
  ),
  languages: Proptypes.arrayOf(
    Proptypes.string,
  ),
  hasMore: Proptypes.bool,
  actions: Proptypes.shape({
    setActiveProject: Proptypes.func.isRequired,
    fetchContactsByProjectId: Proptypes.func.isRequired,
  }).isRequired,
};
