import React from 'react';
import PropTypes from 'prop-types';

import * as status from '../../../../../services/store/status';
import styles from '../ImportManagerSourceTable.module.css';

const TableTitleCell = ({ title, value }) => (
  <div className={styles.cellTitle}>
    <p className={styles.title}>
      { title }
    </p>
    <p className={styles.value}>
      { value }
    </p>
  </div>
);

export default function ImportManagerSourceTableTitle({ source }) {
  const getStatutsPercentage = () => {
    let isStatusEnd = true;
    const progress = source.accounts
      ?.reduce((acc, curr) => acc + curr.issuer
        ?.reduce((accMails, currMails) => {
          if (isStatusEnd && currMails.status !== 'end') {
            isStatusEnd = false;
          }
          return accMails + currMails.global;
        }, 0), 0);
    const total = source.accounts
      ?.reduce((acc, curr) => acc + curr?.issuer?.length, 0);
    return (isStatusEnd) ? 100 : progress / total;
  };

  const getContactsNumber = () => (source.accounts
    ?.reduce((acc, curr) => acc + curr.issuer
      ?.reduce(
        (accMails, currMails) => accMails + (
          (currMails.contacts !== -1) ? currMails.contacts : 0), 0,
      ), 0));

  return (
    <div className={styles.gridTitle}>
      <div />
      <TableTitleCell
        title="Source"
        value={source?.accounts?.length || 0}
      />
      <TableTitleCell
        title="Contacts"
        value={getContactsNumber()}
      />
      <TableTitleCell
        title="Taux de blacklist"
        value=""
      />
      <TableTitleCell
        title="Blacklist actuel"
        value=""
      />
      <TableTitleCell
        title="Type"
        value=""
      />
      <TableTitleCell
        title="Compte"
        value=""
      />
      <TableTitleCell
        title="Statuts"
        value={`${getStatutsPercentage().toFixed(0)}%`}
      />
    </div>
  );
}

ImportManagerSourceTableTitle.propTypes = {
  source: PropTypes.shape({
    status: PropTypes.oneOf(
      [status.IDLE, status.LOADING, status.SUCCEEDED, status.FAILED],
    ),
    account: PropTypes.shape({
      account: PropTypes.string,
      blacklist: PropTypes.number,
      mails: PropTypes.arrayOf(
        PropTypes.shape({
          mail: PropTypes.string,
          contacts: PropTypes.number,
          type: PropTypes.string,
          status: PropTypes.string,
          progress: PropTypes.number,
        }),
      ),
    }),
  }).isRequired,
};
