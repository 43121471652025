import React from 'react';

import styles from './Dropdown.module.css';

export default function Dropdown({ buttons, dropdown }) {
  return (
    <div className={styles.importPopUp} ref={dropdown}>
      {
        buttons && buttons.map((button) => (
          <button
            type="button"
            key={button.label}
            className={button.styles}
            onClick={button.action}
          >
            {button.icon}
            {button.label}
          </button>
        ))
      }
    </div>
  );
}
