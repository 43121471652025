import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ImportManagerSourceTableRowsAccountMails from './AccountMails';
import {
  modifySourceOwner, importContactsByMail, downloadAllContactFromMail,
  deleteSourceFromAccount, flushByImportId, setImportStatus,
} from '../../../../../../../services/store/features/sources/actions';
import { setMailFilters } from '../../../../../../../services/store/features/contacts/actions';
import { setTabToDisplay } from '../../../../../../../services/store/features/ui/actions';

const mapStateToProps = (state) => ({
  query: state.sources.query,
  accounts: state.sources.sources
    .find((a) => a.id === state.sources.active).accounts.map((i) => i.account),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    modifySourceOwner,
    importContactsByMail,
    downloadAllContactFromMail,
    deleteSourceFromAccount,
    setTabToDisplay,
    setMailFilters,
    flushByImportId,
    setImportStatus,
  }, dispatch),
});

export default connect(mapStateToProps,
  mapDispatchToProps)(ImportManagerSourceTableRowsAccountMails);
