import React, { useEffect } from 'react';
import * as statuses from '../../../../services/store/status';
import styles from './ImportManagerContactSegmentation.module.css';

export default function ImportManagerContactSegmentation({
  status, id, projectId, data, actions,
}) {
  useEffect(() => {
    if (status === statuses.IDLE || id !== projectId) {
      actions.fetchSegmentInfoById(id);
    }
  }, [id]);
  if (status !== statuses.SUCCEEDED) return null;
  return (
    <section className="fadeIn">
      <p className={styles.title}>
        Segmentation
      </p>
      <ul className={styles.statList}>
        {
          Object.keys(data).map((key) => (
            <li
              key={key}
              className={styles.stat}
            >
              <p className={styles.label}>{`Segment ${key}`}</p>
              <p className={styles.value}>
                {`${data[key].percent?.toFixed(2)}% `}
                <span className={styles.hint}>
                  {`valeur : ${data[key].value}`}
                </span>
              </p>
            </li>
          ))
        }
      </ul>
    </section>
  );
}
