import React from 'react';

import Loading from '../../components/library/Loading';
import * as statuses from '../../services/store/status';

import HistoryLayout from './HistoryLayout';
import HistoryManager from './HistoryManager';
import HistoryTable from './HistoryTable';
import HistoryError from './HistoryError';
import HistorySearch from './HistorySearch';

export default function History() {
  return (
    <HistoryLayout>
      <HistorySearch>
        {
          ({
            request, authId, begin, end,
          }) => (
            <HistoryManager
              params={{
                request, authId, begin, end,
              }}
            >
              {
                ({ status, data, error }) => {
                  if (status === statuses.LOADING) {
                    return <Loading />;
                  }
                  if (status === statuses.SUCCEEDED) {
                    return <HistoryTable data={data} />;
                  }
                  return <HistoryError error={error} />;
                }
              }
            </HistoryManager>
          )
        }
      </HistorySearch>
    </HistoryLayout>
  );
}
