import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ImportManagerHistory from './ImportManagerHistory';
import { fetchTaskHistory } from '../../../services/store/features/history/actions';

const mapStateToProps = (state) => ({
  history: state.history,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchHistory: fetchTaskHistory,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportManagerHistory);
