import React, { useState } from 'react';
import shared from '../../../styles/shared.module.css';
import styles from './GlobalLayout.module.css';

export default function GlobalLayout({ children }) {
  const [tabToDisplay, setTabToDisplay] = useState('all');
  return (
    <section className={shared.section}>
      <header className={shared.header}>
        <h1 className={shared.title}>
          Import Global
        </h1>
        <nav>
          <button
            type="button"
            className={`${styles.button} ${(tabToDisplay === 'all') ? styles.selected : ''}`}
            onClick={() => setTabToDisplay('all')}
          >
            All
          </button>
          <button
            type="button"
            className={`${styles.button} ${(tabToDisplay === 'invalid') ? styles.selected : ''}`}
            onClick={() => setTabToDisplay('invalid')}
          >
            Invalid Status
          </button>
          <button
            type="button"
            className={`${styles.button} ${(tabToDisplay === 'run') ? styles.selected : ''}`}
            onClick={() => setTabToDisplay('run')}
          >
            Run
          </button>
          <button
            type="button"
            className={`${styles.button} ${(tabToDisplay === 'waiting') ? styles.selected : ''}`}
            onClick={() => setTabToDisplay('waiting')}
          >
            Waiting
          </button>
          <button
            type="button"
            className={`${styles.button} ${(tabToDisplay === 'skip') ? styles.selected : ''}`}
            onClick={() => setTabToDisplay('skip')}
          >
            Skip
          </button>
        </nav>
      </header>
      <main className={styles.main}>
        { children(tabToDisplay) }
      </main>
    </section>
  );
}
