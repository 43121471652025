import axios from '../axiosClient';

export const getAllContactsByProjects = (id) => axios.get(`/users/${id}/all`);

export const getContactDetails = (mail) => axios.get(`/users/${mail}`);
export const getContactsDetailsByProject = (mail, id) => axios.get(`/users/${mail}/${id}`);

export const updateContactById = (id, contactId, contact) => axios.put(`/users/${id}/${contactId}`, contact);

export const getContactsCsv = (filters, query) => axios.post('/csvByFilters', { filters, query });

export const recalculate = () => axios.post('/recalculate');

export const blacklistContacts = (id, emails) => axios.post(`/users/${id}/blacklist`, { emails });
export const unblacklistContacts = (id, emails) => axios.post(`/users/${id}/unblacklist`, { emails });

export const getAllBlacklistedExpression = () => axios.get('/blacklist/0');
export const updateBlacklistByType = (type, expressions) => axios.post(`/blacklist/${type}`, { expressions });
export const deleteBlacklistByType = (type, expressions) => axios.delete(`/blacklist/${type}`, { data: { expressions } });

export const getBlacklistedExpressionByProject = (id) => axios.get(`/blacklist/${id}`);
export const addBlacklistedExpressionByTypeByProject = (type, id, expressions) => axios.post(`/blacklist/${type}/${id}`, { expressions });
export const deleteBlacklistedExpressionByTypeByProject = (type, id, expressions) => axios.delete(`/blacklist/${type}/${id}`, { data: { expressions } });

export const getSegmentData = (id) => axios.get(`/projects/${id}/infos`);
