import React from 'react';
import Proptypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';

import ImportManagerContactTableTitle from './ImportManagerContactTableTitle';
import ImportManagerContactTableRow from './ImportManagerContactTableRow';

import styles from './ImportManagerContactTable.module.css';

export default function ImportManagerContactTable({
  contacts, accounts, languages, hasMore,
  openContactDetails, isAllSelected, actions,
}) {
  return (
    <div className={styles.table}>
      <div className={styles.widthScroll}>
        <ImportManagerContactTableTitle
          isAllSelected={isAllSelected}
          selectAll={actions.selectAll}
          setSort={actions.setSort}
        />
        {
          (contacts.length === 0)
            ? (
              <div className={styles.result}>
                <p>Il n&apos;y a aucun contact correspondant</p>
              </div>
            )
            : (
              <div className={styles.fixed} id="scrollableDiv">
                <InfiniteScroll
                  dataLength={contacts.length}
                  next={actions.next}
                  hasMore={hasMore}
                  loader={<h4>Loading...</h4>}
                  scrollableTarget="scrollableDiv"
                >
                  {
                  contacts.map((contact) => (
                    <ImportManagerContactTableRow
                      key={contact.id}
                      contact={contact}
                      style={styles.tableRow}
                      accounts={accounts}
                      languages={languages}
                      submit={actions.updateContact}
                      selectContactById={actions.selectContactById}
                      openContactDetails={openContactDetails}
                    />
                  ))
                }
                </InfiniteScroll>
              </div>
            )
        }
      </div>
    </div>
  );
}

ImportManagerContactTable.defaultProps = {
  hasMore: true,
};

ImportManagerContactTable.propTypes = {
  contacts: Proptypes.arrayOf(
    Proptypes.shape({
      emailGeneral: Proptypes.string,
      segment: Proptypes.string,
      /* proximity: Proptypes.string, */
      emailImportateur: Proptypes.string,
      amout: Proptypes.number,
      language: Proptypes.string,
    }),
  ).isRequired,
  hasMore: Proptypes.bool,
  actions: Proptypes.shape({
    next: Proptypes.func.isRequired,
  }).isRequired,
};
