import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import styles from './Filter.module.css';
import stylesInput from '../shared/Input.module.css';

import hook from '../../hook/clickOutside';

export default function Filter({
  label, all, options,
  onChange, filterSelectedInput,
  hideLabelOnSelect = true,
}) {
  const refWrapper = useRef(null);

  const [filterSelected, setFilterSelected] = useState((filterSelectedInput !== -1)
    ? options.find((option) => option.value === filterSelectedInput)?.label
    : '');
  const [isOpen, setIsOpen] = useState(false);

  hook(refWrapper, () => setIsOpen(false));

  useEffect(() => {
    if (filterSelectedInput !== -1) {
      setFilterSelected(
        options.find((option) => option.value === filterSelectedInput)?.label,
      );
    } else {
      setFilterSelected('');
    }
  }, [filterSelectedInput]);

  const handleSelection = (newOptionValue, newOptionLabel) => {
    setFilterSelected(newOptionLabel);
    onChange(newOptionValue);
    setIsOpen(false);
  };

  return (
    <div
      className={styles.filter}
      ref={refWrapper}
    >
      <div
        role="button"
        className={` ${(hideLabelOnSelect) && (filterSelected !== '') && styles.selected} ${(isOpen) && stylesInput.inputActive} ${stylesInput.input}`}
        onClick={
          () => setIsOpen((state) => !state)
        }
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setIsOpen((state) => !state);
          }
        }}
        tabIndex="-1"
      >
        <div className={styles.text}>
          <p className={styles.label} hidden={(hideLabelOnSelect) && (filterSelected !== '')}>
            { label }
          </p>
          <p className={styles.optionSelected}>
            {(filterSelected === '') ? all : filterSelected}
          </p>
        </div>
        <FontAwesomeIcon
          className={`${styles.icon} ${(isOpen) && styles.iconAnimate}`}
          icon={(!isOpen) ? faChevronDown : faChevronUp}
        />
      </div>
      <div className={styles.optionContainer} hidden={!isOpen}>
        {
          (all)
          && (
          <option
            value=""
            id={-1}
            onClick={() => handleSelection(-1, '')}
          >
            {all}
          </option>
          )
        }
        {
          options.map((option) => (
            <option
              key={option.id}
              value={option.value}
              id={option.value}
              onClick={() => handleSelection(option.value, option.label)}
            >
              {option.label}
            </option>
          ))
}
      </div>
    </div>
  );
}
