import React, { useState } from 'react';

import styles from './GlobalTable.module.css';
import GlobalTableRow from './GlobalTableRow';
import getDateFromString from '../../../utils/getDateFromString';

export default function GlobalTable({
  data, filter, actions,
}) {
  // eslint-disable-next-line no-unused-vars
  const [sort, setSort] = useState({ order: 'asc', column: undefined });
  const handleSort = (e) => {
    setSort((prevState) => ({
      // eslint-disable-next-line no-nested-ternary
      order: (e.target.name !== prevState.column) ? 'asc' : (prevState.order === 'asc') ? 'dsc' : 'asc',
      column: e.target.name,
    }));
  };

  const getFilteredData = () => {
    let newData = data;
    if (filter === 'invalid') {
      newData = data
        .filter((item) => (item.statut.search('invalid') !== -1 || item.statut === 'server_imap_not_found' || item.statut === 'empty'));
    }
    if (filter === 'run') {
      newData = data
        .filter((item) => item.statut.search('run') !== -1);
    }
    if (filter === 'waiting') {
      newData = data
        .filter((item) => item.statut.search('waiting') !== -1);
    }
    if (filter === 'skip') {
      newData = data
        .filter((item) => item.statut.search('skip') !== -1);
    }
    newData.sort((a, b) => {
      let date1 = getDateFromString(a.time_start);
      let date2 = getDateFromString(b.time_start);

      if (date1 === 'Invalid Date') {
        date1 = new Date(1970);
      }

      if (date2 === 'Invalid Date') {
        date2 = new Date(1970);
      }
      return (sort.column === 'time_start' && sort.order === 'asc') ? date1 - date2 : date2 - date1;
    });
    return newData;
  };

  return (
    <div>
      <div
        className={`${styles.grid} ${styles.header}`}
      >
        <button
          type="button"
          name="time_start"
          onClick={handleSort}
        >
          Date
        </button>
        <button
          type="button"
          name="importId"
          onClick={handleSort}
        >
          Issuer
        </button>
        <button
          type="button"
          name="type"
          onClick={handleSort}
        >
          Type
        </button>
        <button
          type="button"
          name="statut"
          onClick={handleSort}
        >
          Status
        </button>
        <div />
      </div>
      <div className={styles.body}>
        {
          getFilteredData()
            .map((item) => (
              <GlobalTableRow
                key={item.start}
                item={item}
                actions={actions}
              />
            ))
        }
      </div>
    </div>
  );
}
