import * as types from './types';
import * as status from '../../status';

const initialState = {
  status: status.IDLE,
  selectedProject: null,
  selectedMail: null,
  projects: [],
  details: null,
  error: null,
};

export default function contactsDetailsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.SET_SELECTED_CONTACT:
      return {
        ...state,
        status: status.LOADING,
        selectedMail: payload,
        selectedProject: null,
        details: null,
      };
    case types.FETCH_CONTACT_DETAILS_SUCCESS:
      return {
        ...state,
        status: status.SUCCEEDED,
        projects: payload,
        error: null,
      };
    case types.FETCH_CONTACT_DETAILS_FAIL:
      return {
        ...state,
        status: status.FAILED,
        details: null,
        error: payload,
      };
    case types.SET_SELECTED_PROJECT:
      return {
        ...state,
        selectedProject: payload,
        details: {
          status: status.LOADING,
        },
      };
    case types.FETCH_PROJECT_SUCCEEDED:
      return {
        ...state,
        details: {
          status: status.SUCCEEDED,
          ...payload,
        },
      };
    case types.FETCH_PROJECT_FAILED:
      return {
        ...state,
        details: {
          status: status.FAILED,
          error: payload.msg,
        },
      };
    default:
      return state;
  }
}
