import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ImportManagerSource from './ImportManagerSource';
import {
  setActiveSource, fetchSourcesById, setQuery,
  closeUploadModal, uploadContactsFromFile, resetUploadModal,
  importContactsByAccount, importAllContacts, setTimer, clearTimer,
} from '../../../services/store/features/sources/actions';
import { resetLogin } from '../../../services/store/features/roles/actions';

const mapStateToProps = (state) => {
  const active = state.sources?.active;
  const activeSource = state.sources.sources?.find((source) => source.id === active);
  return ({
    source: activeSource,
    sourceStatus: activeSource?.status,
    query: state.sources.query,
    isModalOpen: state.sources.isUploading,
    percentCompleted: state.sources.percentCompleted,
    timer: state.sources.timer,
    login: state.roles.login,
  });
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setActiveSource,
    fetchSourcesById,
    setQuery,
    closeUploadModal,
    uploadContactsFromFile,
    resetUploadModal,
    importContactsByAccount,
    importAllContacts,
    setTimer,
    clearTimer,
    resetLogin,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportManagerSource);
