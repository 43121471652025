import React, { useState } from 'react';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import shared from '../../../styles/shared.module.css';
import styles from './IntradayTable.module.css';

const IntradayTableRow = ({ item }) => {
  const [open, setIsOpen] = useState(false);
  return (
    <>
      <div
        className={`${styles.row}`}
      >
        <p>
          {item.projectId}
        </p>
        <p>
          {item.segments['7'].percent}
        </p>
        <p>
          {
            item.not_ready.length
          }
        </p>
        <button
          type="button"
          className={styles.details}
          onClick={() => setIsOpen((state) => !state)}
        >
          {
            (open)
              ? <VisibilityOff />
              : <Visibility />
          }
        </button>
      </div>
      {
        (open)
        && (
          <div className={styles.table}>
            <div className={`${shared.grid} ${styles.rowDetails} ${shared.title}`}>
              <p>
                ID
              </p>
              <p>
                Source
              </p>
              <p>
                ImportId
              </p>
              <p>
                Type
              </p>
              <p>
                Contacts
              </p>
              <p>
                Status
              </p>
              <p>
                Global
              </p>
            </div>
            {
              (item.not_ready && item.not_ready.length > 0)
              && (
                item.not_ready.map((notReady) => (
                  <div
                    className={styles.rowDetails}
                    key={notReady.id}
                  >
                    <p className={styles.hide}>
                      {notReady.id}
                    </p>
                    <p>
                      {notReady.source}
                    </p>
                    <p>
                      {notReady.importId}
                    </p>
                    <p>
                      {notReady.type}
                    </p>
                    <p>
                      {notReady.contacts}
                    </p>
                    <p>
                      {notReady.status}
                    </p>
                    <p>
                      {notReady.global}
                    </p>
                  </div>
                ))
              )
            }
          </div>
        )
      }
    </>
  );
};

export default function IntradayTable({ data }) {
  return (
    <div>
      <div className={`${shared.grid} ${styles.row} ${shared.title}`}>
        <p>
          Project ID
        </p>
        <p>
          Blacklist
        </p>
        <p>
          Not ready
        </p>
        <p />
      </div>
      <div className={shared.table}>
        {
          (data && data.length > 0)
          && (data.map((item) => (
            <IntradayTableRow
              item={item}
              key={item.projectId}
            />
          )))
        }
      </div>
    </div>
  );
}
