import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ImportManagerSourceToolbar from './ImportManagerSourceToolbar';
import {
  setQuery, downloadAllContactFromSource,
  openUploadModal, uploadContactsFromFile,
  importContactsByAccount, importAllContacts,
  deleteAllSources,
} from '../../../../services/store/features/sources/actions';
import { launchBlacklistTask } from '../../../../services/store/features/history/actions';
import { recalculateContact } from '../../../../services/store/features/contacts/actions';

const mapStateToProps = (state) => ({
  query: state.sources.query,
  isDownloading: state.sources.isDownloading,
  accounts: state.sources?.sources?.find(
    (project) => project.id === state.sources.active,
  )?.accounts,
  isTaskRunning: state.history.activeTask,
  isCreatingTask: state.history.isCreatingTask,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setQuery,
    downloadAllContactFromSource,
    openUploadModal,
    uploadContactsFromFile,
    importContactsByAccount,
    importAllContacts,
    deleteAllSources,
    recalculateContact,
    launchBlacklistTask,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportManagerSourceToolbar);
