import React, { useState } from 'react';
import Proptypes from 'prop-types';
import { CheckCircle, Error } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { useDispatch } from 'react-redux';
import Button from '../library/Button/Button';

import styles from './AccountDetails.module.css';
import AccountDetailsManager from './AccountDetailsManager';
import copyTextToClipboard from '../../utils/copyToClipboard';
import { setAsChecked } from '../../services/clients/lib/import';
import { addToast } from '../../services/store/features/toasts/actions';

export default function AccountDetails({ importId, actions, close }) {
  const [copy, setCopy] = useState('Copier');
  const [status, setStatus] = useState('IDLE');

  const dispatch = useDispatch();

  const handleCopy = (details) => {
    const onSuccess = () => setCopy('Copié !');
    const onError = () => setCopy('Erreur :(');
    copyTextToClipboard(details?.data?.details?.password.trim(), onSuccess, onError);
    window.setTimeout(() => setCopy('Copier'), 2000);
  };

  const handleMarkAsTreated = async () => {
    setStatus('LOADING');
    try {
      const { data, status: code, statusText } = await setAsChecked(importId);
      if (data.status === 'OK') {
        actions.markAsTreated(importId);
        setStatus('SUCCESS');
      } else {
        setStatus('ERROR');
        dispatch(addToast({
          type: 'error',
          title: 'Impossible de marquer l\'import comme traité',
          description: data.msg || `${code} ${statusText}`,
        }));
        window.setTimeout(() => setStatus('IDLE'), 10000);
      }
    } catch (err) {
      setStatus('ERROR');
      dispatch(addToast({
        type: 'error',
        title: 'Impossible de marquer l\'import comme traité',
        description: `${err}`,
      }));
      window.setTimeout(() => setStatus('IDLE'), 10000);
    }
  };

  return (
    <AccountDetailsManager
      importId={importId}
    >
      {
        ({
          details, relaunch, error, handleRelaunch,
        }) => {
          if (details.status !== 'SUCCESS') {
            return (
              <div className={styles.background}>
                <section
                  className={styles.content}
                >
                  <header className={styles.header}>
                    <h3>
                      Détails de l&apos;importation
                    </h3>
                  </header>
                  <main className={styles.listContainer} />
                </section>
              </div>
            );
          }
          return (
            <div
              className={`${(status === 'SUCCESS') ? 'fadeOut' : ''} ${styles.background}`}
              onAnimationEnd={close}
            >
              <section
                className={styles.content}
              >
                <header className={styles.header}>
                  <div>
                    <h3>
                      Détails de l&apos;importation
                    </h3>
                    <p className={styles.issuer}>
                      {details.data?.details?.email}
                    </p>
                  </div>
                  <div className={styles.actions}>
                    <Button
                      label="Ajouter IMAP Server"
                      size="small"
                      onButtonClick={actions.handleOpenImap}
                      className={styles.relaunch}
                    />
                    <Button
                      label="Relancer"
                      size="small"
                      onButtonClick={handleRelaunch}
                      disabled={relaunch !== 'IDLE'}
                      className={styles.relaunch}
                    />
                    <Button
                      label="Marquer comme traité"
                      size="small"
                      icon={(status !== 'LOADING') ? <CheckCircle /> : <FontAwesomeIcon icon={faSpinner} spin />}
                      onButtonClick={handleMarkAsTreated}
                      className={styles.relaunch}
                      disabled={status !== 'IDLE'}
                    />
                  </div>
                </header>
                <main className={styles.listContainer}>
                  {
                    (details?.data)
                      ? (
                        <>
                          <ul className={styles.list}>
                            <li
                              className={styles.item}
                            >
                              <span className={styles.bold}>
                                projectId
                              </span>
                              <span>
                                {details.data?.projectId}
                              </span>
                            </li>
                            {
                              Object.keys(details?.data?.status).map((key) => (
                                <li
                                  key={key}
                                  className={styles.item}
                                >
                                  <span className={styles.bold}>
                                    {' '}
                                    { key }
                                    {' '}
                                  </span>
                                  <span>
                                    {' '}
                                    { details?.data?.status[key] }
                                    {' '}
                                  </span>
                                </li>
                              ))
                            }
                          </ul>
                          <ul className={styles.list}>
                            {
                              Object.keys(details?.data?.details).map((key) => (
                                <li
                                  key={key}
                                  className={styles.item}
                                >
                                  <span className={styles.bold}>
                                    {' '}
                                    { key }
                                    {' '}
                                  </span>
                                  <div className={styles.password}>
                                    {' '}
                                    { details?.data?.details[key] }
                                    {' '}
                                    {
                                      (key === 'password')
                                      && (
                                        <Button
                                          label={copy}
                                          variant="outline"
                                          size="small"
                                          className={styles.button}
                                          onButtonClick={() => handleCopy(details)}
                                        />
                                      )
                                    }
                                  </div>
                                </li>
                              ))
                            }
                          </ul>
                        </>
                      )
                      : (
                        <p>
                          Aucun détails à afficher
                        </p>
                      )
                  }
                </main>
                {
                  (error)
                  && (
                    <div className={styles.status}>
                      <p className={styles.error}>
                        <Error className={styles.icon} />
                        <span>
                          {error}
                        </span>
                      </p>
                    </div>
                  )
                }
                {
                  (relaunch === 'SUCCESS')
                  && (
                    <div className={styles.status}>
                      <p className={styles.success}>
                        <CheckCircle className={styles.icon} />
                        <span>
                          La relance a été effectuée
                        </span>
                      </p>
                    </div>
                  )
                }
              </section>
            </div>
          );
        }
      }
    </AccountDetailsManager>
  );
}

AccountDetails.defaultProps = {
  details: undefined,
};

AccountDetails.propTypes = {
  details: Proptypes.shape({}),
};
