import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DeleteIcon from '@material-ui/icons/Delete';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import Searchbar from '../../../../components/Searchbar';
import Dropdown from '../../../../components/Dropdown';
import useOutsideClickAlert from '../../../../components/library/hook/clickOutside';

import styles from './ImportManagerSourceToolbar.module.css';

export default function ImportManagerSourceToolbar({
  id, accounts, query, isDownloading,
  actions, isTaskRunning, isCreatingTask, handleDelete,
}) {
  const [isImportOpen, setIsImportOpen] = useState(false);
  const [isMoreOpen, setIsMoreOpen] = useState(false);

  const importRef = useRef();
  const moreRef = useRef();

  useOutsideClickAlert(importRef, () => setIsImportOpen(false));
  useOutsideClickAlert(moreRef, () => setIsMoreOpen(false));

  const handleImport = (category) => {
    if (category === 'all') {
      actions.importAllContacts();
    } else {
      actions.importContactsByAccount(category);
    }
    setIsImportOpen(false);
  };

  const getStatutsPercentage = () => {
    if (accounts) {
      const progress = accounts
        ?.reduce((acc, curr) => acc + curr.issuer
          ?.reduce((accMails, currMails) => accMails + currMails?.global, 0), 0);
      const total = accounts
        ?.reduce((acc, curr) => acc + curr?.issuer?.length, 0);
      return progress / total;
    }
    return 0;
  };

  const handleDeleteAccount = () => {
    handleDelete({ status: true, function: actions.deleteAllSources });
    setIsMoreOpen(false);
  };

  const handleDeleteAccountsAndContacts = () => {
    handleDelete({
      status: true,
      function: () => actions.deleteAllSources(true),
    });
    setIsMoreOpen(false);
  };

  const handleRecalculate = () => {
    actions.recalculateContact();
    setIsMoreOpen(false);
  };

  const getDoneAccounts = () => accounts
    ?.filter((account) => account.issuer
      ?.reduce((acc, curr) => acc && (curr.status !== 'run'), true));

  const importButtons = [
    {
      label: 'Supprimer les comptes',
      styles: styles.importButton,
      action: handleDeleteAccount,
      icon: <DeleteIcon className={styles.icon} />,
    },
    {
      label: 'Supprimer les contacts',
      styles: styles.importButton,
      action: handleDeleteAccountsAndContacts,
      icon: <DeleteIcon className={styles.icon} />,
    },
    {
      label: 'Recalculer',
      styles: styles.optionButton,
      action: handleRecalculate,
      icon: <PlayCircleOutlineIcon className={styles.icon} />,
    },
  ];

  return (
    <div
      className={styles.toolbar}
    >
      <Searchbar
        query={query}
        setQuery={actions.setQuery}
      />
      <div className={styles.buttonGroup}>
        <button
          type="button"
          className={`${styles.ghost} ${styles.tool}`}
          onClick={actions.openUploadModal}
        >
          <PublishIcon className={styles.icon} />
          Importer des contacts
        </button>
        {
          (!isDownloading)
            ? (
              <button
                type="button"
                className={`${styles.ghost} ${styles.tool}`}
                onClick={actions.downloadAllContactFromSource}
              >
                <GetAppIcon className={styles.icon} />
                Télécharger
              </button>
            )
            : (
              <button
                type="button"
                className={`${styles.ghost} ${styles.tool}`}
              >
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  className={styles.icon}
                />
                Téléchargement en cours ...
              </button>
            )
        }
        <button
          type="button"
          className={`${styles.button} ${styles.blacklist} ${(isTaskRunning !== null || isCreatingTask) ? styles.disabled : ''}`}
          onClick={() => actions.launchBlacklistTask(id)}
        >
          {
            (isCreatingTask)
              ? 'Lancement en cours ...'
              : 'Lancer la blacklist'
          }
        </button>
        <div
          className={`${styles.import} ${styles.tool}`}
          ref={importRef}
        >
          <button
            type="button"
            className={`${styles.button} ${(
              (getStatutsPercentage() === 0)
              || ((getStatutsPercentage() !== 100) && getDoneAccounts().length === 0)
            ) ? styles.disabled : ''}`}
            onClick={() => setIsImportOpen((state) => !state)}
            disabled={
              (getStatutsPercentage() === 0)
              || ((getStatutsPercentage() !== 100) && getDoneAccounts().length === 0)
            }
          >
            Relancer les imports
            <ExpandMoreIcon />
          </button>
          {
            (isImportOpen)
            && (
              <ul className={styles.importChoice}>
                <button
                  type="button"
                  onClick={() => handleImport('all')}
                >
                  Relancer tous les imports
                </button>
                {
                  accounts && getDoneAccounts()
                    .map((account) => (
                      <button
                        key={account.email}
                        type="button"
                        onClick={() => handleImport(account.email)}
                      >
                        Relancer tous les imports lié à
                        {' '}
                        { account.email }
                      </button>
                    ))
                }
              </ul>
            )
          }
        </div>
        <div
          className={`${styles.tool} ${styles.import}`}
          ref={moreRef}
        >
          <button
            type="button"
            className={styles.more}
            onClick={() => setIsMoreOpen((state) => !state)}
          >
            <MoreHorizIcon className={styles.icon} />
          </button>
          {
            (isMoreOpen)
            && (
              <Dropdown buttons={importButtons} />
            )
          }
        </div>
      </div>
    </div>
  );
}

ImportManagerSourceToolbar.defaultProps = {
  query: '',
  isDownloading: false,
  accounts: [],
};

ImportManagerSourceToolbar.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({
    account: PropTypes.string,
    blacklist: PropTypes.number,
    mails: PropTypes.arrayOf(
      PropTypes.shape({
        mail: PropTypes.string,
        contacts: PropTypes.number,
        type: PropTypes.string,
        status: PropTypes.string,
        progress: PropTypes.number,
      }),
    ),
  })),
  query: PropTypes.string,
  isDownloading: PropTypes.bool,
  actions: PropTypes.shape({
    setQuery: PropTypes.func.isRequired,
    downloadAllContactFromSource: PropTypes.func.isRequired,
    openUploadModal: PropTypes.func.isRequired,
    uploadContactsFromFile: PropTypes.func.isRequired,
    importContactsByAccount: PropTypes.func.isRequired,
    importAllContacts: PropTypes.func.isRequired,
    deleteAllSources: PropTypes.func.isRequired,
  }).isRequired,
};
