import * as types from './types';
import * as status from '../../status';

const initialState = {
  status: status.IDLE,
  list: [],
  activeProjects: window.sessionStorage.getItem('pinnedProjects') ? JSON.parse(window.sessionStorage.getItem('pinnedProjects')) : [],
  error: null,
};

export const getNewActiveProjects = (state, payload) => {
  if (state.find((project) => project.id === payload.id)) {
    const newPins = state.filter((project) => project.id !== payload.id);
    window.sessionStorage.setItem('pinnedProjects', JSON.stringify(newPins));
    return newPins;
  }
  window.sessionStorage.setItem('pinnedProjects', JSON.stringify([payload, ...state]));
  return [payload, ...state];
};

export default function projectReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_PROJECTS_REQUEST:
      return {
        ...state,
        status: status.LOADING,
      };
    case types.FETCH_PROJECTS_SUCCEEDED:
      return {
        ...state,
        list: payload,
        status: status.SUCCEEDED,
      };
    case types.FETCH_PROJECTS_FAILED:
      return {
        ...state,
        status: status.FAILED,
        error: payload,
      };
    case types.FETCH_PROJECT_QUERY_REQUEST:
      return {
        ...state,
        status: status.LOADING,
      };
    case types.FETCH_PROJECT_QUERY_SUCCEEDED:
      return {
        ...state,
        status: status.SUCCEEDED,
        list: payload,
        history: state.list,
      };
    case types.FETCH_PROJECT_QUERY_FAILED:
      return {
        ...state,
        status: status.FAILED,
        error: payload,
      };
    case types.RESET_PROJECT_QUERY:
      return {
        ...state,
        status: status.SUCCEEDED,
        list: state.history,
      };
    case types.SET_ACTIVE_PROJECT_TAB:
      return {
        ...state,
        activeProjects: getNewActiveProjects(state.activeProjects, payload),
      };
    default:
      return state;
  }
}
