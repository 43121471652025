import React, { useEffect, useState } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

import BlacklistTypeList from './BlacklistTypeList';
import * as statuses from '../../services/store/status';
import styles from './BlacklistType.module.css';

export default function BlacklistType({
  type, blacklist, global, actions, status, project = 0,
}) {
  const [expression, setExpression] = useState('');
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isSearchbarOpen, setIsSearchbarOpen] = useState(false);
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (expression !== '' && status === statuses.SUCCEEDED) {
      setExpression('');
    }
  }, [status]);

  const handleSubmit = () => {
    actions.blacklist(type.toLowerCase(), expression.split('\n'));
    setHasSubmitted(true);
  };

  const handleChange = (e) => {
    setExpression(e.target.value);
    if (hasSubmitted) {
      setHasSubmitted(false);
    }
  };

  const getProjects = () => blacklist
    .filter((d) => d.project === project)
    .filter((d) => {
      if (query && query.trim() !== '') {
        return (d.regex.toLowerCase().search(query.toLowerCase()) !== -1);
      }
      return 1;
    });

  return (
    <div className={styles.type}>
      <div>
        <div className={styles.toolbar}>
          <h2>
            {type}
          </h2>
          <div className={`${styles.searchbar} ${(isSearchbarOpen) ? styles.show : styles.hide}`}>
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              hidden={!isSearchbarOpen}
            />
            {
              (!isSearchbarOpen)
                ? (
                  <button
                    type="button"
                    onClick={() => setIsSearchbarOpen(true)}
                  >
                    <SearchIcon />
                  </button>
                )
                : (
                  <button
                    type="button"
                    onClick={() => {
                      setIsSearchbarOpen(false);
                      setQuery('');
                    }}
                  >
                    <ClearIcon className={styles.icon} />
                  </button>
                )
            }
          </div>
        </div>
        {
        (hasSubmitted && status === statuses.FAILED)
        && <p className={styles.error}>{ `${blacklist.error}` }</p>
      }
        {
        (project === 0)
        && (
          <div>
            {
              (getProjects()
                .length > 0)
                ? (
                  <ul className={`${styles.list} ${styles.container}`}>
                    {
                (getProjects()
                  .map((i) => (
                    <li key={i.regex}>
                      <p>{ i.regex }</p>
                      {
                      (i.project === project)
                      && (
                        <button
                          type="button"
                          onClick={() => actions.unblacklist(type.toLowerCase(), [i.regex])}
                        >
                          <ClearIcon />
                        </button>
                      )
                    }
                    </li>
                  )))
            }
                  </ul>
                )
                : (
                  <div className={`${styles.emptyWrapper} ${styles.container}`}>
                    <p className={styles.empty}> Aucune expression n&apos;a été blacklistée </p>
                  </div>
                )
      }
          </div>
        )
      }
        {(project !== 0)
        && (
          <div>
            <BlacklistTypeList
              label="Global"
              blacklist={global}
              query={query}
              actions={actions}
              type={type}
            />
            <BlacklistTypeList
              label="Custom"
              blacklist={blacklist}
              actions={actions}
              project={project}
              type={type}
              query={query}
              open
            />
          </div>
        )}
      </div>
      <div className={styles.inputWrapper}>
        <textarea
          value={expression}
          onChange={handleChange}
        />
        <div className={styles.submit}>
          <p className={styles.hint}>
            Ajouter plusieurs expressions à blacklister en les séparant par des retours à la ligne
          </p>
          <button
            type="button"
            onClick={handleSubmit}
            disabled={hasSubmitted && status === statuses.LOADING}
          >
            {
            (hasSubmitted && status === statuses.LOADING)
              ? 'Ajout en cours ...'
              : 'Ajouter'
          }
          </button>
        </div>
      </div>
    </div>
  );
}
