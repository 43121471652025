import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ImportManagerSourceTable from './ImportManagerSourceTable';

import { fetchContactsByProjectId } from '../../../../services/store/features/contacts/actions';

const mapStateToProps = (state) => ({
  contacts: state.contacts.contacts.find((project) => project.id === state.sources.active) || { status: 'IDLE' },
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchContactsByProjectId }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportManagerSourceTable);
