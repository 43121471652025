import * as types from './types';
import { getProjects, getProjectsByQuery } from '../../../clients/lib/projects';

export function fetchActiveProject() {
  return async (dispatch) => {
    dispatch({ type: types.FETCH_PROJECTS_REQUEST });
    try {
      const { data } = await getProjects();
      if (data.status === 'OK') {
        dispatch({
          type: types.FETCH_PROJECTS_SUCCEEDED,
          payload: data.data,
        });
      } else {
        dispatch({
          type: types.FETCH_PROJECTS_FAILED,
          payload: data.msg,
        });
      }
    } catch (err) {
      dispatch({
        type: types.FETCH_PROJECTS_FAILED,
        payload: err,
      });
    }
  };
}

export function setActiveProject(project) {
  return (dispatch) => dispatch({ type: types.SET_ACTIVE_PROJECT_TAB, payload: project });
}

export function search(query) {
  return async (dispatch) => {
    if (!query) {
      dispatch({ type: types.RESET_PROJECT_QUERY });
    } else {
      dispatch({
        type: types.FETCH_PROJECT_QUERY_REQUEST,
      });
      try {
        const { data } = await getProjectsByQuery(query);
        dispatch({
          type: types.FETCH_PROJECT_QUERY_SUCCEEDED,
          payload: data,
        });
      } catch (err) {
        dispatch({
          type: types.FETCH_PROJECT_QUERY_FAILED,
          payload: err,
        });
      }
    }
  };
}
