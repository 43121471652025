import React from 'react';

import Rows from './Rows';
import styles from './ImportManagerPotentialsTable.module.css';

export default function ImportManagerPotentialsTable({ source }) {
  return (
    <div className={styles.table}>
      <div className={styles.tableOverflow}>
        <div className={styles.gridTitle}>
          <div />
          <p>Comptes</p>
        </div>
        <Rows source={source} />
      </div>
    </div>
  );
}
