export const FETCH_PROJECTS_REQUEST = 'FETCH_PROJECTS_REQUEST';
export const FETCH_PROJECTS_SUCCEEDED = 'FETCH_PROJECTS_SUCCEEDED';
export const FETCH_PROJECTS_FAILED = 'FETCH_PROJECTS_FAILED';

export const FETCH_PROJECT_QUERY_REQUEST = 'FETCH_PROJECT_QUERY_REQUEST';
export const FETCH_PROJECT_QUERY_SUCCEEDED = 'FETCH_PROJECT_QUERY_SUCCEEDED';
export const FETCH_PROJECT_QUERY_FAILED = 'FETCH_PROJECT_QUERY_FAILED';

export const RESET_PROJECT_QUERY = 'RESET_PROJECT_QUERY';

export const SET_ACTIVE_PROJECT_TAB = 'SET_ACTIVE_PROJECT_TAB';
