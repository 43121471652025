import React from 'react';

import GlobalLayout from './GlobalLayout';
import GlobalManager from './GlobalManager';
import GlobalTable from './GlobalTable';

export default function Global() {
  return (
    <GlobalLayout>
      {
        (filter) => (
          <GlobalManager>
            {(data, actions) => (
              <GlobalTable
                data={data}
                filter={filter}
                skipImport={actions.skipImport}
                setImportStatus={actions.setImportStatus}
                actions={actions}
              />
            )}
          </GlobalManager>
        )
      }
    </GlobalLayout>
  );
}
