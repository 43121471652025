import {
  getSourcesById, uploadContactsFile,
  deleteAllAccounts, updateAccountBlacklist, updateSourceOwner,
  deleteAccountFromSource,
  deleteMailSourceFromAccount, deleteAllAccountsAndContact,
  deleteAccountAndContactsFromSource, flushSource,
} from '../../../clients/lib/sources';
import { rerunImportByMail } from '../../../clients/lib/import';
import formatContact from '../../../../utils/formatContactForCsv';
import {
  createAndDownloadCsv, fetchAllContactsByProjects, getSourcelistAfterFileUpload,
  getSourceWithUpdatedOwner,
} from './utils';
import { addToast } from '../toasts/actions';
import * as types from './types';
import * as contactTypes from '../contacts/types';
import * as status from '../../status';

export function setIsOpen(bool) {
  return (dispatch) => {
    if (bool) {
      dispatch({ type: types.OPEN_VIEW });
    } else {
      dispatch({ type: types.CLOSE_VIEW });
    }
  };
}

export function setTimer(timer) {
  return (dispatch) => dispatch({ type: types.SET_TIMER, payload: timer });
}

export function clearTimer() {
  return (dispatch, getState) => {
    window.clearInterval(getState().sources.timer);
    dispatch(setTimer(null));
  };
}

export function fetchSourcesById(id) {
  return async (dispatch) => {
    dispatch({
      type: types.FETCH_SOURCE_REQUEST,
    });
    try {
      const { data } = await getSourcesById(Number.parseInt(id, 10));
      dispatch({
        type: types.FETCH_SOURCE_SUCCEEDED,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: types.FETCH_SOURCE_FAILED,
        payload: err,
      });
      dispatch(clearTimer());
    }
  };
}

export function setActiveSource(id) {
  return (dispatch) => {
    dispatch({
      type: types.SET_ACTIVE_SOURCE,
      payload: Number.parseInt(id, 10),
    });
  };
}

export function setQuery(query) {
  return (dispatch) => {
    dispatch({
      type: types.SET_QUERY,
      payload: query,
    });
  };
}

export function downloadAllContactFromSource() {
  return async (dispatch, getState) => {
    dispatch({
      type: types.DOWNLOAD_CONTACTS_REQUEST,
    });
    try {
      const { active } = getState().sources;
      const { contacts } = getState().contacts;
      const activeContactsData = contacts.find((project) => project.id === active);
      const contactsStatus = activeContactsData?.status;
      if (!contactsStatus || contactsStatus === status.IDLE) {
        await fetchAllContactsByProjects(active);
        return;
      }
      const activeContacts = activeContactsData.data.map((contact) => formatContact(contact));
      createAndDownloadCsv({ dispatch, activeContacts });
    } catch (err) {
      dispatch({
        type: types.DOWNLOAD_CONTACTS_FAILED,
        payload: err,
      });
    }
  };
}

export function downloadAllContactByFilter(filter) {
  return async (dispatch, getState) => {
    dispatch({
      type: types.DOWNLOAD_CONTACTS_REQUEST,
    });
    try {
      const { active } = getState().sources;
      const { contacts } = getState().contacts;
      const activeContactsData = contacts.find((project) => project.id === active);
      const contactsStatus = activeContactsData?.status;
      if (!contactsStatus || contactsStatus === status.IDLE) {
        await fetchAllContactsByProjects(active);
        return;
      }
      const activeContacts = activeContactsData.data.filter(filter)
        .map((contact) => formatContact(contact));
      createAndDownloadCsv({ dispatch, activeContacts });
    } catch (err) {
      dispatch({
        type: types.DOWNLOAD_CONTACTS_FAILED,
        payload: err,
      });
    }
  };
}

export function downloadAllContactFromAccount(account) {
  return async (dispatch) => {
    dispatch(downloadAllContactByFilter((contact) => contact.emailImportateur === account));
  };
}

export function downloadAllContactFromMail(mail) {
  return async (dispatch) => {
    dispatch(downloadAllContactByFilter((contact) => contact.issuer === mail));
  };
}

export function openUploadModal() {
  return (dispatch) => {
    dispatch({
      type: types.UPLOAD_CONTACTS_REQUEST,
    });
  };
}

export function closeUploadModal() {
  return (dispatch) => {
    dispatch({
      type: types.UPLOAD_CONTACTS_CANCELED,
    });
  };
}

export function resetUploadModal() {
  return (dispatch) => {
    dispatch({
      type: types.UPLOAD_CONTACTS_CANCELED,
    });
  };
}

export function uploadContactsFromFile(files, account, projectId) {
  return async (dispatch, getState) => {
    try {
      /* Upload file with progress bar
       * https://gist.github.com/virolea/e1af9359fe071f24de3da3500ff0f429
       */
      const config = {
        timeout: 86400000,
        onUploadProgress(progressEvent) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          dispatch({
            type: types.SET_UPLOAD_PROGRESS,
            payload: percentCompleted,
          });
        },
      };
      const data = new FormData();
      data.append('file', files);
      data.append('email', account);
      data.append('source', files.name.split('.') === 'pst' ? 'PST' : 'CSV');
      data.append('type', files.name.split('.') === 'pst' ? 'PST' : 'CSV');
      const { data: newResponse } = await uploadContactsFile(
        data,
        config,
        projectId,
        account,
      );
      if (newResponse.status === 'OK') {
        dispatch({
          type: types.UPLOAD_CONTACTS_SUCCEEDED,
          payload: getSourcelistAfterFileUpload({ state: getState(), account, files }),
        });
        window.setTimeout(() => {
          dispatch({
            type: types.UPLOAD_CONTACTS_CANCELED,
          });
        }, 5000);
      } else {
        dispatch({
          type: types.UPLOAD_CONTACTS_FAILED,
          payload: data.msg,
        });
      }
    } catch (err) {
      dispatch({
        type: types.UPLOAD_CONTACTS_FAILED,
        payload: err,
      });
    }
  };
}

export function importContactsByMail(issuer, account, type) {
  return async (dispatch, getState) => {
    const { sources, active } = getState().sources;
    dispatch({
      type: types.UPDATE_SOURCE_REQUEST,
      payload: active,
    });
    try {
      const { accounts } = sources.find((s) => s.id === active);
      const list = accounts.map((currentAccount) => (
        (currentAccount.email === account)
          ? {
            ...currentAccount,
            issuer: currentAccount.issuer.map((email) => (
              (email.id === issuer)
                ? {
                  ...email,
                  status: 'loading',
                  progress: 0,
                  global: 0,
                }
                : email
            )),
          }
          : currentAccount
      ));

      const newSourceList = sources.map((currentSource) => (
        (currentSource.id === active)
          ? {
            ...currentSource,
            isUpdating: false,
            accounts: list,
          }
          : currentSource
      ));

      const { data } = await rerunImportByMail({
        id: active, account, issuer, type,
      });
      if (data.status === 'OK') {
        dispatch({
          type: types.UPDATE_SOURCE_SUCCEEDED,
          payload: newSourceList,
        });
      } else {
        dispatch({
          type: types.UPDATE_SOURCE_FAILED,
          payload: data.msg,
        });
      }
    } catch (err) {
      dispatch({
        type: types.UPDATE_SOURCE_FAILED,
        payload: err,
      });
    }
  };
}

export function importContactsByAccount(account) {
  return async (dispatch, getState) => {
    const { active, sources } = getState().sources;
    const { accounts } = sources.find((source) => source.id === active);
    const emailToRelaunch = accounts.find((a) => a.email === account).issuer;
    emailToRelaunch
      .forEach((issuer) => dispatch(importContactsByMail(issuer.id, account, issuer.type)));
  };
}

export function importAllContacts() {
  return async (dispatch, getState) => {
    const { active, sources } = getState().sources;
    const { accounts } = sources.find((source) => source.id === active);
    accounts.forEach((account) => dispatch(importContactsByAccount(account.email)));
  };
}

export function deleteAllSources(deleteContact = false) {
  return async (dispatch, getState) => {
    dispatch({
      type: types.DELETE_ALL_SOURCES_REQUEST,
    });
    try {
      const { active } = getState().sources;
      if (deleteContact) {
        await deleteAllAccountsAndContact(active);
        dispatch({
          type: contactTypes.DELETE_ALL_CONTACTS,
          payload: active,
        });
        dispatch({
          type: types.DELETE_ALL_SOURCES_SUCCEEDED,
          payload: active,
        });
      } else {
        await deleteAllAccounts(active);
        dispatch({
          type: types.DELETE_ALL_SOURCES_SUCCEEDED,
          payload: active,
        });
      }
    } catch (err) {
      dispatch({
        type: types.DELETE_ALL_SOURCES_FAILED,
        payload: err,
      });
    }
  };
}

export function setAccountBlacklist(mail, value) {
  return async (dispatch, getState) => {
    const { active } = getState().sources;
    dispatch({
      type: types.UPDATE_SOURCE_BLACKLIST_REQUEST,
      payload: active,
    });
    try {
      const { data } = await updateAccountBlacklist(active, mail, value);
      dispatch({
        type: types.UPDATE_SOURCE_BLACKLIST_SUCCEEDED,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: types.UPDATE_SOURCE_BLACKLIST_FAILED,
        payload: err,
      });
    }
  };
}

export function modifySourceOwner(mail, oldAccount, newAccount) {
  return async (dispatch, getState) => {
    const { sources, active } = getState().sources;
    dispatch({
      type: types.UPDATE_SOURCE_REQUEST,
      payload: active,
    });
    try {
      const newSource = getSourceWithUpdatedOwner({
        sources, mail, oldAccount, newAccount, active,
      });
      const newSourceList = sources.map((currentSource) => (
        (currentSource.id === active)
          ? newSource
          : currentSource
      ));
      await updateSourceOwner(active, newSource);
      dispatch({
        type: types.UPDATE_SOURCE_SUCCEEDED,
        payload: newSourceList,
      });
    } catch (err) {
      dispatch({
        type: types.UPDATE_SOURCE_FAILED,
        payload: err,
      });
    }
  };
}

export function setImportStatus(email, importId, state) {
  return (dispatch) => {
    dispatch({
      type: types.SET_IMPORT_STATUS,
      payload: { importId, status: state, email },
    });
  };
}

export function deleteAccountByMail(mail, deleteContact = false) {
  return async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_SOURCE_REQUEST,
    });
    try {
      const { sources, active } = getState().sources;
      const activeSource = sources.find((source) => source.id === active);
      const index = sources.map((i) => i.id).indexOf(active);
      const newSourceAccounts = activeSource.accounts.filter((source) => source.account !== mail);
      const newSource = {
        ...activeSource,
        accounts: newSourceAccounts,
      };
      const newSourceList = [
        ...sources.slice(0, index),
        newSource,
        ...sources.slice(index + 1),
      ];
      if (!deleteContact) {
        await deleteAccountFromSource(active, newSource);
        dispatch({
          type: types.UPDATE_SOURCE_SUCCEEDED,
          payload: newSourceList,
        });
      } else {
        await deleteAccountAndContactsFromSource(active, newSource);
        dispatch({
          type: types.UPDATE_SOURCE_SUCCEEDED,
          payload: newSourceList,
        });
      }
    } catch (err) {
      dispatch({
        type: types.UPDATE_SOURCE_FAILED,
        payload: err,
      });
    }
  };
}

export function deleteSourceFromAccount(mail, account) {
  return async (dispatch, getState) => {
    const { sources, active } = getState().sources;
    dispatch({
      type: types.UPDATE_SOURCE_REQUEST,
      payload: active,
    });
    try {
      const source = sources.find((s) => s.id === active);
      const { accounts } = source;

      const issuers = accounts.find((a) => a.email === account)?.issuer || [];
      const list = accounts.map((currAccount) => ((currAccount.email === account)
        ? { ...currAccount, issuer: issuers.filter((issuer) => issuer.id !== mail) }
        : currAccount
      ));

      const newSourceList = sources
        .map((currSource) => ((currSource.id === active)
          ? { ...currSource, isUpdating: false, accounts: list }
          : currSource));

      await deleteMailSourceFromAccount(active, { ...source, isUpdating: false, accounts: list });

      dispatch({
        type: types.UPDATE_SOURCE_SUCCEEDED,
        payload: newSourceList,
      });
    } catch (err) {
      dispatch({
        type: types.UPDATE_SOURCE_FAILED,
        payload: err,
      });
    }
  };
}

export function flushByImportId(importId) {
  return async (dispatch) => {
    try {
      const { data } = await flushSource(importId);
      if (data.statut === 'OK') {
        dispatch(addToast({
          type: 'success',
          title: 'Flush has been done successfully',
          description: '',
        }));
      } else {
        dispatch(addToast({
          type: 'error',
          title: 'Flush has failed',
          description: `An error occurred : ${data.msg}`,
        }));
      }
    } catch (err) {
      dispatch(addToast({
        type: 'error',
        title: 'Flush has failed',
        description: `A technical error occurred : ${err}`,
      }));
    }
  };
}
