import * as types from './type';
import * as status from '../../status';

const initialState = {
  global: {
    status: status.IDLE,
    data: null,
    error: null,
  },
  project: {
    projectId: null,
    status: status.IDLE,
    data: null,
    error: null,
  },
};

export default function blacklistReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_GLOBAL_BLACKLIST_REQUEST:
      return {
        ...state,
        global: {
          ...state.global,
          status: status.LOADING,
        },
      };
    case types.FETCH_GLOBAL_BLACKLIST_SUCCEEDED:
      return {
        ...state,
        global: {
          ...state.global,
          status: status.SUCCEEDED,
          data: payload,
        },
      };
    case types.FETCH_GLOBAL_BLACKLIST_FAILED:
      return {
        ...state,
        global: {
          ...state.global,
          status: status.FAILED,
          data: null,
          error: payload,
        },
      };
    case types.SEND_BLACKLIST_REQUEST:
      return {
        ...state,
        global: {
          ...state.global,
          [payload]: status.LOADING,
        },
      };
    case types.SEND_BLACKLIST_SUCCEEDED:
      return {
        ...state,
        global: {
          ...state.global,
          data: [...state.global.data, ...payload.data],
          [payload.type]: status.SUCCEEDED,
        },
      };
    case types.SEND_BLACKLIST_FAILED:
      return {
        ...state,
        global: {
          ...state.global,
          error: payload.error,
          [payload.type]: status.FAILED,
        },
      };
    case types.DELETE_BLACKLIST_REQUEST:
      return {
        ...state,
        global: {
          ...state.global,
          [payload]: status.LOADING,
        },
      };
    case types.DELETE_BLACKLIST_SUCCEEDED:
      return {
        ...state,
        global: {
          ...state.global,
          data: state.global.data
            .filter((expression) => (
              !(expression.type === payload.type.toUpperCase()
              && payload.data.includes(expression.regex))
            )),
          [payload.type]: status.SUCCEEDED,
        },
      };
    case types.DELETE_BLACKLIST_FAILED:
      return {
        ...state,
        global: {
          ...state.global,
          error: payload.error,
          [payload.type]: status.FAILED,
        },
      };

    case types.FETCH_BLACKLIST_REQUEST:
      return {
        ...state,
        project: {
          ...state.project,
          projectId: payload,
          status: status.LOADING,
        },
      };
    case types.FETCH_BLACKLIST_SUCCEEDED:
      return {
        ...state,
        project: {
          ...state.project,
          status: status.SUCCEEDED,
          data: payload,
        },
      };
    case types.FETCH_BLACKLIST_FAILED:
      return {
        ...state,
        project: {
          ...state.project,
          status: status.FAILED,
          data: null,
          error: payload,
        },
      };
    case types.SEND_BLACKLIST_PROJECT_REQUEST:
      return {
        ...state,
        project: {
          ...state.project,
          projectId: payload.id,
          [payload.type]: status.LOADING,
        },
      };
    case types.SEND_BLACKLIST_PROJECT_SUCCEEDED:
      return {
        ...state,
        project: {
          ...state.project,
          data: [...state.project.data, ...payload.data],
          [payload.type]: status.SUCCEEDED,
        },
      };
    case types.SEND_BLACKLIST_PROJECT_FAILED:
      return {
        ...state,
        project: {
          ...state.project,
          error: payload.error,
          [payload.type]: status.FAILED,
        },
      };
    case types.DELETE_BLACKLIST_PROJECT_REQUEST:
      return {
        ...state,
        project: {
          ...state.project,
          [payload]: status.LOADING,
        },
      };
    case types.DELETE_BLACKLIST_PROJECT_SUCCEEDED:
      return {
        ...state,
        project: {
          ...state.project,
          data: state.project.data.filter((expression) => (
            !(expression.type === payload.type.toUpperCase()
            && payload.data.includes(expression.regex))
          )),
          [payload.type]: status.SUCCEEDED,
        },
      };
    case types.DELETE_BLACKLIST_PROJECT_FAILED:
      return {
        ...state,
        project: {
          ...state.project,
          error: payload.error,
          [payload.type]: status.FAILED,
        },
      };

    default:
      return state;
  }
}
