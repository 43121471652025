import * as types from './type';
import {
  getAllBlacklistedExpression, updateBlacklistByType, deleteBlacklistByType,
  getBlacklistedExpressionByProject,
  addBlacklistedExpressionByTypeByProject,
  deleteBlacklistedExpressionByTypeByProject,
} from '../../../clients/lib/contacts';

export function fetchGlobaLBlacklist() {
  return async (dispatch) => {
    dispatch({
      type: types.FETCH_GLOBAL_BLACKLIST_REQUEST,
    });
    try {
      const { data } = await getAllBlacklistedExpression();
      if (data.status === 'OK') {
        dispatch({
          type: types.FETCH_GLOBAL_BLACKLIST_SUCCEEDED,
          payload: data.data,
        });
      } else {
        dispatch({
          type: types.FETCH_GLOBAL_BLACKLIST_FAILED,
          payload: data.msg,
        });
      }
    } catch (err) {
      dispatch({
        type: types.FETCH_GLOBAL_BLACKLIST_FAILED,
        payload: err,
      });
    }
  };
}

export function blacklistGlobalByType(type, expressions) {
  return async (dispatch) => {
    dispatch({ type: types.SEND_BLACKLIST_REQUEST, payload: type });
    try {
      const { data } = await updateBlacklistByType(type, expressions);
      if (data.status === 'OK') {
        dispatch({
          type: types.SEND_BLACKLIST_SUCCEEDED,
          payload: {
            type,
            data: expressions
              .map((regex) => ({ project: 0, type: type.toUpperCase(), regex })),
          },
        });
      } else {
        dispatch({
          type: types.SEND_BLACKLIST_FAILED,
          payload: { type, error: data.msg },
        });
      }
    } catch (err) {
      dispatch({
        type: types.SEND_BLACKLIST_FAILED,
        payload: { type, error: err },
      });
    }
  };
}

export function unblacklistGlobalByType(type, expressions) {
  return async (dispatch) => {
    dispatch({ type: types.DELETE_BLACKLIST_REQUEST, payload: type });
    try {
      const { data } = await deleteBlacklistByType(type, expressions);
      if (data.status === 'OK') {
        dispatch({
          type: types.DELETE_BLACKLIST_SUCCEEDED,
          payload: { type, data: expressions },
        });
      } else {
        dispatch({
          type: types.DELETE_BLACKLIST_FAILED,
          payload: { type, error: data.msg },
        });
      }
    } catch (err) {
      dispatch({
        type: types.DELETE_BLACKLIST_FAILED,
        payload: { type, error: err },
      });
    }
  };
}

export function fetchBlacklistByProject(id) {
  return async (dispatch) => {
    dispatch({
      type: types.FETCH_BLACKLIST_REQUEST,
      payload: id,
    });
    try {
      const { data } = await getBlacklistedExpressionByProject(id);
      if (data.status === 'OK') {
        dispatch({
          type: types.FETCH_BLACKLIST_SUCCEEDED,
          payload: data.data,
        });
      } else {
        dispatch({
          type: types.FETCH_BLACKLIST_FAILED,
          payload: data.msg,
        });
      }
    } catch (err) {
      console.error('[ERROR] : ', err);
      dispatch({
        type: types.FETCH_BLACKLIST_FAILED,
        payload: err,
      });
    }
  };
}

export function blacklistExpressionByType(type, id, expressions) {
  return async (dispatch) => {
    dispatch({ type: types.SEND_BLACKLIST_PROJECT_REQUEST, payload: { type, id } });
    try {
      const { data } = await addBlacklistedExpressionByTypeByProject(type, id, expressions);
      if (data.status === 'OK') {
        dispatch({
          type: types.SEND_BLACKLIST_PROJECT_SUCCEEDED,
          payload: {
            type,
            id,
            data: expressions
              .map((regex) => ({ project: id, type: type.toUpperCase(), regex })),
          },
        });
      } else {
        dispatch({
          type: types.SEND_BLACKLIST_PROJECT_FAILED,
          payload: { type, id, error: data.msg },
        });
      }
    } catch (err) {
      dispatch({
        type: types.SEND_BLACKLIST_PROJECT_FAILED,
        payload: { type, id, error: err },
      });
    }
  };
}

export function unblacklistExpressionByType(type, id, expressions) {
  return async (dispatch) => {
    dispatch({ type: types.DELETE_BLACKLIST_PROJECT_REQUEST, payload: { type, id } });
    try {
      const { data } = await deleteBlacklistedExpressionByTypeByProject(type, id, expressions);
      if (data.status === 'OK') {
        dispatch({
          type: types.DELETE_BLACKLIST_PROJECT_SUCCEEDED,
          payload: { type, id, data: expressions },
        });
      } else {
        dispatch({
          type: types.DELETE_BLACKLIST_PROJECT_FAILED,
          payload: { type, id, error: data.msg },
        });
      }
    } catch (err) {
      dispatch({
        type: types.DELETE_BLACKLIST_PROJECT_FAILED,
        payload: { type, id, error: err },
      });
    }
  };
}
