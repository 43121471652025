import * as types from './types';
import { getContactDetails, getContactsDetailsByProject } from '../../../clients/lib/contacts';

export function setSelectedContact(mail) {
  return async (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_CONTACT,
      payload: mail,
    });
    try {
      const { data } = await getContactDetails(mail);
      if (data.status === 'OK') {
        dispatch({
          type: types.FETCH_CONTACT_DETAILS_SUCCESS,
          payload: data.data,
        });
      } else {
        dispatch({
          type: types.FETCH_CONTACT_DETAILS_FAIL,
          payload: data.msg,
        });
      }
    } catch (err) {
      dispatch({
        type: types.FETCH_CONTACT_DETAILS_FAIL,
        payload: 'Une erreur technique est survenue, veuillez réessayer ultérieurement',
      });
    }
  };
}

export function setSelectedProject(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: types.SET_SELECTED_PROJECT,
      payload: id,
    });
    try {
      const { data } = await getContactsDetailsByProject(getState().details.selectedMail, id);
      if (data.status === 'OK') {
        dispatch({
          type: types.FETCH_PROJECT_SUCCEEDED,
          payload: data.data,
        });
      } else {
        dispatch({
          type: types.FETCH_PROJECT_FAILED,
          payload: data.msg,
        });
      }
    } catch (err) {
      dispatch({
        type: types.FETCH_PROJECT_FAILED,
        payload: err,
      });
    }
  };
}

export function setSelectedContactsByProject(mail, id) {
  return async (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_PROJECT,
      payload: id,
    });
    try {
      const { data } = await getContactsDetailsByProject(mail, id);
      if (data.status === 'OK') {
        dispatch({
          type: types.FETCH_PROJECT_SUCCEEDED,
          payload: data.data,
        });
      } else {
        dispatch({
          type: types.FETCH_PROJECT_FAILED,
          payload: data.msg,
        });
      }
    } catch (err) {
      dispatch({
        type: types.FETCH_PROJECT_FAILED,
        payload: err,
      });
    }
  };
}
