import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ImportManagerContactData from './ImportManagerContactData';
import { fetchContactsByProjectId, setActiveProject } from '../../../services/store/features/contacts/actions';
import { setSelectedContactsByProject } from '../../../services/store/features/contactDetails/actions';

const mapStateToProps = (state) => ({
  contactStatus: state.contacts.contacts?.find((list) => list.id === state.contacts.active)?.status || 'IDLE',
  contacts: state.contacts.contacts?.find((list) => list.id === state.contacts.active)?.data,
  details: state.details.details,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchContactsByProjectId,
    setActiveProject,
    setSelectedContactsByProject,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportManagerContactData);
