import React, { useState, useRef } from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckCircle, Error } from '@material-ui/icons';

import styles from './ServerImapPopUp.module.css';
import useOutsideClickAlert from '../../../../../../../components/library/hook/clickOutside';
import InputText from '../../../../../../../components/library/Input/InputText/InputText';
import Button from '../../../../../../../components/library/Button/Button';
import { setImapServer } from '../../../../../../../services/clients/lib/import';

export default function ServerImapPopUp({ importId, close }) {
  const [status, setStatus] = useState('IDLE');
  const [error, setError] = useState(null);
  const [imap, setImap] = useState('');
  const [shouldRestart, setShouldRestart] = useState(true);

  const popup = useRef();
  useOutsideClickAlert(popup, close);

  const submit = async () => {
    setStatus('LOADING');
    setError(null);
    try {
      const { data } = await setImapServer(importId, imap, shouldRestart);
      if (data.status === 'OK') {
        setStatus('SUCCESS');
        setError(null);
      } else {
        setStatus('FAIL');
        setError(data?.msg || 'Une erreur est survenue, veuillez réessayer');
      }
    } catch (err) {
      setStatus('FAIL');
      setError(`Une erreur serveur est survenue, veuillez réessayer plus tard (Error status: ${err.response.status}`);
    }
  };

  const handleShouldRestart = (e) => setShouldRestart(e.target.checked);

  return (
    <div className={styles.background}>
      <section
        className={styles.content}
        ref={popup}
      >
        <header className={styles.header}>
          <h1>
            Configuration serveur IMAP
          </h1>
        </header>
        <main className={styles.main}>
          {
            (status === 'FAIL')
            && (
              <p className={styles.error}>
                <span>
                  <Error />
                </span>
                { error }
              </p>
            )
          }
          {
            (status === 'SUCCESS')
            && (
              <p className={styles.success}>
                <span>
                  <CheckCircle className={styles.icon} />
                </span>
                Le serveur IMAP a été modifié avec succès
              </p>
            )
          }
          <InputText
            label="Serveur IMAP"
            valueInput={imap}
            onTextChange={(e) => setImap(e.target.value)}
            state={(error !== null) ? 'ERROR' : 'DEFAULT'}
            hint={error}
            disabled={status === 'SUCCESS'}
          />
          <label
            htmlFor="restart"
            className={styles.restart}
          >
            <input
              id="restart"
              name="restart"
              type="checkbox"
              checked={shouldRestart}
              className={styles.checkbox}
              onChange={handleShouldRestart}
            />
            Auto restart
          </label>
          <div className={styles.buttonGroup}>
            <Button
              label={(status !== 'SUCCESS') ? 'Annuler' : 'Fermer'}
              variant="ghost"
              onButtonClick={close}
            />
            {
              (status !== 'SUCCESS')
              && (
                <Button
                  label={(status === 'LOADING') ? 'Envoi en cours...' : 'Envoyer'}
                  icon={(status === 'LOADING') && <FontAwesomeIcon icon={faSpinner} spin />}
                  onButtonClick={submit}
                  disabled={imap.trim() === ''}
                />
              )
            }
          </div>
        </main>
      </section>
    </div>
  );
}
