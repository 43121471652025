import React, { useState, useEffect } from 'react';

import Loading from '../../../../components/library/Loading';
import ErrorAnimation from '../../../../components/library/ErrorAnimation/ErrorAnimation';
import getExchangeById from '../../../../services/clients/lib/exchanges';

import styles from './MailReader.module.css';

export default function MailReader({ exchangeId = '0' }) {
  const [mail, setMail] = useState({ id: exchangeId, status: 'IDLE' });
  const [isErrorOpen, setIsErrorOpen] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      try {
        setMail({ id: exchangeId, status: 'LOADING' });
        const { data } = await getExchangeById(exchangeId);
        if (!isCancelled) {
          if (data.status === 'OK') {
            setMail({ ...data.data, status: 'SUCCESS' });
          } else {
            setMail((state) => ({ ...state, status: 'ERROR', error: `${data.msg}` }));
          }
        }
      } catch (err) {
        if (!isCancelled) {
          setMail((state) => ({ ...state, status: 'ERROR', error: `${err}` }));
        }
      }
    };
    if (exchangeId) {
      fetchData();
    }
    return () => { isCancelled = true; };
  }, [exchangeId]);

  return (
    <section className={styles.reader}>
      {
        (!exchangeId) && (
          <div>
            <p>
              Sélectionner un email
            </p>
          </div>
        )
      }
      {
        (exchangeId && (mail.status === 'IDLE' || mail.status === 'LOADING'))
        && (<Loading />)
      }
      {
        (mail.status === 'SUCCESS')
        && (
          <div className="fadeIn">
            <p className={styles.date}>
              { new Date(mail.date).toLocaleDateString('fr', {
                weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
              }) }
            </p>
            <p className={styles.title}>
              { mail.subject }
            </p>
            <div className={styles.contact}>
              <p>
                {`From: ${mail?.from?.fullname || mail?.from?.email}`}
              </p>
              <p>
                {`To: ${mail?.to[0]?.fullname || mail?.to[0]?.email}`}
              </p>
            </div>
            <p className={`${styles.text} ${(mail.text === '') ? styles.empty : ''}`}>
              { mail.text || '( Vide )'}
            </p>
          </div>
        )
      }
      {
        (exchangeId && mail.status === 'ERROR')
        && (
          <div className={`fadeIn ${styles.error}`}>
            <div className={styles.errorPopup}>
              <ErrorAnimation />
              <p>
                Une erreur est survenue lors du chargement du mail
              </p>
              <button
                type="button"
                onClick={() => setIsErrorOpen((state) => !state)}
              >
                Voir les détails de l&apos;erreur
              </button>
              {
              (isErrorOpen)
              && (
              <p className={styles.hint}>{ mail.error }</p>
              )
            }
            </div>
          </div>
        )
      }
    </section>
  );
}
