import React from 'react';
import shared from '../../../styles/shared.module.css';

export default function HistoryLayout({ children }) {
  return (
    <section className={shared.section}>
      <header className={shared.header}>
        <h1 className={shared.title}>
          Historique des logs
        </h1>
      </header>
      <main>
        { children }
      </main>
    </section>
  );
}
