const LOGS = {
  0: { id: '0', description: 'AffichageInscription' },
  1: { id: '1', description: 'AffichageConnexion' },
  2: { id: '2', description: 'InscriptionHoolders' },
  3: { id: '3', description: 'InscriptionLinkedin' },
  4: { id: '4', description: 'InscriptionFacebook' },
  5: { id: '5', description: 'ConnexionHoolders' },
  6: { id: '6', description: 'ConnexionLinkedin' },
  7: { id: '7', description: 'ConnexionFacebook' },
  8: { id: '8', description: 'mdpOublierAccess' },
  9: { id: '9', description: 'validationPageAccess' },
  10: { id: '10', description: 'connexionAfterValidation' },
  11: { id: '11', description: 'pageConnexion-clickLinkedin' },
  12: { id: '12', description: 'pageConnexion-clickFacebook' },
  13: { id: '13', description: 'pageConnexion-clickSeConnecter' },
  14: {
    id: '14',
    description: 'pageConnexion-ClickReinitialiserMotDepasse',
  },
  15: { id: '15', description: 'pageConnexion-clickSinscrire' },
  16: { id: '16', description: 'pageInscription-clickLinkedin' },
  17: { id: '17', description: 'pageInscription-clickFacebook' },
  18: { id: '18', description: 'pageInscription-clickSinscrireAvecEmail' },
  19: { id: '19', description: 'pageInscription-clickDejaUnCompte' },
  20: { id: '20', description: 'pageInscription-validerInscription' },
  21: { id: '21', description: 'pageInscription-annulerInscription' },
  22: { id: '22', description: 'pageRecuperation-annuler' },
  23: {
    id: '23',
    description: 'pageRecuperation-clickreinitialisermotdepasse',
  },
  24: {
    id: '24',
    description: 'page /compte/token/?token= - clickConfirmerCompte',
  },
  25: {
    id: '25',
    description: 'page /compte/token/?token= - clickRenvoyerCode',
  },
  26: {
    id: '26',
    description: 'page /validation/token/?token= - clickConfirmerCompte',
  },
  27: {
    id: '27',
    description: 'page /validation/token/?token= - clickRenvoyerCode',
  },
  28: { id: '28', description: 'InscriptionLinkedinNok' },
  29: { id: '29', description: 'InscriptionFacebookNok' },
  30: { id: '30', description: 'ConnexionLinkedinNok' },
  31: { id: '31', description: 'ConnexionFacebookNok' },
  32: {
    id: '32',
    description: 'pageConnexion - cliquer ici pour l\\activer',
  },
  33: {
    id: '33',
    description: 'click bouton reserver - reservationPage Echeancier',
  },
  34: { id: '34', description: 'pageLandCofunding - clickSoutenir' },
  35: { id: '35', description: 'pageLandCofunding - videoClick' },
  36: { id: '36', description: 'partage' },
  37: { id: '37', description: 'pageLandCofunding - accesPage' },
  38: { id: '38', description: 'documentTelechargement' },
  39: { id: '39', description: 'soutienModalCliqueNok' },
  40: { id: '40', description: 'reservationModalCliqueNok' },
  41: { id: '41', description: 'soutienModalCliqueOk' },
  42: { id: '42', description: 'Investir reservationModalCliqueOk' },
  43: { id: '43', description: 'partagePageFacebook' },
  44: { id: '44', description: 'partagePageLinkedin' },
  45: { id: '45', description: 'partageModalFacebook' },
  46: { id: '46', description: 'partageModalLinkedin' },
  47: { id: '47', description: 'partageModalTwitter' },
  48: { id: '48', description: 'cliqueLogoHoolders' },
  49: { id: '49', description: 'cliqueChampInvestPage' },
  50: { id: '50', description: 'cliqueChampInvestModal' },
  51: { id: '51', description: 'scrollEnDessousHeader' },
  52: { id: '52', description: 'clicHeaderInscription' },
  53: { id: '53', description: 'modalInvestAgainClicAjouter' },
  54: { id: '54', description: 'modalInvestAgainClicModify' },
  55: { id: '55', description: 'modalModifyClickConfirm' },
  56: { id: '56', description: 'modalModifyClickNo' },
  57: { id: '57', description: 'Page lanfcofunding - click investir' },
  58: { id: '58', description: 'partagePageTwitter' },
  59: { id: '59', description: '2min on page' },
  60: { id: '60', description: 'clickOnInputAmount Echeancier' },
  61: { id: '61', description: '5min on page' },
  62: { id: '62', description: '10min on page' },
  63: { id: '63', description: '30min on page' },
  64: { id: '64', description: 'clickChampUserEmail' },
  65: { id: '65', description: 'clickChampUserNumero' },
  66: { id: '66', description: 'clickCodeValidation' },
  67: { id: '67', description: 'clickValiderDocConfidentiel' },
  68: { id: '68', description: 'clickVerifierCodeSMS' },
  69: { id: '69', description: 'clickTelechargementOpen' },
  70: { id: '70', description: 'lickdocumentConfidentielOpen' },
  71: { id: '71', description: 'modalClickFermer' },
  72: { id: '72', description: 'clickChampUserLastName' },
  73: { id: '73', description: 'clickChampUserFirstName' },
  74: { id: '74', description: 'documentConfidentielTelechargement' },
  75: { id: '75', description: 'seeAssociateLinkedIn' },
  76: { id: '76', description: 'lickButtonInvestir OpenPopUp' },
  77: { id: '77', description: 'presResaMauvaisMontant' },
  78: { id: '78', description: 'TransfertVersPageContactFinResa' },
  79: { id: '79', description: 'ransfertVersPageContactFinResa Error' },
  80: { id: '80', description: 'rriverPageContact' },
  81: { id: '81', description: 'PageContactClickPaiement' },
  82: { id: '82', description: 'PageContactClickRetourProjet' },
  83: { id: '83', description: 'PageSepaLWArriver' },
  84: { id: '84', description: 'PageSepaStripeArriver' },
  85: { id: '85', description: 'PageSepaLwClickRetour' },
  86: { id: '86', description: 'PageSepaStripeClickRetour' },
  87: { id: '87', description: 'PageSepaLwClickVirement' },
  88: { id: '88', description: 'PageSepaStripeClickVirement' },
  89: { id: '89', description: 'PageSepaLWClickValiderPayer' },
  90: { id: '90', description: 'PageSepaStripeClickValiderPayer' },
  91: { id: '91', description: 'PageVirementLwClickRetour' },
  92: { id: '92', description: 'PageVirementStripeClickRetour' },
  93: { id: '93', description: 'PageVirementLwClickSepa' },
  94: { id: '94', description: 'PageVirementStripeClickSepa' },
  95: { id: '95', description: 'PageVirementLwClickDone' },
  96: { id: '96', description: 'PageVirementStripeClickDone' },
  97: { id: '97', description: 'ArriverPageVirementLw' },
  98: { id: '98', description: 'ArriverPageVirementStripe' },
  99: { id: '99', description: 'ransfertVersLwPaiementCB' },
  100: { id: '100', description: 'ArriverPageCBStripe' },
  101: { id: '101', description: 'PageCBRetourStripe' },
  102: { id: '102', description: 'PageCBClickVirementStripe' },
  103: { id: '103', description: 'PageCbClickPayerStripe' },
  104: { id: '104', description: 'PageCbCarteErrorStripe' },
  105: { id: '105', description: 'PageCbOkTransfertStripe' },
  106: { id: '106', description: 'ArriverPageRetourCBLw' },
  107: { id: '107', description: 'ArriverPageRetourCBStripe' },
  108: { id: '108', description: 'PageRetourCBLwClickRetour' },
  109: { id: '109', description: 'PageRetourCBLwClickAutreMoyen' },
  110: { id: '110', description: 'PageRetourCBLwClickByCard' },
  111: { id: '111', description: 'PageRetourCBStripeClickRetour' },
  112: { id: '112', description: 'PageRetourCBStripeClickAutreMoyen' },
  113: { id: '113', description: 'PageRetourCBStripeByCard' },
  114: { id: '114', description: 'TransfertOnlineSign' },
  115: { id: '115', description: 'RetourOnlineSign' },
  116: { id: '116', description: 'PageEntrepriseClickEmailEquipe' },
  117: { id: '117', description: 'PageSepaStripeAcceptRulesPopup' },
  118: { id: '118', description: 'PageSepaStripeRefuseRulesPopup' },
  119: { id: '119', description: 'FacebookSuiviInvestclick' },
  120: { id: '120', description: 'ollsHojarInterested' },
  121: { id: '121', description: 'ollsHojarNotInterested' },
  122: { id: '122', description: 'PageProjetCharger' },
  123: { id: '123', description: 'Take RDV' },
  124: { id: '124', description: 'ouvrir mail' },
  125: { id: '125', description: 'click mail' },
  126: { id: '126', description: 'unsubscribe' },
  127: { id: '127', description: 'BlacklistP2P' },
  128: { id: '128', description: 'A investi (payer)' },
  129: { id: '129', description: 'Send Email' },
  130: { id: '130', description: 'unBlacklistP2P' },
  131: { id: '131', description: "N'investira pas" },
  132: { id: '132', description: 'Inscription event investisseur' },
  133: { id: '133', description: 'Pop-up emmeteur no acces page projet' },
  134: { id: '134', description: 'scroll 50% + page projet' },
  135: { id: '135', description: 'scroll 75% + page projet' },
  136: { id: '136', description: 'affichage pop-up de fin de page' },
  137: { id: '137', description: 'remplissage pop-up fin de page' },
  138: { id: '138', description: 'validation pop-up de fin de page' },
  139: { id: '139', description: "Clique sur m'alerter" },
  140: { id: '140', description: 'Clique sur équipe' },
  141: { id: '141', description: 'Clique sur finance' },
  142: { id: '142', description: 'Clique sur questions/responses' },
  143: { id: '143', description: 'Clique sur commentaires' },
  144: { id: '144', description: "Clique sur obtenir l'accès" },
  145: { id: '145', description: 'Clique sur Whats app' },
  146: { id: '146', description: 'Affichage du captcha' },
  147: { id: '147', description: 'Tentative resolution captcha' },
  148: { id: '148', description: 'Resolution success captcha' },
  '-4': {
    id: ':----',
    description: ':-----------------------------------------------------:',
  },
  '-3': { id: '-3', description: 'Ayomi Blacklist' },
  '-2': { id: '-2', description: 'Soft Bounce' },
  '-1': { id: '-1', description: 'Bounce' },
};

export default LOGS;
