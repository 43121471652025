import React, { useState, useEffect, useRef } from 'react';
import Proptypes from 'prop-types';
import { CheckCircle, Error } from '@material-ui/icons';

import Button from '../../../../../../../components/library/Button/Button';
import useOutsideClickAlert from '../../../../../../../components/library/hook/clickOutside';

import styles from './AccountDetails.module.css';
import { getDetailsByImportId } from '../../../../../../../services/clients/lib/sources';
import { rerunImportByMail } from '../../../../../../../services/clients/lib/import';
import copyTextToClipboard from '../../../../../../../utils/copyToClipboard';

export default function AccountDetails({ close, importId }) {
  const [details, setDetails] = useState({ status: 'IDLE', data: {}, error: null });
  const [relaunch, setRelaunch] = useState('IDLE');
  const [error, setError] = useState();
  const [copy, setCopy] = useState('Copier');

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const { data } = await getDetailsByImportId(importId);
        if (data.status === 'OK') {
          setDetails({ status: 'SUCCESS', data: data.data, error: null });
        } else {
          setDetails({ status: 'ERROR', data: null, error: data.msg });
        }
      } catch (err) {
        setDetails({ status: 'ERROR', data: null, error: err });
      }
    };
    fetchDetails();
  }, []);

  const popup = useRef();
  useOutsideClickAlert(popup, close);

  const handleCopy = () => {
    const onSuccess = () => setCopy('Copié !');
    const onError = () => setCopy('Erreur :(');
    copyTextToClipboard(details?.data?.details?.password.trim(), onSuccess, onError);
    window.setTimeout(() => setCopy('Copier'), 2000);
  };

  const handleRelaunch = async () => {
    setRelaunch('LOADING');
    try {
      const { data } = await rerunImportByMail({
        id: details.data?.projectId,
        account: details.data?.details?.email,
        issuer: details.data?.details?.id,
        type: details.data?.details?.type,
      });
      if (data.status === 'OK') {
        setRelaunch('SUCCESS');
      } else {
        setRelaunch('FAIL');
        setError(data.msg || 'Une erreur est survenue');
      }
    } catch (err) {
      setRelaunch('FAIL');
      setError(`${err || 'ERREUR'}`);
    }
  };

  if (details.status !== 'SUCCESS') {
    return (
      <div className={styles.background}>
        <section
          className={styles.content}
          ref={popup}
        >
          <header className={styles.header}>
            <h1>
              Détails de l&apos;importation
            </h1>
          </header>
          <main className={styles.listContainer} />
        </section>
      </div>
    );
  }

  return (
    <div className={styles.background}>
      <section
        className={styles.content}
        ref={popup}
      >
        <header className={styles.header}>
          <h1>
            Détails de l&apos;importation
          </h1>
          <p className={styles.issuer}>
            {details.data?.details?.email}
          </p>
        </header>
        <main className={styles.listContainer}>
          {
            (details?.data)
              ? (
                <>
                  <ul className={styles.list}>
                    <li
                      className={styles.item}
                    >
                      <span className={styles.bold}>
                        projectId
                      </span>
                      <span>
                        {details.data?.projectId}
                      </span>
                    </li>
                    {
                      Object.keys(details?.data?.status).map((key) => (
                        <li
                          key={key}
                          className={styles.item}
                        >
                          <span className={styles.bold}>
                            {' '}
                            { key }
                            {' '}
                          </span>
                          <span>
                            {' '}
                            { details?.data?.status[key] }
                            {' '}
                          </span>
                        </li>
                      ))
                    }
                  </ul>
                  <ul className={styles.list}>
                    {
                      Object.keys(details?.data?.details).map((key) => (
                        <li
                          key={key}
                          className={styles.item}
                        >
                          <span className={styles.bold}>
                            {' '}
                            { key }
                            {' '}
                          </span>
                          <div className={styles.password}>
                            {' '}
                            { details?.data?.details[key] }
                            {' '}
                            {
                              (key === 'password')
                              && (
                                <Button
                                  label={copy}
                                  variant="outline"
                                  size="small"
                                  className={styles.button}
                                  onButtonClick={handleCopy}
                                />
                              )
                            }
                          </div>
                        </li>
                      ))
                    }
                  </ul>
                </>
              )
              : (
                <p>
                  Aucun détails à afficher
                </p>
              )
          }
        </main>
        {
          (error)
          && (
            <div className={styles.status}>
              <p className={styles.error}>
                <Error className={styles.icon} />
                <span>
                  {error}
                </span>
              </p>
            </div>
          )
        }
        {
          (relaunch === 'SUCCESS')
          && (
            <div className={styles.status}>
              <p className={styles.success}>
                <CheckCircle className={styles.icon} />
                <span>
                  La relance a été effectuée
                </span>
              </p>
            </div>
          )
        }
        <footer className={styles.footer}>
          <Button
            label="Relancer"
            onButtonClick={handleRelaunch}
            disabled={relaunch !== 'IDLE'}
            className={styles.relaunch}
          />
          <Button
            label="Fermer"
            onButtonClick={close}
          />
        </footer>
      </section>
    </div>
  );
}

AccountDetails.defaultProps = {
  details: undefined,
};

AccountDetails.propTypes = {
  close: Proptypes.func.isRequired,
  details: Proptypes.shape({}),
};
