import React from 'react';
import PropTypes from 'prop-types';

import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

import styles from './Searchbar.module.css';

export default function Searchbar({ query, setQuery, placeholder = 'Rechercher une source' }) {
  return (
    <div className={styles.searchbar}>
      <input
        type="text"
        className={styles.input}
        value={query || ''}
        placeholder={placeholder}
        onChange={(e) => setQuery(e.target.value)}
      />
      {
        (query !== '')
          ? (
            <button
              className={styles.button}
              type="button"
              aria-label="Annuler la recherche"
              onClick={() => setQuery('')}
            >
              <ClearIcon className={styles.icon} />
            </button>
          )
          : (
            <SearchIcon className={styles.icon} />
          )
      }
    </div>
  );
}

Searchbar.defaultProps = {
  query: '',
};

Searchbar.propTypes = {
  query: PropTypes.string,
  setQuery: PropTypes.func.isRequired,
};
