import React, { useState } from 'react';
import {
  Visibility, VisibilityOff,
} from '@material-ui/icons';

import styles from '../GlobalTable.module.css';
import stylesRow from './GlobalTableRow.module.css';
import AccountDetails from '../../../../components/AccountDetails';
import ServerImapPopUp
  from '../../../ImportManager/ImportManagerSource/ImportManagerSourceTable/ImportManagerSourceTableRows/ImportManagerSourceTableRowsAccount/ServerImapPopUp';
import GlobalTableRowEdit from './GlobalTableRowEdit';
import { statusList } from '../../../../utils/statusList';

export default function GlobalTableRow({ item, actions }) {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isImapOpen, setIsImapOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const getStatus = (issuer) => statusList[issuer.statut];

  const getAllStatus = () => (
    Object.keys(statusList).map((key) => ({ value: key, color: statusList[key] })));

  const handleOpenImap = () => {
    setIsImapOpen(true);
  };

  if (isEditMode) {
    return (
      <GlobalTableRowEdit
        item={item}
        getStatus={getStatus}
        statusList={getAllStatus()}
        close={() => setIsEditMode(false)}
        setStatus={actions.setImportStatus}
      />
    );
  }

  return (
    <>
      {
        (isImapOpen) && (
          <ServerImapPopUp
            close={() => setIsImapOpen(false)}
            importId={item.importId}
          />
        )
      }
      <div
        className={`${styles.grid} ${styles.row} ${stylesRow.issuer}`}
        key={item.importId}
      >
        <p>{ item.time_start }</p>
        <p>{ item.importId }</p>
        <div />
        <div
          onDoubleClick={() => setIsEditMode(true)}
        >
          <p className={`${styles.status} ${styles[getStatus(item)]}`}>{ item.statut }</p>
          { (item.statut === 'run')
            && (
              <p className={stylesRow.description}>
                {`Import: ${item.global}%`}
              </p>
            )}
        </div>
        <button
          type="button"
          className={styles.actions}
          onClick={() => setIsDetailsOpen((state) => !state)}
        >
          {
            (!isDetailsOpen)
              ? <Visibility />
              : <VisibilityOff />
          }
        </button>
      </div>
      {
        (isDetailsOpen) && (
          <AccountDetails
            close={() => setIsDetailsOpen(false)}
            importId={item.importId}
            actions={{ handleOpenImap, markAsTreated: actions.markAsTreated }}
          />
        )
      }
    </>
  );
}
