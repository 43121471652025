import { combineReducers } from 'redux';
import projectsReducer from './features/projects/reducer';
import sourcesReducer from './features/sources/reducer';
import contactsReducer from './features/contacts/reducer';
import uiReducer from './features/ui/reducer';
import detailsReducer from './features/contactDetails/reducer';
import blacklistReducer from './features/blacklist/reducer';
import historyReducer from './features/history/reducer';
import rolesReducer from './features/roles/reducer';
import toastsReducer from './features/toasts/reducer';
import progressBarReducer from './features/progressBar/reducer';

const rootReducer = combineReducers({
  projects: projectsReducer,
  sources: sourcesReducer,
  contacts: contactsReducer,
  ui: uiReducer,
  details: detailsReducer,
  blacklist: blacklistReducer,
  history: historyReducer,
  roles: rolesReducer,
  toasts: toastsReducer,
  progress: progressBarReducer,
});

export default rootReducer;
