import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ImportManagerSourceTableRowsAccount from './ImportManagerSourceTableRowsAccount';
import {
  setAccountBlacklist, deleteAccountByMail, importContactsByAccount,
  downloadAllContactFromAccount,
} from '../../../../../../services/store/features/sources/actions';

import { fetchContactsByProjectId } from '../../../../../../services/store/features/contacts/actions';
import { getLoginLink } from '../../../../../../services/store/features/roles/actions';

const mapStateToProps = (state) => ({
  query: state.sources.query,
  contacts: state.contacts.contacts.find((project) => project.id === state.sources.active) || { status: 'IDLE' },
  roles: state.roles.roles,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setAccountBlacklist,
    deleteAccountByMail,
    importContactsByAccount,
    downloadAllContactFromAccount,
    fetchContactsByProjectId,
    getLoginLink,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportManagerSourceTableRowsAccount);
