import React from 'react';
import Proptypes from 'prop-types';
import LoopIcon from '@material-ui/icons/Loop';

import styles from './ImportManagerSourceToolbarFilters.module.css';
import Filter from '../../../../../components/library/Input/Filter/Filter';
import { initialFilters } from '../../../../../services/store/features/contacts/reducer';
import SEGMENTS from '../../../../../utils/getSegmentLabel';

export default function ImportManagerSourceToolbarFilters({
  filters, accounts, setFilters, languages, segments, reset,
}) {
  const handleChange = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  return (
    <div className={styles.filters}>
      <button
        type="button"
        className={styles.clear}
        onClick={reset}
      >
        Effacer tous les filtres
        <LoopIcon className={styles.icon} />
      </button>
      <div className={styles.row}>
        <Filter
          label="Segment :"
          all="Tous"
          filterSelectedInput={filters.segment}
          onChange={(value) => handleChange('segment', value)}
          options={segments.map((i) => ({ id: i, value: i, label: `${i} - ${SEGMENTS[i]}` }))}
        />
        <Filter
          label="Proximité :"
          filterSelectedInput={filters.proximity}
          onChange={(value) => handleChange('proximity', value)}
          all="Toute"
          options={[
            { id: 0, value: 100, label: 'Proche' },
            { id: 1, value: 0, label: 'Éloigné' },
            { id: 2, value: 1, label: 'Inconnu' },
          ]}
        />
        <Filter
          label="Issuer"
          filterSelectedInput={filters.owner}
          onChange={(value) => handleChange('owner', value)}
          all="Tous"
          options={accounts.map((account) => ({ id: account, value: account, label: account }))}
        />
        <Filter
          label="Somme simulée"
          onChange={(value) => handleChange('amount', value)}
          filterSelectedInput={filters.amount}
          all="Tous"
          options={[
            { id: 0, value: 0, label: '0€' },
            { id: 1, value: 1000, label: '> 1000€' },
            { id: 2, value: 5000, label: '> 5000€' },
            { id: 3, value: 10000, label: '> 10000€' },
            { id: 4, value: 100000, label: '> 10000€' },
          ]}
        />
        <Filter
          label="Langues"
          onChange={(value) => handleChange('language', value)}
          filterSelectedInput={filters.language}
          all="Toutes"
          options={languages
            .map((language, key) => ({ id: key, value: language, label: language }))}
        />
        <Filter
          label="Échanges"
          onChange={(value) => handleChange('exchange', value)}
          filterSelectedInput={filters.exchange}
          all="Tous"
          options={[
            { id: 0, value: 0, label: '0' },
            { id: 1, value: 100, label: '> 100' },
            { id: 2, value: 500, label: '> 500' },
            { id: 3, value: 10000, label: '> 10000' },
          ]}
        />
        <Filter
          label="Dernier message reçu"
          onChange={(value) => handleChange('received', value)}
          filterSelectedInput={filters.received}
          all="Tous"
          options={[
            { id: 0, value: 0, label: 'Aucun message reçu' },
            { id: 1, value: 7, label: '< 1 semaine' },
            { id: 2, value: 31, label: '< 1 mois' },
            { id: 3, value: 186, label: '< 6 mois' },
            { id: 4, value: 187, label: '> 6 mois' },
          ]}
        />
        <Filter
          label="Dernier message envoyé"
          onChange={(value) => handleChange('send', value)}
          filterSelectedInput={filters.send}
          all="Tous"
          options={[
            { id: 0, value: 0, label: 'Aucun message envoyé' },
            { id: 1, value: 1, label: '< 1 semaine' },
            { id: 2, value: 2, label: '< 1 mois' },
            { id: 3, value: 3, label: '< 6 mois' },
            { id: 4, value: 4, label: '> 6 mois' },
          ]}
        />
        <Filter
          label="Abonnement e-mails"
          onChange={(value) => handleChange('subscribe', value)}
          filterSelectedInput={filters.subscribe}
          all="Tous"
          options={[
            { id: 0, value: '0', label: 'Abonné' },
            { id: 1, value: '1', label: 'Bounce' },
            { id: 2, value: '2', label: 'Désabonné' },
            { id: 3, value: '3', label: 'A investi' },
          ]}
        />
      </div>
    </div>
  );
}

ImportManagerSourceToolbarFilters.defaultProps = {
  filters: initialFilters,
  accounts: [],
  languages: [],
};

ImportManagerSourceToolbarFilters.propTypes = {
  filters: Proptypes.shape({
    segment: Proptypes.number,
    /* proximity: Proptypes.oneOf([
      Proptypes.string,
      Proptypes.number,
    ]), */
    owner: Proptypes.oneOfType([
      Proptypes.string,
      Proptypes.number,
    ]),
    amount: Proptypes.number,
    language: Proptypes.oneOfType([
      Proptypes.string,
      Proptypes.number,
    ]),
    exchange: Proptypes.number,
    received: Proptypes.number,
    send: Proptypes.number,
    subscribe: Proptypes.number,
  }),
  accounts: Proptypes.arrayOf(
    Proptypes.string,
  ),
  languages: Proptypes.arrayOf(
    Proptypes.string,
  ),
  setFilters: Proptypes.func.isRequired,
  reset: Proptypes.func.isRequired,
};
