import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchGlobaLBlacklist, blacklistGlobalByType, unblacklistGlobalByType } from '../../services/store/features/blacklist/actions';

import Blacklist from './Blacklist';

const mapStateToProps = (state) => ({
  blacklist: state.blacklist.global,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchGlobaLBlacklist,
    blacklistGlobalByType,
    unblacklistGlobalByType,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Blacklist);
