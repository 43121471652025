import React, { useEffect } from 'react';
import Proptypes from 'prop-types';

import { useParams } from 'react-router-dom';

import styles from './ImportManager.module.css';
import Source from './ImportManagerSource';
import Contact from './ImportManagerContact';
import Potential from './ImportManagerPotentials';
import Mail from './ImportManagerMail';
import ContactData from './ImportManagerContactData';
import Blacklist from './ImportManagerBlacklist';
import History from './ImportManagerHistory';
import * as statuses from '../../services/store/status';
import ErrorAnimation from '../../components/library/ErrorAnimation/ErrorAnimation';

export default function ImportManager({ source, tabToDisplay, actions }) {
  const { id } = useParams();

  useEffect(() => {
    actions.setTabToDisplay('source');
    actions.setActiveSource(id);
  }, [id]);

  const TAB_STATE = {
    source: (
      <Source
        id={Number.parseInt(id, 10)}
        openMail={() => actions.setTabToDisplay('email')}
      />),
    email: <Contact
      id={Number.parseInt(id, 10)}
      openContactDetails={() => actions.setTabToDisplay('contactsDetails')}
    />,
    potential: <Potential id={Number.parseInt(id, 10)} />,
    mailReader: <Mail id={Number.parseInt(id, 10)} />,
    contactsDetails: <ContactData id={Number.parseInt(id, 10)} />,
    blacklist: <Blacklist id={Number.parseInt(id, 10)} />,
    history: <History id={Number.parseInt(id, 10)} />,
  };

  if (source === statuses.FAILED) {
    return (
      <section className={styles.project}>
        <main className={styles.error}>
          <ErrorAnimation />
          <h1 className={styles.title}>
            {`Le projet ${id} est introuvable`}
          </h1>
          <p> Êtes-vous sur que l&apos;identifiant du projet est correcte ?</p>
        </main>
      </section>
    );
  }

  return (
    <section className={styles.project}>
      <header className={styles.header}>
        <p className={styles.subtitle}>{`PROJET ${id}`}</p>
        <h1 className={styles.title}>
          Gestion des imports
        </h1>
        <nav>
          <button
            className={`${styles.button} ${(tabToDisplay === 'source') ? styles.selected : ''}`}
            type="button"
            onClick={() => actions.setTabToDisplay('source')}
          >
            Source d&apos;import
          </button>
          <button
            type="button"
            className={`${styles.button} ${(tabToDisplay === 'email') ? styles.selected : ''}`}
            onClick={() => actions.setTabToDisplay('email')}
          >
            Emails importés
          </button>
          <button
            type="button"
            className={`${styles.button} ${(tabToDisplay === 'potential') ? styles.selected : ''}`}
            onClick={() => actions.setTabToDisplay('potential')}
          >
            Emails potentiels
          </button>
          <button
            type="button"
            className={`${styles.button} ${(tabToDisplay === 'mailReader') ? styles.selected : ''}`}
            onClick={() => actions.setTabToDisplay('mailReader')}
          >
            Échange d&apos;email
          </button>
          <button
            type="button"
            className={`${styles.button} ${(tabToDisplay === 'contactsDetails') ? styles.selected : ''}`}
            onClick={() => actions.setTabToDisplay('contactsDetails')}
          >
            Données contacts
          </button>
          <button
            type="button"
            className={`${styles.button} ${(tabToDisplay === 'blacklist') ? styles.selected : ''}`}
            onClick={() => actions.setTabToDisplay('blacklist')}
          >
            Blacklist
          </button>
          <button
            type="button"
            className={`${styles.button} ${(tabToDisplay === 'history') ? styles.selected : ''}`}
            onClick={() => actions.setTabToDisplay('history')}
          >
            Task history
          </button>
        </nav>
      </header>
      { TAB_STATE[tabToDisplay] }
    </section>
  );
}

ImportManager.propTypes = {
  actions: Proptypes.shape({
    fetchUser: Proptypes.func.isRequired,
  }).isRequired,
};
