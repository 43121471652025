export const SET_ACTIVE_SOURCE = 'SET_ACTIVE_SOURCE';
export const SET_QUERY = 'SET_QUERY';

export const FETCH_SOURCE_REQUEST = 'FETCH_SOURCE_REQUEST';
export const FETCH_SOURCE_SUCCEEDED = 'FETCH_SOURCE_SUCCEEDED';
export const FETCH_SOURCE_FAILED = 'FETCH_SOURCE_FAILED';

export const UPDATE_SOURCE_REQUEST = 'UPDATE_SOURCE_REQUEST';
export const UPDATE_SOURCE_SUCCEEDED = 'UPDATE_SOURCE_SUCCEEDED';
export const UPDATE_SOURCE_FAILED = 'UPDATE_SOURCE_FAILED';

export const DOWNLOAD_CONTACTS_REQUEST = 'DOWNLOAD_CONTACTS_REQUEST';
export const DOWNLOAD_CONTACTS_SUCCEEDED = 'DOWNLOAD_CONTACTS_SUCCEEDED';
export const DOWNLOAD_CONTACTS_FAILED = 'DOWNLOAD_CONTACTS_FAILED';

export const UPLOAD_CONTACTS_REQUEST = 'UPLOAD_CONTACTS_REQUEST';
export const UPLOAD_CONTACTS_SUCCEEDED = 'UPLOAD_CONTACTS_SUCCEEDED';
export const UPLOAD_CONTACTS_FAILED = 'UPLOAD_CONTACTS_FAILED';
export const UPLOAD_CONTACTS_CANCELED = 'UPLOAD_CONTACTS_CANCELED';
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';

export const RERUN_ACCOUNT_IMPORT_REQUEST = 'RERUN_ACCOUNT_IMPORT_REQUEST';
export const RERUN_ACCOUNT_IMPORT_SUCCEEDED = 'RERUN_ACCOUNT_IMPORT_SUCCEEDED';
export const RERUN_ACCOUNT_IMPORT_FAILED = 'RERUN_ACCOUNT_IMPORT_FAILED';

export const DELETE_ALL_SOURCES_REQUEST = 'DELETE_ALL_SOURCES_REQUEST';
export const DELETE_ALL_SOURCES_SUCCEEDED = 'DELETE_ALL_SOURCES_SUCCEEDED';
export const DELETE_ALL_SOURCES_FAILED = 'DELETE_ALL_SOURCES_FAILED';

export const OPEN_VIEW = 'OPEN_VIEW';
export const CLOSE_VIEW = 'CLOSE_VIEW';
export const SET_TIMER = 'SET_TIMER';

export const UPDATE_SOURCE_BLACKLIST_REQUEST = 'UPDATE_SOURCE_BLACKLIST_REQUEST';
export const UPDATE_SOURCE_BLACKLIST_SUCCEEDED = 'UPDATE_SOURCE_BLACKLIST_SUCCEEDED';
export const UPDATE_SOURCE_BLACKLIST_FAILED = 'UPDATE_SOURCE_BLACKLIST_FAILED';

export const SET_IMPORT_STATUS = 'SET_IMPORT_STATUS';
