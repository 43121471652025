import React, { useState } from 'react';
import Proptypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styles from './ImportManagerContactTableRowDetails.module.css';
import { USERS_MAIL_STATE } from '../../../../../../utils/getSegmentLabel';

const Detail = ({ title, value, children }) => (
  <div className={styles.detail}>
    <p className={styles.label}>
      {title}
    </p>
    {
      (value)
        ? (
          <p className={styles.value}>
            {value}
          </p>
        )
        : (children)
    }
  </div>
);

Detail.defaultProps = {
  value: undefined,
  children: undefined,
};

Detail.propTypes = {
  title: Proptypes.string.isRequired,
  value: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
  ]),
  children: Proptypes.element,
};

export default function ImportManagerContactTableRowDetails({ contact }) {
  const [isSourceOpen, setIsSourceOpen] = useState(false);
  const [isSegmentationOpen, setIsSegmentationOpen] = useState(false);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  return (
    <div className={styles.details}>
      <h2 className={styles.title}>
        Détails contact
      </h2>
      <div className={styles.row}>
        <Detail title="Type" value={contact.type || '0'} />
        <Detail title="Prédiction" value={contact.prediction || 'Aucune'} />
        <Detail
          title="Proximité"
          info="Proche / Éloigné / Seuil"
          value={`${contact.prediction[0]?.near || 0}/${contact.prediction[0]?.far || 0}/${contact.prediction[0]?.threshold || 0}`}
        />
        <Detail
          title="Abonnement emails"
        >
          {
            (contact.mailUnsubscribe === '0')
              ? (
                <div className={styles.subscribe}>
                  <CheckCircleIcon className={styles.icon} />
                  <p>
                    {USERS_MAIL_STATE[contact.mailUnsubscribe]}
                  </p>
                </div>
              )
              : (
                <div className={styles.unsubscribe}>
                  <CancelIcon icon={styles.icon} />
                  <p>
                    {USERS_MAIL_STATE[contact.mailUnsubscribe]}
                  </p>
                </div>
              )
          }
        </Detail>
        <Detail title="Téléphone" value={contact.phone || 'Non renseigné'} />
        <Detail title="Issuer" value={contact.issuer || 'Non renseigné'} />
      </div>
      <div>
        <div className={styles.divider}>
          <h3 className={styles.title}>
            Sources
          </h3>
          <button
            type="button"
            onClick={() => setIsSourceOpen((state) => !state)}
          >
            {
              (!isSourceOpen)
                ? <ExpandMoreIcon />
                : <ExpandLessIcon />
            }
          </button>
        </div>
        {
          (isSourceOpen)
          && (
            <div className={styles.source}>
              {
                (Array.isArray(contact.source))
                  ? contact.source.map((name) => (<p key={name}>{ name }</p>))
                  : <p>{ contact.source }</p>
              }
            </div>
          )
        }
      </div>
      <div>
        <div className={styles.divider}>
          <h3 className={styles.title}>
            Segmentations emails
          </h3>
          <button
            type="button"
            onClick={() => setIsSegmentationOpen((state) => !state)}
          >
            {
              (!isSegmentationOpen)
                ? <ExpandMoreIcon />
                : <ExpandLessIcon />
            }
          </button>
        </div>
        {
          (isSegmentationOpen)
          && (
            <div className={styles.row}>
              {
                Object.keys(contact.mailSegment).map((key) => (
                  <Detail key={key} title={key} value={contact.mailSegment[key]} />
                ))
              }
            </div>
          )
        }
      </div>
      <div>
        <div className={styles.divider}>
          <h3 className={styles.title}>
            Message
          </h3>
          <button
            type="button"
            onClick={() => setIsMessageOpen((state) => !state)}
          >
            {
              (!isMessageOpen)
                ? <ExpandMoreIcon />
                : <ExpandLessIcon />
            }
          </button>
        </div>
        {
          (isMessageOpen)
          && (
            <div className={styles.row}>
              <Detail
                title="Message envoyé"
                value={contact.receivedByIssuer}
              />
              <Detail
                title="Dernier Message envoyé"
                value={contact.lastDateReceivedByIssuer}
              />
              <Detail
                title="Premier Message envoyé"
                value={contact.firstDateReceivedByIssuer}
              />
              <Detail
                title="Message reçu"
                value={contact.sentByIssuer}
              />
              <Detail
                title="Dernier Message reçu"
                value={contact.lastDateSentByIssuer}
              />
              <Detail
                title="Premier Message reçu"
                value={contact.firstDateSentByIssuer}
              />
            </div>
          )
        }
      </div>
    </div>
  );
}

ImportManagerContactTableRowDetails.propTypes = ({
  contact: Proptypes.shape({
    emailGeneral: Proptypes.string,
    segment: Proptypes.string,
    /* proximity: Proptypes.string, */
    emailImportateur: Proptypes.string,
    amout: Proptypes.number,
    language: Proptypes.string,
  }).isRequired,
});
