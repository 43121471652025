import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import GetAppIcon from '@material-ui/icons/GetApp';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

import styles from './ImportManagerSourceUpload.module.css';
import useOutsideClickAlert from '../../../../components/library/hook/clickOutside';

export default function ImportManagerSourceUpload({
  accounts, percentCompleted, close, submit, reset, projectId,
}) {
  const [selectedAccount, setSelectedAccount] = useState(accounts[0].email);
  const [files, setFiles] = useState(null);
  const [hasSubmitted, setHassubmitted] = useState(false);
  const [isFilesExtensionValid, setIsFileExtensionValid] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const inputRef = useRef();
  const popupRef = useRef();

  const handleSubmit = () => {
    if (files) {
      console.log(selectedAccount);
      submit(files, selectedAccount, projectId);
      setHassubmitted(true);
    }
  };

  const addFiles = (file) => {
    const acceptedFiles = ['csv', 'pst'];
    const filesExtension = file.name.split('.').pop();
    if (!acceptedFiles.includes(filesExtension)) {
      setIsFileExtensionValid(false);
    } else {
      setIsFileExtensionValid(true);
      setFiles(file);
    }
  };

  const handleFiles = (e) => {
    const filesInput = e.target.files[0];
    addFiles(filesInput);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsHovered(true);
  };

  const handleDragLeave = () => {
    setIsHovered(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const { files: fileInput } = e.dataTransfer;
    addFiles(fileInput[0]);
    setIsHovered(false);
  };

  const handleCancel = () => {
    setFiles(null);
    if (reset) reset();
    close();
  };

  useOutsideClickAlert(popupRef, close);

  if (hasSubmitted && (percentCompleted === 100)) {
    window.setTimeout(() => {
      setIsVisible(false);
    }, 3000);
    return (
      <section className={`${styles.fileUpload} ${(isVisible) ? '' : styles.fadeOut}`}>
        <InsertDriveFileIcon className={styles.icon} />
        <div className={styles.progress}>
          <div className={styles.row}>
            <p>
              {files.name}
            </p>
            <p className={styles.bold}>
              {Math.ceil((files.size / 1024) / 1024)}
              MB
            </p>
            <button
              type="button"
              className={styles.cancel}
              onClick={handleCancel}
            >
              <CheckIcon className={styles.successIcon} />
            </button>
          </div>
          <div className={styles.barBackground}>
            <div
              className={`${styles.bar} ${styles.success}`}
              style={{ width: '100%' }}
            />
          </div>
        </div>
      </section>
    );
  }

  if (hasSubmitted) {
    return (
      <section className={styles.fileUpload}>
        <InsertDriveFileIcon className={styles.icon} />
        <div className={styles.progress}>
          <div className={styles.row}>
            <p>
              {files.name}
            </p>
            <p className={styles.bold}>
              {Math.ceil((files.size / 1024) / 1024)}
              MB
            </p>
            <button
              type="button"
              className={styles.cancel}
              onClick={handleCancel}
            >
              Annuler
            </button>
          </div>
          <div className={styles.barBackground}>
            <div
              className={styles.bar}
              style={{ width: `${percentCompleted}%` }}
            />
          </div>
        </div>
      </section>
    );
  }

  return (
    <div className={styles.container}>
      <section
        className={styles.upload}
        ref={popupRef}
      >
        <h1>
          Importer des contacts
        </h1>
        {
          (!files)
            ? (
              <div>
                <div
                  className={`${styles.dropzone} ${(isHovered) ? styles.hovered : ''}`}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <div className={styles.iconContainer}>
                    <GetAppIcon className={styles.icon} />
                  </div>
                  <div className={styles.dropzoneText}>
                    <p>
                      Déposer-glisser ou
                    </p>
                    <button
                      type="button"
                      className={styles.dropzoneButton}
                      onClick={() => {
                        inputRef.current.click();
                      }}
                    >
                      importer
                    </button>
                    <p>
                      le fichier
                    </p>
                  </div>
                </div>
                {
                (isFilesExtensionValid)
                  ? (
                    <p className={styles.label}>
                      Fichier accepté: .csv, .pst
                    </p>
                  )
                  : (
                    <p className={styles.error}>
                      Le fichier n&apos;est pas valide.
                      Vérifier qu&apos; il s&apos;agisse bien d&apos;un fichier csv ou pst.
                    </p>
                  )
              }
                <input
                  type="file"
                  ref={inputRef}
                  onChange={handleFiles}
                  accept=".csv, .pst"
                  hidden
                />
              </div>
            )
            : (
              <div className={styles.file}>
                <div className={styles.rowFile}>
                  <InsertDriveFileIcon
                    className={styles.fileIcon}
                  />
                  <p className={styles.name}>
                    { files.name }
                  </p>
                  <p className={styles.bold}>
                    { Math.ceil(files.size / 1024 / 1024) }
                    MB
                  </p>
                </div>
                <button
                  type="button"
                  className={styles.clear}
                  onClick={() => setFiles(null)}
                >
                  <ClearIcon />
                </button>
              </div>
            )
        }
        <p className={styles.selectLabel}>
          Associé à un compte
        </p>
        {
          (accounts)
          && (
            <select
              className={styles.select}
              value={selectedAccount}
              onChange={(e) => setSelectedAccount(e.target.value)}
            >
              {
                accounts
                  .map((account) => account.email)
                  .map((account) => (
                    <option
                      key={account}
                      value={account}
                    >
                      { account }
                    </option>
                  ))
              }
            </select>
          )
        }
        <button
          type="button"
          className={styles.button}
          onClick={handleSubmit}
          disabled={!files}
        >
          Importer les contact
        </button>
      </section>
    </div>
  );
}

ImportManagerSourceUpload.defaultProps = {
  accounts: [],
  percentCompleted: 0,
};

ImportManagerSourceUpload.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({
    account: PropTypes.string,
    blacklist: PropTypes.number,
    mails: PropTypes.arrayOf(
      PropTypes.shape({
        mail: PropTypes.string,
        contacts: PropTypes.number,
        type: PropTypes.string,
        status: PropTypes.string,
        progress: PropTypes.number,
      }),
    ),
  })),
  percentCompleted: PropTypes.number,
  close: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};
