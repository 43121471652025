import * as types from './types';

export function setTabToDisplay(tab) {
  return async (dispatch) => {
    dispatch({ type: types.SET_TAB_TO_DISPLAY, payload: tab });
  };
}

export function printUiReducer() {
  console.log('ui reducer');
}
