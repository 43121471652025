import React from 'react';
import Proptypes from 'prop-types';

import ImportManagerSourceTableRowsAccount from './ImportManagerSourceTableRowsAccount';
import styles from '../ImportManagerSourceTable.module.css';

export default function ImportManagerSourceTableRows({ id, source, handleDelete }) {
  return (
    <div className={styles.tableBody}>
      {
        (source.accounts && source.accounts.length > 0)
        && source.accounts.map((account) => (
          <ImportManagerSourceTableRowsAccount
            key={account.email}
            account={account}
            handleDelete={handleDelete}
            id={id}
          />
        ))
      }
    </div>
  );
}

ImportManagerSourceTableRows.propTypes = {
  source: Proptypes.shape({}).isRequired,
};
