import * as types from './types';

const initialState = {
  list: [],
};

export default function progressBarReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.ADD_PROGRESS_BAR:
      return { ...state, list: [...state.list, payload] };
    case types.REMOVE_PROGRESS_BAR:
      return { ...state, list: state.list.filter((item) => item.id !== payload) };
    case types.SET_PROGRESS_VALUE:
      return {
        ...state,
        list: state.list.map((progressBar) => (
          (progressBar.id === payload.id)
            ? { ...progressBar, percent: payload.percent }
            : progressBar
        )),
      };
    default:
      return state;
  }
}
