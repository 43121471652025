import React, { useState, useEffect, useRef } from 'react';
import Proptypes from 'prop-types';
import DoneIcon from '@material-ui/icons/Done';
import MoreVertIcon from '@material-ui/icons/MoreVert';
//  import BlockIcon from '@material-ui/icons/Block';
//  import PersonAddIcon from '@material-ui/icons/PersonAdd';
import InfoIcon from '@material-ui/icons/Info';
import { useParams } from 'react-router-dom';

import styles from './ImportManagerContactTableRow.module.css';
import ImportManagerContactTableRowDetails from './ImportManagerContactTableRowDetails';
import useOutsideClickAlert from '../../../../../components/library/hook/clickOutside';
import Dropdown from '../../../../../components/Dropdown';

export default function ImportManagerContactTableRow({
  contact, accounts, languages, submit,
  openContactDetails, style, actions, selectContactById,
}) {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isOptionOpen, setIsOptionOpen] = useState(false);

  const [current, setCurrent] = useState(contact || {});

  const { id } = useParams();

  useEffect(() => {
    setCurrent(contact);
  }, [contact]);

  const optionsDropdown = useRef(null);
  useOutsideClickAlert(optionsDropdown, () => setIsOptionOpen(false));

  const handleSubmit = () => {
    setIsEditMode(false);
    submit(id, current);
  };

  const selectContact = () => {
    const newContact = {
      ...current,
      isSelected: !current.isSelected,
    };
    selectContactById(id, newContact);
    setCurrent(newContact);
  };

  const handleChange = (e) => {
    setCurrent((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const getOptionButtons = () => [
    /*  {
      label: (contact.segment !== '7') ? 'Blacklist' : 'Unblacklist',
      styles: styles.optionButton,
      action: (contact.segment !== '7')
        ? () => {
          actions.blacklist(id, [contact.emailGeneral]);
          setIsOptionOpen(false);
        }
        : () => {
          actions.unblacklist(id, [contact.emailGeneral]);
          setIsOptionOpen(false);
        },
      icon: (contact.segment !== '7')
        ? <BlockIcon className={styles.icon} />
        : <PersonAddIcon className={styles.icon} />,
    },  */
    {
      label: 'Afficher les données',
      styles: styles.optionButton,
      action: () => {
        openContactDetails();
        actions.setSelectedContactsByProject(contact.emailGeneral, id);
      },
      icon: <InfoIcon className={styles.icon} />,
    },
  ];

  return (
    <div className={style}>
      <div className={`${styles.grid} ${styles.relative}`}>
        <div>
          <input
            type="checkbox"
            className={styles.checkbox}
            checked={current.isSelected}
            onChange={selectContact}
          />
        </div>
        <button
          type="button"
          className={styles.mail}
          onClick={() => setIsDetailsOpen((state) => !state)}
        >
          { current.emailGeneral }
        </button>
        {
          (isEditMode)
            ? (
              <select
                name="segment"
                onChange={handleChange}
                value={current.segment}
              >
                {
                  [...Array(10).keys()]
                    .map((option) => (<option value={option} key={option}>{option}</option>))
                }
              </select>
            )
            : (
              <button
                type="button"
                onDoubleClick={() => setIsEditMode((state) => !state)}
                className={styles.editable}
              >
                { current.segment }
              </button>
            )
        }
        <p>
          { contact.proximity.threshold }
        </p>
        {
          (isEditMode)
            ? (
              <select
                name="emailImportateur"
                onChange={handleChange}
                value={current.emailImportateur}
              >
                {
                accounts.map((account) => (
                  <option key={account} value={account}>
                    { account }
                  </option>
                ))
              }
              </select>
            )
            : (
              <button
                type="button"
                className={styles.editable}
                onDoubleClick={() => setIsEditMode((state) => !state)}
              >
                { current.emailImportateur }
              </button>
            )
        }
        <p>
          { contact.amount }
        </p>
        {
          (isEditMode)
            ? (
              <select
                name="language"
                onChange={handleChange}
                value={current.language}
              >
                {
            languages.map((language) => (
              <option key={language} value={language}>
                { language }
              </option>
            ))
          }
              </select>
            )
            : (
              <button
                type="button"
                onDoubleClick={() => setIsEditMode((state) => !state)}
                className={styles.editable}
              >
                { current.language }
              </button>
            )
        }
        {
          (!isEditMode)
            ? (
              <div ref={optionsDropdown}>
                <button
                  type="button"
                  className={styles.button}
                  onClick={() => setIsOptionOpen((state) => !state)}
                >
                  <MoreVertIcon />
                </button>
                {
                  (isOptionOpen) && <Dropdown buttons={getOptionButtons()} />
                }
              </div>
            )
            : (
              <button
                type="button"
                className={styles.button}
                onClick={handleSubmit}
              >
                <DoneIcon />
              </button>
            )
        }
      </div>
      {
        (isDetailsOpen)
        && (
          <ImportManagerContactTableRowDetails contact={contact} />
        )
      }
    </div>
  );
}

ImportManagerContactTableRow.propTypes = {
  contact: Proptypes.shape({
    emailGeneral: Proptypes.string,
    segment: Proptypes.string,
    proximity: Proptypes.shape({
      threshold: Proptypes.number.isRequired,
    }),
    emailImportateur: Proptypes.string,
    amount: Proptypes.number,
    language: Proptypes.string,
  }).isRequired,
};
