import React from 'react';

import styles from './ImportManagerContactTable.module.css';

export default function ImportManagerContactTableTitle({
  isAllSelected = false, selectAll = null, setSort,
}) {
  return (
    <div className={`${styles.title} ${styles.grid}`}>
      <input
        type="checkbox"
        checked={isAllSelected}
        onChange={selectAll}
      />
      <p>
        Email
      </p>
      <button
        type="button"
        className={styles.sortable}
        onClick={() => setSort('segment')}
      >
        Segment
      </button>
      <button
        type="button"
        className={styles.sortable}
        onClick={() => setSort('proximity')}
      >
        Proximité
      </button>
      <p>
        Importateur
      </p>
      <button
        type="button"
        className={styles.sortable}
        onClick={() => setSort('amount')}
      >
        Somme simulée
      </button>
      <p>
        Langues
      </p>
      <div />
    </div>
  );
}
