import React, { useState } from 'react';

import MailReader from './MailReader';
import MailFinder from './MailFinder';

import styles from './ImportManagerMail.module.css';

export default function ImportManagerMail({ id }) {
  const [exchangeId, setExchangeId] = useState(null);
  return (
    <main className={styles.mail}>
      <MailFinder
        id={id}
        setExchangeId={setExchangeId}
      />
      <MailReader exchangeId={exchangeId} />
    </main>
  );
}
