import React, { useState } from 'react';

import styles from '../ContactDetails.module.css';
import stylesLog from './ContactDetailsLog.module.css';
import LOGS from '../../../contants/log';

export default function ContactDetailsLog({ action, index }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={stylesLog.container}>
      <button
        type="button"
        onClick={() => setIsOpen((state) => !state)}
        className={stylesLog.action}
      >
        <p>
          {`Action n°${index + 1} `}
        </p>
        <p className={stylesLog.type}>
          { `(Date : ${new Date(Number.parseFloat(action.date) * 1000).toLocaleString('fr-FR')}, Type : ${action.event} - ${LOGS[action.event]?.description})` }
        </p>
      </button>
      {
        (isOpen)
        && (
          <div className={`${stylesLog.details} ${styles.grid}`}>
            {
              Object.keys(action).map((detail) => {
                if (detail === 'date') {
                  return (
                    <div
                      className={styles.segmentation}
                      key={detail.date}
                    >
                      <p>
                        { detail }
                      </p>
                      <b>
                        {new Date(Number.parseFloat(action[detail]) * 1000).toLocaleString('fr-FR')}
                      </b>
                    </div>
                  );
                }
                return (
                  <div
                    className={styles.segmentation}
                    key={detail.date}
                  >
                    <p>
                      { detail }
                    </p>
                    <b>
                      {action[detail]}
                    </b>
                  </div>
                );
              })
            }
          </div>
        )
      }
    </div>
  );
}
