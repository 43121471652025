import * as types from './types';

const initState = {
  status: 'IDLE',
  roles: [],
  login: {
    status: 'IDLE',
    url: null,
  },
};

export default function rolesReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_ROLE_REQUEST:
      return { ...state, status: 'LOADING' };
    case types.FETCH_ROLE_SUCCEEDED:
      return { ...state, status: 'SUCCEEDED', roles: payload };
    case types.FETCH_ROLE_FAILED:
      return { ...state, status: 'FAILED' };
    case types.GET_LOGIN_LINK_REQUEST:
      return { ...state, login: { status: 'LOADING' } };
    case types.GET_LOGIN_LINK_SUCCEEDED:
      return { ...state, login: { status: 'SUCCEEDED', url: payload } };
    case types.GET_LOGIN_LINK_FAILED:
      return { ...state, login: { status: 'FAILED', url: null, error: payload } };
    case types.RESET_LOGIN_LINK:
      return { ...state, login: { status: 'IDLE', url: null } };
    default:
      return state;
  }
}
