import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchContactsByProjectId } from '../../../../services/store/features/contacts/actions';

import MailFinder from './MailFinder';

const mapStateToProps = (state) => ({
  contacts: state.contacts.contacts.find((project) => project.id === state.sources.active),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchContactsByProjectId,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MailFinder);
