import React from 'react';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import RouteConfig from './services/routes/routes';

import ImportManagerSourceUpload from './views/ImportManager/ImportManagerSource/ImportManagerSourceUpload';
import {
  closeUploadModal, uploadContactsFromFile, resetUploadModal,
} from './services/store/features/sources/actions';

/* eslint-disable import/no-unresolved */
import 'library/style/normalize.css';
import 'library/style/reset.css';
import 'library/style/typography.css';
import 'library/style/animations.css';
import Toasts from './components/Toasts';
import UploadProgressionBar from './components/UploadProgressionBar';
/* eslint-enable import/no-unresolved */

function App({
  isModalOpen, source, percentCompleted, actions,
}) {
  return (
    <div className="App">
      <RouteConfig />
      <Toasts />
      { (isModalOpen) && (
        <ImportManagerSourceUpload
          accounts={source.accounts}
          percentCompleted={percentCompleted}
          close={actions.closeUploadModal}
          submit={actions.uploadContactsFromFile}
          reset={actions.resetUploadModal}
          projectId={window.location.pathname.split('/')[1]}
        />
      )}
      <UploadProgressionBar />
    </div>
  );
}

App.defaultProps = {
  source: undefined,
  percentCompleted: undefined,
  actions: undefined,
};

App.propTypes = {
  isModalOpen: Proptypes.bool.isRequired,
  source: Proptypes.shape({}),
  percentCompleted: Proptypes.number,
  actions: Proptypes.shape({}),
};

const mapStateToProps = (state) => {
  const active = state.sources?.active;
  const activeSource = state.sources.sources?.find((source) => source.id === active);
  return ({
    source: activeSource,
    isModalOpen: state.sources.isUploading,
    percentCompleted: state.sources.percentCompleted,
  });
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    closeUploadModal,
    uploadContactsFromFile,
    resetUploadModal,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
