import React, { useState, useRef } from 'react';
import Proptypes from 'prop-types';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useParams } from 'react-router-dom';

import styles from './ImportManagerContactToolbar.module.css';
import ImportManagerSourceToolbarFilters from './ImportManagerSourceToolbarFilters';
import Searchbar from '../../../../components/Searchbar/Searchbar';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import Button from '../../../../components/library/Button/Button';
import useOutsideClickAlert from '../../../../components/library/hook/clickOutside';
import { initialFilters } from '../../../../services/store/features/contacts/reducer';

export default function ImportManagerContactToolbar({
  query, filters, accounts, languages, segments,
  shouldBlacklist, selectedContacts, actions,
}) {
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isBlacklistPopUpOpen, setIsBlacklistPopUpOpen] = useState(false);

  const { id } = useParams();

  const dropdownRef = useRef();
  useOutsideClickAlert(dropdownRef, () => setIsDropdownOpen(false));

  const modalRef = useRef();
  useOutsideClickAlert(modalRef, () => setIsBlacklistPopUpOpen(false));

  const handleDownloadAll = () => {
    actions.downloadAllContactFromSource();
    setIsDropdownOpen(false);
  };

  const handleFilteredDownload = () => {
    actions.downloadFilteredContacts();
    setIsDropdownOpen(false);
  };

  const handleBlacklist = () => {
    if (isBlacklistPopUpOpen) {
      if (shouldBlacklist) {
        actions.blacklistContactsSelection(id);
      } else {
        actions.unblacklistContactsSelection(id);
      }
      setIsBlacklistPopUpOpen(false);
    } else {
      setIsBlacklistPopUpOpen(true);
    }
  };

  const getButtons = () => [
    {
      label: 'Télécharger tous les contacts',
      styles: styles.download,
      action: handleDownloadAll,
    },
    {
      label: 'Télécharger la liste de contacts actuels',
      styles: styles.download,
      action: handleFilteredDownload,
    },
  ];

  return (
    <div className={styles.toolbarContainer}>
      {
        (isBlacklistPopUpOpen)
        && (
          <div className={`fadeIn ${styles.overlay}`}>
            <section
              className={styles.modal}
              ref={modalRef}
            >
              <h2>
                {`Êtes - vous sûr de vouloir ${(shouldBlacklist) ? 'blacklister' : 'déblacklister'} votre selection actuelle ?`}
              </h2>
              <p>
                {`Vous allez ${(shouldBlacklist) ? 'blacklister' : 'déblacklister'} ${selectedContacts} contact(s).`}
              </p>
              <div className={styles.row}>
                <Button
                  label="Annuler"
                  variant="ghost"
                  onButtonClick={() => setIsBlacklistPopUpOpen(false)}
                />
                <Button
                  label="Je confirme"
                  onButtonClick={handleBlacklist}
                />
              </div>
            </section>
          </div>
        )
      }
      <div className={styles.toolbar}>
        <div className={styles.searchbar}>
          <Searchbar
            placeholder="Rechercher un contact"
            query={query}
            setQuery={actions.setQuery}
          />
          <button
            type="button"
            className={styles.advanced}
            onClick={() => setIsAdvancedSearchOpen((state) => !state)}
          >
            Recherche avancé
            {
              (!isAdvancedSearchOpen)
                ? <ExpandMoreIcon />
                : <ExpandLessIcon />
            }
          </button>
        </div>
        <div className={styles.rowButton}>
          <Button
            label={(shouldBlacklist) ? 'Blacklister la selection' : 'Déblacklister la sélection'}
            onButtonClick={handleBlacklist}
            disabled={selectedContacts === 0}
          />
          <div
            className={styles.dropdown}
            ref={dropdownRef}
          >
            <button
              type="button"
              className={styles.button}
              onClick={() => setIsDropdownOpen((state) => !state)}
            >
              Télécharger
              {
              (!isDropdownOpen)
                ? <ExpandMoreIcon className={styles.icon} />
                : <ExpandLessIcon className={styles.icon} />
            }
            </button>
            {(isDropdownOpen) && <Dropdown buttons={getButtons()} />}
          </div>
        </div>
      </div>
      {
        (isAdvancedSearchOpen)
        && (
          <ImportManagerSourceToolbarFilters
            filters={filters}
            setFilters={actions.setFilters}
            accounts={accounts}
            languages={languages}
            segments={segments}
            reset={actions.resetFilters}
          />
        )
      }
    </div>
  );
}

ImportManagerContactToolbar.defaultProps = {
  query: '',
  filters: initialFilters,
  accounts: [],
  languages: [],
};

ImportManagerContactToolbar.propTypes = {
  query: Proptypes.string,
  filters: Proptypes.shape({
    segment: Proptypes.number,
    /* proximity: Proptypes.oneOf([
      Proptypes.string,
      Proptypes.number,
    ]), */
    owner: Proptypes.oneOfType([
      Proptypes.string,
      Proptypes.number,
    ]),
    amount: Proptypes.number,
    language: Proptypes.oneOfType([
      Proptypes.string,
      Proptypes.number,
    ]),
    exchange: Proptypes.number,
    received: Proptypes.number,
    send: Proptypes.number,
    subscribe: Proptypes.number,
  }),
  accounts: Proptypes.arrayOf(
    Proptypes.string,
  ),
  languages: Proptypes.arrayOf(
    Proptypes.string,
  ),
  actions: Proptypes.shape({
    downloadAllContactFromSource: Proptypes.func.isRequired,
    downloadFilteredContacts: Proptypes.func.isRequired,
    recalculateContact: Proptypes.func.isRequired,
  }).isRequired,
};
