import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Navigation from './Navigation';

import { fetchActiveProject, setActiveProject, search } from '../../services/store/features/projects/actions';

const mapStateToProps = (state) => ({
  shouldFetch: state.roles.status === 'SUCCEEDED',
  status: state.projects.status,
  projects: state.projects.list,
  activeProjects: state.projects.activeProjects,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchActiveProject,
    search,
    setActiveProject,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
