import React, { useState } from 'react';
import { Event } from '@material-ui/icons';

import styles from './HistorySearchDate.module.css';
import Button from '../../../../components/library/Button/Button';
import InputText from '../../../../components/library/Input/InputText/InputText';

const dateValidation = new RegExp(/\d{2}-\d{2}-\d{4} \d{2}:\d{2}/);

export default function HistorySearchDate({
  selected, onChange, date, setDate,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [begin, setBegin] = useState(date?.begin && dateValidation.test(date.begin) ? date.begin : '');
  const [end, setEnd] = useState(date?.end && dateValidation.test(date.end) ? date.end : '');
  const [errors, setErrors] = useState(null);

  const handleSubmit = () => {
    const newErrors = {};
    if (!dateValidation.test(begin)) {
      newErrors.begin = 'Format requis: MM-DD-AAAA HH:MM';
    } else if (new Date(begin).toString() === 'Invalid Date') {
      newErrors.begin = 'Date Invalide. Format requis: MM-DD-AAAA HH:MM';
    }
    if (!dateValidation.test(end)) {
      newErrors.end = 'Format requis: MM-DD-AAAA HH:MM';
    } else if (new Date(end).toString() === 'Invalid Date') {
      newErrors.end = 'Date Invalide. Format requis: MM-DD-AAAA HH:MM';
    } else if (new Date(end) < new Date(begin)) {
      newErrors.end = 'Date de fin doit être ultérieur à la date du début';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors(null);
      setDate({ begin, end });
      setIsOpen(false);
    }
  };

  const DATE_LABEL = {
    lastHour: 'Dernière heure',
    last24Hours: 'Dernière 24 heures',
    yesterday: 'Hier',
    custom: `${new Date(begin).toLocaleDateString('fr')} - ${new Date(end).toLocaleDateString('fr')}`,
  };

  return (
    <div className={styles.searchDate}>
      <Button
        className={styles.button}
        onButtonClick={() => setIsOpen((curr) => !curr)}
        type={((selected === 'custom') && (!date.begin || !date.end)) ? 'primary' : 'secondary'}
        variant="outline"
      >
        <Event className={styles.icon} />
        <span>
          { DATE_LABEL[selected] }
        </span>
      </Button>
      <div
        hidden={!isOpen}
        className={`fadeIn ${styles.dropdown}`}
      >
        <label htmlFor="lastHour">
          <input
            type="radio"
            id="lastHour"
            name="dateRange"
            checked={selected === 'lastHour'}
            onChange={onChange}
          />
          Dernière heure
        </label>
        <label htmlFor="last24Hours">
          <input
            type="radio"
            id="last24Hours"
            name="dateRange"
            checked={selected === 'last24Hours'}
            onChange={onChange}
          />
          Dernière 24 heures
        </label>
        <label htmlFor="yesterday">
          <input
            type="radio"
            id="yesterday"
            name="dateRange"
            value={selected}
            checked={selected === 'yesterday'}
            onChange={onChange}
          />
          Hier
        </label>
        <label htmlFor="custom">
          <input
            type="radio"
            id="custom"
            name="dateRange"
            value={selected}
            checked={selected === 'custom'}
            onChange={onChange}
          />
          Plage personnalisée
        </label>
        {
          (selected === 'custom')
          && (
            <>
              <InputText
                label="Date de début"
                iconFirst={<Event />}
                placeholder="MM-DD-AAAA HH:MM"
                valueInput={begin}
                onTextChange={(e) => setBegin(e.target.value)}
                state={errors?.begin && 'ERROR'}
                hint={errors?.begin}
              />
              <InputText
                label="Date de fin"
                iconFirst={<Event />}
                placeholder="MM-DD-AAAA HH:MM"
                className={styles.customDate}
                valueInput={end}
                onTextChange={(e) => setEnd(e.target.value)}
                state={errors?.end && 'ERROR'}
                hint={errors?.end}
              />
              <Button
                label="Appliquer"
                className={styles.apply}
                disabled={begin === '' || end === ''}
                onButtonClick={handleSubmit}
              />
            </>
          )
        }
      </div>
    </div>
  );
}
