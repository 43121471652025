import React, { useState } from 'react';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import styles from './UploadProgressionBar.module.css';

const ProgressBar = ({
  id, name, size, unit, percent, actions,
}) => {
  const [hide, setHide] = useState(false);
  const handleCancel = () => (hide) && actions.removeProgressBar(id);
  const handleHide = () => setHide(true);
  return (
    <section
      className={`${hide ? 'slideOutRight' : 'slideInRight'} ${styles.fileUpload}`}
      onAnimationEnd={handleCancel}
    >
      <InsertDriveFileIcon className={styles.icon} />
      <div className={styles.progress}>
        <div className={styles.row}>
          <p>
            {name}
          </p>
          <p className={styles.bold}>
            {size}
            {unit}
          </p>
          <button
            type="button"
            className={styles.cancel}
            onClick={handleHide}
          >
            Fermer
          </button>
        </div>
        <div className={styles.barBackground}>
          <div
            className={styles.bar}
            style={{ width: `${percent}%` }}
          />
        </div>
      </div>
    </section>
  );
};

function UploadProgressionBar({
  list, actions,
}) {
  return (
    <div className={styles.container}>
      {
        list.map((item) => (
          <ProgressBar
            key={item.id}
            id={item.id}
            name={item.name}
            size={item.size}
            unit={item.unit}
            percent={item.percent}
            actions={actions}
          />
        ))
      }
    </div>
  );
}

export default UploadProgressionBar;
