import axios from '../axiosClient';

export const getSourcesById = (id) => axios.get(`/accounts/${id}`);
export const getAllSources = () => axios.get('/accounts');
export const getAllContactsCsv = () => axios.post('/csv', {
  config: {
    responseType: 'blob',
  },
});

export const getContactsCsvByAccount = (account) => axios.post('/csvByAccount', {
  data: { account },
  config: {
    responseType: 'blob',
  },
});

export const getContactsCsvByMail = (mail) => axios.post('/csvByMail', {
  data: { mail },
  config: {
    responseType: 'blob',
  },
});

export const uploadContactsFile = (data, config, projectId, email) => axios.post(`/imports/accounts/${projectId}/${email}`, data, config);
export const deleteAllAccounts = (id) => axios.delete(`/sources/${id}`);
// export const deleteAccountFromSource = (id, account) => axios.delete(`/sources/${id}`, account);
export const deleteAccountFromSource = (id, source) => axios.put(`/sources/${id}`, source);
export const deleteAllAccountsAndContact = (id, source) => axios.put(`/sourcesAndContact/${id}`, source);

export const deleteMailSourceFromAccount = (id, source) => axios.put(`/sources/${id}`, source);
export const deleteAccountAndContactsFromSource = (id, mail) => axios.put(`/sourcesAndContact/${id}`, mail);

/*
 * export const updateContactsBlacklist
 * = (mail, blacklist) => axios.put(`/account/${mail}`, blacklist);
 */

export const updateAccountBlacklist = (id, source, value) => axios.put(`/accounts/${id}/${source}`, { blacklistPercent: value });
export const updateSourceOwner = (id, source) => axios.put(`/sources/${id}`, source);

export const getDetailsByImportId = (importId) => axios.get(`/imports/details/${importId}`);

export const flushSource = (importId) => axios.post(`/imports/flush/${importId}`);
