import React, { useState, useEffect } from 'react';
import { getGlobalImport } from '../../../services/clients/lib/import';
import Loading from '../../../components/library/Loading';

export default function GlobalManager({ children }) {
  const [status, setStatus] = useState('IDLE');
  const [importGlobal, setImportGlobal] = useState([]);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    const fetchGlobalImport = async () => {
      setStatus('LOADING');
      try {
        const { data } = await getGlobalImport();
        if (data.status === 'OK') {
          setStatus('SUCCESS');
          setImportGlobal(data.data);
        } else {
          setStatus('ERROR');
          setImportGlobal(undefined);
          setError(data.msg);
        }
      } catch (err) {
        setStatus('ERROR');
        setError(err);
      }
    };
    fetchGlobalImport();
  }, []);

  const skipImport = (importId) => setImportGlobal(importGlobal.map((item) => (
    (item.importId === importId)
      ? { ...item, statut: 'skip' }
      : item
  )));

  const setImportStatus = (importId, statut) => setImportGlobal(importGlobal.map((item) => (
    (item.importId === importId)
      ? { ...item, statut }
      : item
  )));

  const markAsTreated = (importId) => setImportGlobal((state) => state
    .filter((item) => item.importId !== importId));

  if (status === 'LOADING') return <Loading />;

  if (status === 'ERROR') {
    return (
      <>
        <h1>Something went wrong</h1>
        <details>
          { error }
        </details>
      </>
    );
  }
  return (children(importGlobal, { skipImport, setImportStatus, markAsTreated }));
}
