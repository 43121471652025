import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ImportManagerBlacklist from './ImportManagerBlacklist';
import {
  fetchBlacklistByProject,
  fetchGlobaLBlacklist,
  blacklistExpressionByType,
  unblacklistExpressionByType,
} from '../../../services/store/features/blacklist/actions';

const mapStateToProps = (state) => ({
  blacklist: state.blacklist.project,
  globalBlacklist: state.blacklist.global,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchBlacklistByProject,
    fetchGlobaLBlacklist,
    blacklistExpressionByType,
    unblacklistExpressionByType,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportManagerBlacklist);
