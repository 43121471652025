import React, { useEffect } from 'react';

import BlacklistType from '../../../components/BlacklistType';
import Loading from '../../../components/library/Loading';
import * as statuses from '../../../services/store/status';
import styles from './ImportManagerBlacklist.module.css';

export default function ImportManagerBlacklist({
  id, blacklist, globalBlacklist, actions,
}) {
  useEffect(() => {
    if (blacklist.status === statuses.IDLE || blacklist.projectId !== id) {
      actions.fetchBlacklistByProject(id);
    }
    if (globalBlacklist.status === statuses.IDLE) {
      actions.fetchGlobaLBlacklist();
    }
  }, []);

  const blacklistActions = {
    blacklist: (type, expressions) => actions
      .blacklistExpressionByType(type, id, expressions),
    unblacklist: (type, expressions) => actions
      .unblacklistExpressionByType(type, id, expressions),
  };

  if (blacklist.status === statuses.SUCCEEDED) {
    return (
      <main className={styles.blacklist}>
        <BlacklistType
          type="DOMAIN"
          blacklist={blacklist}
          global={globalBlacklist}
          actions={blacklistActions}
          status={blacklist.domain}
          project={id}
        />
        <BlacklistType
          type="PREFIX"
          blacklist={blacklist}
          global={globalBlacklist}
          actions={blacklistActions}
          status={blacklist.prefix}
          project={id}
        />
        <BlacklistType
          type="EMAIL"
          blacklist={blacklist}
          global={globalBlacklist}
          actions={blacklistActions}
          status={blacklist.email}
          project={id}
        />
      </main>
    );
  }
  if (blacklist.status === statuses.FAILED) {
    return (
      <main className={styles.blacklist}>
        <div>
          <p> Une erreur est survenue lors du chargement des données</p>
          <p>{`${blacklist.error}`}</p>
        </div>
      </main>
    );
  }
  return (
    <main className={styles.blacklist}>
      <Loading />
    </main>
  );
}
