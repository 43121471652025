/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route, useHistory,
} from 'react-router-dom';

import Navigation from '../../components/Navigation';
import Homepage from '../../views/Homepage';
import ImportManager from '../../views/ImportManager';
import ContactsDetails from '../../views/ContactsDetails';
import Blacklist from '../../views/Blacklist';
import Documentation from '../../views/Documentation';
import Loading from '../../components/library/Loading';
import verifyToken from '../store/features/roles/actions';
import ErrorBoundary from '../../components/ErrorBoundary';
import Global from '../../views/Global';
import Intraday from '../../views/Intraday';
import History from '../../views/History';

const ROUTES = [
  {
    path: '/history',
    component: History,
    auth: true,
  },
  {
    path: '/intraday',
    component: Intraday,
    auth: true,
  },
  {
    path: '/documentation',
    component: Documentation,
    auth: true,
  },
  {
    path: '/contacts',
    component: ContactsDetails,
    auth: true,
  },
  {
    path: '/blacklist',
    component: Blacklist,
    auth: true,
  },
  {
    path: '/import',
    component: Global,
    auth: true,
  },
  {
    path: '/:id',
    component: ImportManager,
    auth: true,
  },
  {
    path: '/',
    component: Homepage,
    auth: true,
  },
];

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes({
  path, auth, routes, status, roles, authorizedRole, ...route
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const debug = urlParams.get('debug');
    if (debug === 'true') {
      history.push('/debug');
    }
    if (auth) {
      dispatch(verifyToken());
    }
  }, []);

  useEffect(() => {
    if (roles && roles.length > 0 && status === 'SUCCEEDED') {
      // eslint-disable-next-line no-restricted-syntax
      for (const role of roles) {
        if (!authorizedRole.includes(role)) {
          if (history.length === 1) {
            document.location.assign('https//auth.ayomi.fr/');
          } else {
            history.goBack();
          }
        }
      }
    }
  }, [status]);

  if (status === 'SUCCEEDED') {
    return (
      <Route
        path={path}
        render={(props) => (
          // pass the sub-routes down to keep nesting
          <route.component {...props} routes={routes} />
        )}
      />
    );
  }
  return <Loading />;
}

export default function RouteConfig() {
  return (
    <Router>
      <div className="row">
        <Navigation />
        <ErrorBoundary>
          <Switch>
            {ROUTES.map((route) => (
              <ConnectedRouteWithSubRoutes key={route.path} {...route} />
            ))}
          </Switch>
        </ErrorBoundary>
      </div>
    </Router>
  );
}

const mapStateToProps = (state) => ({
  authorizedRole: state.roles.roles,
  status: state.roles.status,
});

const ConnectedRouteWithSubRoutes = connect(mapStateToProps)(RouteWithSubRoutes);
