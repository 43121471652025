let id = 0;

const progressBarFactory = ({ name, size, unit }) => {
  id += 1;
  return {
    id, name, size, unit,
  };
};

export default progressBarFactory;
