import * as types from './types';
import * as status from '../../status';

export const initialFilters = {
  segment: -1,
  proximity: -1,
  owner: -1,
  amount: -1,
  language: -1,
  exchange: -1,
  received: -1,
  send: -1,
  subscribe: -1,
};

const initialState = {
  active: null,
  contacts: [],
  segments: {
    id: null,
    status: status.IDLE,
    data: [],
  },
  filters: initialFilters,
  sort: { category: null, order: 'asc' },
  query: '',
  total: 0,
  contactsPerPage: 50,
  page: 1,
};

export default function contactsReducer(state = initialState, action) {
  const { type, payload } = action;
  let contactsList;
  switch (type) {
    case types.SET_SELECT_ALL:
      return {
        ...state,
        contacts: [
          ...state.contacts.filter((list) => list.id !== state.active),
          {
            ...state.contacts.find((list) => list.id === state.active),
            data: payload.contacts,
          },
        ],
      };
    case types.SET_ACTIVE_PROJECT:
      return {
        ...state,
        active: payload,
        page: 1,
        contacts: [
          ...state.contacts
            .filter((contact) => contact.id !== payload),
          {
            id: payload,
            status: status.IDLE,
            ...state.contacts
              .find((contact) => contact.id === payload),
          },
        ],
      };
    case types.SET_FILTERS:
      return {
        ...state,
        filters: payload,
      };
    case types.SET_SORT:
      return {
        ...state,
        sort: {
          category: payload,
          order: (state.sort.order === 'asc') ? 'dsc' : 'asc',
        },
      };
    case types.SET_QUERY:
      return {
        ...state,
        query: payload,
        page: 1,
      };
    case types.FETCH_CONTACTS_REQUEST:
      return {
        ...state,
        page: 1,
        contacts: [
          ...state.contacts
            .filter((contact) => contact.id !== payload),
          {
            id: payload,
            status: status.LOADING,
          },
        ],
      };
    case types.FETCH_CONTACTS_SUCCEEDED:
      return {
        ...state,
        contacts: [
          ...state.contacts
            .filter((contact) => contact.id !== payload.id),
          {
            id: payload.id,
            status: status.SUCCEEDED,
            total: payload.contacts.length,
            languages: payload.languages,
            data: payload.contacts,
          },
        ],
      };
    case types.FETCH_CONTACTS_FAILED:
      return {
        ...state,
        contacts: [
          ...state.contacts
            .filter((contact) => contact.id === payload),
          {
            id: state.active,
            status: status.FAILED,
            error: payload,
            data: [],
          },
        ],
      };
    case types.SET_NEXT:
      return {
        ...state,
        page: state.page + 1,
      };
    case types.RESET_FILTERS:
      return {
        ...state,
        filters: initialFilters,
      };
    case types.DELETE_ALL_CONTACTS:
      return {
        ...state,
        contacts: [
          ...state.contacts.filter((project) => project.id !== state.active),
        ],
      };
    case types.DELETE_CONTACTS_BY_IMPORTER:
      return {
        ...state,
        contacts: [
          ...state.contacts.filter((project) => project.id !== state.active),
          payload,
        ],
      };
    case types.BLACKLIST_CONTACTS_SUCCEEDED:
      contactsList = [
        ...state.contacts
          .find((project) => project.id === state.active).data
          .filter((contact) => !payload.includes(contact.emailGeneral)),
        ...state.contacts
          .find((project) => project.id === state.active).data
          .filter((contact) => payload.includes(contact.emailGeneral))
          .map((contact) => ({ ...contact, isSelected: false, segment: '7' })),
      ];
      contactsList.sort((a, b) => a.id - b.id);
      return {
        ...state,
        contacts: [
          ...state.contacts.filter((project) => project.id !== state.active),
          {
            ...state.contacts.find((project) => project.id === state.active),
            data: contactsList,
          },
        ],
      };
    case types.UNBLACKLIST_CONTACTS_SUCCEEDED:
      contactsList = [
        ...state.contacts
          .find((project) => project.id === state.active).data
          .filter((contact) => !payload.includes(contact.emailGeneral)),
        ...state.contacts
          .find((project) => project.id === state.active).data
          .filter((contact) => payload.includes(contact.emailGeneral))
          .map((contact) => ({ ...contact, isSelected: false, segment: '1' })),
      ];
      contactsList.sort((a, b) => a.id - b.id);
      return {
        ...state,
        contacts: [
          ...state.contacts.filter((project) => project.id !== state.active),
          {
            ...state.contacts.find((project) => project.id === state.active),
            data: contactsList,
          },
        ],
      };
    case types.UPDATE_CONTACTS_SUCCEEDED:
      contactsList = [
        payload.contact,
        ...state.contacts
          .find((project) => project.id === state.active).data
          .filter((contact) => contact.emailGeneral !== payload.contact.emailGeneral),
      ];
      contactsList.sort((a, b) => a.id - b.id);
      return {
        ...state,
        contacts: [
          ...state.contacts.filter((project) => project.id !== payload.id),
          {
            ...state.contacts.find((project) => project.id === payload.id),
            data: contactsList,
          },
        ],
      };
    case types.FETCH_SEGMENT_REQUEST:
      return {
        ...state,
        segments: {
          id: payload,
          status: status.LOADING,
        },
      };
    case types.FETCH_SEGMENT_SUCCEEDED:
      return {
        ...state,
        segments: {
          ...state.segments,
          data: payload.data,
          id: payload.id,
          status: status.SUCCEEDED,
        },
      };
    case types.FETCH_SEGMENT_FAILED:
      return {
        ...state,
        segments: {
          ...state.segments,
          status: status.FAILED,
          error: payload.err,
        },
      };
    default:
      return state;
  }
}
