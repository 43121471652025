import React, {
  useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { PushPin, PushPinOutlined, MenuOpen } from '@mui/icons-material';

import Searchbar from './Searchbar';
import * as state from '../../services/store/status';
import styles from './Navigation.module.css';

import useOutsideClickAlert from '../library/hook/clickOutside';

export default function Navigation({
  shouldFetch, status, projects, activeProjects, actions,
}) {
  const [isMenuShow, setIsMenuShow] = useState(false);
  const [filters, setFilters] = useState('');

  const location = useLocation();

  useEffect(() => {
    if (shouldFetch && status === state.IDLE) {
      actions.fetchActiveProject();
    }
  }, [shouldFetch]);

  const menuRef = useRef();
  useOutsideClickAlert(menuRef, () => setIsMenuShow(false));

  const handleFilter = (project) => !`${project.id}`.search(filters);

  const getTabList = (id) => {
    const projectsList = activeProjects.map((active) => ({ ...active, isPinned: true }));
    if (Number.isNaN(Number.parseInt(id, 10))) return projectsList;
    if (!activeProjects
      .find((project) => Number.parseInt(project.id, 10) === Number.parseInt(id, 10))) {
      return [
        {
          id,
          name: projects.find((project) => project.id === Number.parseInt(id, 10))?.name || '',
          isPinned: false,
        },
        ...projectsList,
      ];
    }
    return projectsList;
  };

  return (
    <div>
      {
        (!isMenuShow)
        && (
          <button
            type="button"
            className={`${styles.absolute} ${styles.menuButton}`}
            onClick={() => setIsMenuShow((menu) => !menu)}
          >
            <MenuOpen />
          </button>
        )
      }
      <nav
        ref={menuRef}
        className={`${styles.navigation} ${(!isMenuShow) ? styles.hide : styles.show}`}
      >
        <div className={styles.logoContainer}>
          <p className={styles.logo}>
            Ayomi
            <span className={styles.dot}>
              .
            </span>
          </p>
          <p className={styles.logoDescription}>
            Administration
          </p>
        </div>
        <div className={styles.general}>
          <p className={styles.title}>
            GENERAL
          </p>
        </div>
        <ul
          className={styles.list}
        >
          <NavLink
            to="/contacts"
            className={styles.link}
          >
            Données contacts
          </NavLink>
          <NavLink
            to="/blacklist"
            className={styles.link}
          >
            Blacklist
          </NavLink>
          <NavLink
            to="/documentation"
            className={styles.link}
          >
            Documentation
          </NavLink>
          <NavLink
            to="/import"
            className={styles.link}
          >
            Import global
          </NavLink>
          <NavLink
            to="/intraday"
            className={styles.link}
          >
            Intraday
          </NavLink>
          <NavLink
            to="/history"
            className={styles.link}
          >
            Historique des logs
          </NavLink>
        </ul>
        <div className={styles.tabTitle}>
          <p className={styles.title}>
            ONGLETS
          </p>
        </div>
        <ul className={styles.tab}>
          {
            getTabList(location.pathname.split('/')[1]).map((project) => (
              <NavLink
                key={project.id}
                className={`${styles.link} ${styles.pin}`}
                activeClassName={styles.selected}
                to={`/${project.id}`}
              >
                <span>
                  {`Projet ${project.id} ${project.name}`}
                </span>
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    actions.setActiveProject(project);
                  }}
                >
                  {
                    project.isPinned
                      ? <PushPin className={styles.pinned} />
                      : <PushPinOutlined />
                  }
                </button>
              </NavLink>
            ))
          }
        </ul>
        <div className={styles.searchbar}>
          <p className={styles.title}>
            PROJETS
          </p>
          <Searchbar
            search={actions.search}
            filter={(value) => setFilters(value)}
          />
        </div>
        {
          (projects && projects.filter(handleFilter).length > 0)
            ? (
              <ul className={`${styles.list} ${styles.projects}`}>
                {
                projects
                  .filter(handleFilter)
                  .map((project) => (
                    <NavLink
                      key={project.id}
                      className={styles.link}
                      to={`/${project.id}`}
                    >
                      {`${project.id} - ${project.name}`}
                    </NavLink>
                  ))
              }
              </ul>
            )
            : (
              <div className={styles.empty}>
                <p>
                  Aucun projet actif.
                </p>
                <p>
                  Accéder à un projet en utilisant la barre de
                  recherche et en appuyant sur la flèche ou sur entrer.
                </p>
              </div>
            )
        }
      </nav>
    </div>
  );
}

Navigation.defaultProps = {
  projects: [],
  status: state.IDLE,
};

Navigation.propTypes = {
  status: PropTypes.oneOf(
    [state.IDLE, state.LOADING, state.SUCCEEDED, state.FAILED],
  ),
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  actions: PropTypes.shape({
    fetchActiveProject: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
  }).isRequired,
};
