import axios from '../axiosClient';

export const rerunImportByProject = (id) => axios.post('/importByProject', id);
export const rerunImportByAccount = (account) => axios.post('/importByAccount', account);
export const rerunImportByMail = ({
  id, account, issuer, type,
}) => axios.post(`/tasks/${id}/import/${account}/${issuer}/${type.toLowerCase()}`);

export const setImapServer = (id, imap, refresh) => axios.post(`/imports/imap/${id}?restart=${refresh ? 1 : 0}`, { imap });
export const getGlobalImport = () => axios.get('/imports/global');

export const skip = (id) => axios.post(`/imports/skip/${id}`);
export const setImportStatus = (importId, status) => axios.post(`/imports/status/${importId}/${status}`);
export const setAsChecked = (importId) => axios.post(`/imports/check/${importId}`);
export const getIntraday = (date) => axios.get(`/intraday/${date}`);
