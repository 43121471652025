import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { removeProgressBar } from '../../services/store/features/progressBar/actions';
// eslint-disable-next-line import/extensions
import UploadProgressionBar from './UploadProgressionBar.jsx';

const mapStateToProps = (state) => ({
  list: state.progress.list,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ removeProgressBar }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadProgressionBar);
