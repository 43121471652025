import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// eslint-disable-next-line import/extensions
import ImportManagerContactSegmentation from './ImportManagerContactSegmentation.jsx';
import { fetchSegmentInfoById } from '../../../../services/store/features/contacts/actions';

const mapStateToProps = (state) => ({
  status: state.contacts.segments.status,
  data: state.contacts.segments.data,
  projectId: state.contacts.segments.id,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchSegmentInfoById }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportManagerContactSegmentation);
