import React, { useState, useEffect, useRef } from 'react';
import Proptypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CheckIcon from '@material-ui/icons/Check';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import LoopIcon from '@material-ui/icons/Loop';
import GetAppIcon from '@material-ui/icons/GetApp';
import Person from '@material-ui/icons/Person';

import Dropdown from '../../../../../../components/Dropdown';
import ImportManagerSourceTableRowsAccountMails from './AccountMails';
import useOutsideClickAlert from '../../../../../../components/library/hook/clickOutside';
import styles from '../../ImportManagerSourceTable.module.css';
import { SEGMENTS_TYPE } from '../../../../../../utils/getSegmentLabel';
import { isUserAuthorized } from '../../../../../../services/store/features/roles/actions';

export default function ImportManagerSourceTableRowsAccount({
  contacts, query, account, actions, handleDelete, roles,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [blacklist, setBlacklist] = useState(account?.blacklistPercent);
  const [isMoreOpen, setIsMoreOpen] = useState(false);

  const optionsRef = useRef();
  useOutsideClickAlert(optionsRef, () => setIsMoreOpen(false));

  useEffect(() => {
    if (query) {
      setIsOpen(true);
    }
  }, [query]);

  useEffect(() => {
    setBlacklist(account?.blacklistPercent);
  }, [account]);

  const PROGRESS_STATE = {
    waiting: {
      label: 'En attente',
      value: 'waiting',
      style: styles.waiting,
    },
    done: {
      label: 'Terminé',
      value: 'done',
      style: styles.done,
    },
    loading: {
      label: 'En cours',
      value: 'loading',
      style: styles.loading,
    },
  };

  const getProgess = () => {
    if (account && account.issuer) {
      if (account.issuer.map((i) => i.status).every((s) => s === 'end')) return PROGRESS_STATE.done;
      const progress = account.issuer.reduce((acc, curr) => (acc + curr.global), 0);
      if (progress === 0) return PROGRESS_STATE.waiting;
      if (progress === account.issuer.length * 100) return PROGRESS_STATE.done;
      return PROGRESS_STATE.loading;
    }
    return PROGRESS_STATE.waiting;
  };

  const handleDeleteAccount = (value) => {
    handleDelete({ status: true, function: () => actions.deleteAccountByMail(value) });
  };

  const handleDeleteAccountsAndContacts = (value) => {
    handleDelete({ status: true, function: () => actions.deleteAccountByMail(value, true) });
  };

  const handleSubmit = (mail) => {
    setIsEdit(false);
    actions.setAccountBlacklist(mail, Number.parseInt(blacklist, 10));
  };

  const handleImport = (value) => {
    actions.importContactsByAccount(value);
    setIsMoreOpen(false);
  };

  const handleConnectAs = (value) => {
    actions.getLoginLink(value, account.projectId);
    setIsMoreOpen(false);
  };

  const getBlacklistedContacts = () => contacts?.data?.filter(
    (contact) => ((contact.emailImportateur === account.email) && (contact.segment === '7')),
  ).length || 0;

  const getBlacklistPercentage = () => {
    const total = account.issuer
      ?.reduce((acc, curr) => acc + ((curr.contacts !== -1) ? curr.contacts : 0), 0) || 0;
    if (total !== 0) {
      return (100 * getBlacklistedContacts()) / total;
    }
    return 0;
  };

  const getOptionButtons = (accountToUpdate) => {
    const status = getProgess().value;
    const moreButtons = [
      {
        label: 'Télécharger',
        styles: (
          (account.issuer?.reduce((acc, curr) => acc + curr.contacts, 0) || 0) > 0)
          ? styles.optionButton
          : styles.disabled,
        action: () => {
          actions.downloadAllContactFromAccount(accountToUpdate);
          setIsMoreOpen(false);
        },
        icon: <GetAppIcon className={styles.icon} />,
      },
      {
        label: 'Se connecter',
        styles: (
          (!isUserAuthorized(roles, ['AUTH_AS_USER']))
            ? styles.disabled
            : styles.optionButton
        ),
        action: () => handleConnectAs(accountToUpdate),
        icon: <Person className={styles.icon} />,
      },
      {
        label: 'Supprimer le compte',
        styles: styles.importButton,
        action: () => handleDeleteAccount(accountToUpdate),
        icon: <DeleteIcon className={styles.icon} />,
      },
      {
        label: 'Supprimer les contacts',
        styles: styles.importButton,
        action: () => handleDeleteAccountsAndContacts(accountToUpdate),
        icon: <DeleteIcon className={styles.icon} />,
      },
    ];
    if (status !== 'done') {
      return moreButtons;
    }
    return [
      {
        label: 'Relancer',
        styles: styles.optionButton,
        action: () => handleImport(accountToUpdate),
        icon: <LoopIcon className={styles.icon} />,
      },
      ...moreButtons,
    ];
  };

  return (
    <div>
      <div
        className={`${styles.grid} ${styles.source} ${styles.relative}`}
        role="button"
        tabIndex="-1"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setIsOpen((state) => !state);
          }
        }}
      >
        <button
          type="button"
          onClick={() => setIsOpen((state) => !state)}
        >
          {
            (!isOpen)
              ? <ExpandMoreIcon />
              : <ExpandLessIcon />
          }
        </button>
        <p>
          { account.email }
        </p>
        <p>
          { account.issuer
            ?.reduce((acc, curr) => acc + ((curr.contacts !== -1) ? curr.contacts : 0), 0) || 0}
        </p>
        {
          (!isEdit)
            ? (
              <button
                className={`${styles.reset} p ${styles.pointable}`}
                type="button"
                onDoubleClick={() => setIsEdit(true)}
              >
                {blacklist}
                %
              </button>
            )
            : (
              <input
                className={styles.blacklistInput}
                value={blacklist}
                onChange={(e) => setBlacklist(e.target.value)}
              />
            )
        }
        <p className={(blacklist < getBlacklistPercentage()) ? styles.blacklistWarn : ''}>
          {`${getBlacklistPercentage().toFixed(2)}%`}
        </p>
        <p>
          { SEGMENTS_TYPE[account.type] || 'undefined'}
        </p>
        <p>
          { account.email }
        </p>
        <div className={styles.status}>
          <div className={getProgess()?.style} />
          <p>{ getProgess()?.label }</p>
        </div>
        {
          (!isEdit)
            ? (
              <div ref={optionsRef}>
                <button
                  type="button"
                  className={styles.button}
                  onClick={() => setIsMoreOpen((state) => !state)}
                >
                  <MoreVertIcon />
                </button>
                {
                  (isMoreOpen) && <Dropdown buttons={getOptionButtons(account.email)} />
                }
              </div>
            )
            : (
              <button
                type="button"
                className={styles.button}
                onClick={() => handleSubmit(account.email)}
              >
                <CheckIcon />
              </button>
            )
        }
      </div>
      {
        (isOpen) && (
          <ImportManagerSourceTableRowsAccountMails
            id={account.email}
            mails={account?.issuer?.map((mail) => ({
              ...mail,
              blacklist: account.blacklistPercent,
              account: account.email,
              blacklisted: contacts.data.filter((contact) => ((contact.issuer === mail.id) && (contact.segment === '7'))).length,
            }))}
            handleDelete={handleDelete}
          />
        )
      }
    </div>
  );
}

ImportManagerSourceTableRowsAccount.defaultProps = {
  query: '',
};

ImportManagerSourceTableRowsAccount.propTypes = {
  query: Proptypes.string,
  account: Proptypes.shape({
    email: Proptypes.string,
    blacklistPercent: Proptypes.number,
    issuer: Proptypes.arrayOf(
      Proptypes.shape({
        mail: Proptypes.string,
        contacts: Proptypes.number,
        type: Proptypes.string,
        status: Proptypes.string,
        progress: Proptypes.number,
      }),
    ),
  }).isRequired,
  actions: Proptypes.shape({
    deleteAccountByMail: Proptypes.func,
    setAccountBlacklist: Proptypes.func,
    importContactsByAccount: Proptypes.func,
  }).isRequired,
};
