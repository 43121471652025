import React, { useState, useEffect } from 'react';
import axios from 'axios';
import marked from 'marked';
import { sanitize } from 'dompurify';

import markdown from './README.md';
import shared from '../../styles/shared.module.css';
import styles from './Documentation.module.css';

export default function Documentation() {
  const [documentation, setDocumentation] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(markdown);
      setDocumentation(sanitize(marked(response.data)));
    };
    fetchData();
  }, []);
  return (
    <section className={shared.section}>
      <header className={shared.header}>
        <h1 className={shared.title}>
          Documentation
        </h1>
      </header>
      <main className={styles.main}>
        <article
          className={styles.markdown}
          dangerouslySetInnerHTML={{ __html: documentation }}
        />
      </main>
    </section>
  );
}
