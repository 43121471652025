import * as types from './types';
import * as status from '../../status';

const initialState = {
  active: null,
  isOpen: true,
  sources: [],
  query: '',
  isDownloading: false,
  isUploading: false,
  percentCompleted: 0,
  timer: null,
};

export default function sourcesReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.OPEN_VIEW:
      return {
        ...state,
        isOpen: true,
      };
    case types.CLOSE_VIEW:
      return {
        ...state,
        isOpen: false,
      };
    case types.SET_ACTIVE_SOURCE:
      return {
        ...state,
        active: payload,
      };
    case types.SET_QUERY:
      return {
        ...state,
        query: payload,
      };
    case types.SET_TIMER:
      return {
        ...state,
        timer: payload,
      };
    case types.FETCH_SOURCE_REQUEST:
      return {
        ...state,
        sources: (state.sources.map((source) => source.id).indexOf(state.active) !== -1)
          ? (state.sources.map((source) => ((source.id !== state.active)
            ? source
            : { ...source, status: status.LOADING, error: null }))
          )
          : [...state.sources, { id: state.active, status: status.LOADING, error: null }],
      };
    case types.FETCH_SOURCE_SUCCEEDED:
      return {
        ...state,
        sources: state.sources.map((source) => ((source.id !== state.active)
          ? source
          : { ...source, status: status.SUCCEEDED, accounts: payload })),
      };
    case types.FETCH_SOURCE_FAILED:
      return {
        ...state,
        sources: state.sources.map((source) => ((source.id !== state.active)
          ? source
          : { ...source, status: status.FAILED, error: payload })),
      };
    case types.DOWNLOAD_CONTACTS_REQUEST:
      return {
        ...state,
        isDownloading: true,
      };
    case types.DOWNLOAD_CONTACTS_SUCCEEDED:
      return {
        ...state,
        isDownloading: false,
      };
    case types.DOWNLOAD_CONTACTS_FAILED:
      return {
        ...state,
        isDownloading: false,
      };
    case types.UPLOAD_CONTACTS_REQUEST:
      return {
        ...state,
        isUploading: true,
        percentCompleted: 0,
      };
    case types.UPLOAD_CONTACTS_SUCCEEDED:
      return {
        ...state,
        sources: payload,
      };
    case types.UPLOAD_CONTACTS_FAILED:
      return {
        ...state,
        isUploading: false,
        error: payload,
        percentCompleted: 0,
      };
    case types.UPLOAD_CONTACTS_CANCELED:
      return {
        ...state,
        isUploading: false,
        percentCompleted: 0,
      };
    case types.SET_UPLOAD_PROGRESS:
      return {
        ...state,
        percentCompleted: payload,
      };
    case types.RERUN_ACCOUNT_IMPORT_REQUEST:
      return {
        ...state,
        isImporting: true,
      };
    case types.RERUN_ACCOUNT_IMPORT_SUCCEEDED:
      return {
        ...state,
        isImporting: false,
        sources: payload,
      };
    case types.RERUN_ACCOUNT_IMPORT_FAILED:
      return {
        ...state,
        isImporting: false,
        error: payload,
      };
    case types.DELETE_ALL_SOURCES_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case types.DELETE_ALL_SOURCES_SUCCEEDED:
      return {
        ...state,
        isImporting: false,
        sources: state.sources.filter((source) => source.id !== payload),
      };
    case types.DELETE_ALL_SOURCES_FAILED:
      return {
        ...state,
        isImporting: false,
      };
    case types.UPDATE_SOURCE_REQUEST:
      return {
        ...state,
        sources: state.sources?.map((s) => (
          (s.id === state.active)
            ? { ...s, isUpdating: true }
            : s
        )),
      };
    case types.UPDATE_SOURCE_SUCCEEDED:
      return { ...state, sources: payload };
    case types.UPDATE_SOURCE_BLACKLIST_SUCCEEDED:
      return {
        ...state,
        sources: state.sources.map((source) => (
          (source.id === payload.projectId)
            ? {
              ...source,
              accounts: source.accounts.map((account) => (
                (account.email === payload.email) ? payload : account
              )),
            }
            : source
        )),
      };
    case types.SET_IMPORT_STATUS:
      return {
        ...state,
        sources: state.sources.map((source) => (
          (source.id === state.active)
            ? {
              ...source,
              accounts: source.accounts.map((account) => (
                (account.email === payload.email)
                  ? {
                    ...account,
                    issuer: account.issuer
                      .map((imported) => ((imported.importId === payload.importId)
                        ? { ...imported, status: payload.status }
                        : imported)),
                  }
                  : account
              )),
            }
            : source
        )),
      };
    default:
      return state;
  }
}
