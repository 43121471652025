import React, { useState, useEffect } from 'react';

import * as statuses from '../../../services/store/status';
import { getLogHistory } from '../../../services/clients/lib/history';

function HistoryManager({ params, children }) {
  const [status, setStatus] = useState(statuses.IDLE);
  const [history, setHistory] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const fetchHistory = async () => {
      setStatus(statuses.LOADING);
      try {
        const { data } = await getLogHistory({
          authId: params.authId !== '' ? params.authId : undefined,
          request: params.request !== '' ? params.request : undefined,
        }, { start: params.begin, end: params.end });
        if (data.status === 'OK') {
          setHistory(data.data);
          setStatus(statuses.SUCCEEDED);
        } else {
          setStatus(statuses.FAILED);
          setError(data.msg);
        }
      } catch (err) {
        setStatus(statuses.FAILED);
        setError(err);
      }
    };
    fetchHistory();
  }, [params]);

  return (
    <>
      {
        children({ status, data: history, error })
      }
    </>
  );
}

export default React.memo(HistoryManager,
  (prev, next) => Object.keys(prev.params)
    .reduce((acc, key) => acc && (prev.params[key] === next.params[key]), true));
