import * as types from './type';
import { getTaskHistoryByProject, createBlacklistTask } from '../../../clients/lib/history';

export function fetchTaskHistory(id) {
  return async (dispatch) => {
    dispatch({ type: types.FETCH_HISTORY_REQUEST });
    try {
      const { data } = await getTaskHistoryByProject(id);
      if (data.status === 'OK') {
        dispatch({
          type: types.FETCH_HISTORY_SUCCEEDED,
          payload: data.data,
        });
      } else {
        dispatch({
          type: types.FETCH_ASSEMBLY_FAILED,
          payload: data.msg,
        });
      }
    } catch (err) {
      console.error('[ERROR]: ', err);
      dispatch({
        type: types.FETCH_ASSEMBLY_FAILED,
        payload: err,
      });
    }
  };
}

export function launchBlacklistTask(id) {
  return async (dispatch) => {
    dispatch({ type: types.CREATE_TASK_REQUEST });
    try {
      const { data } = await createBlacklistTask(id);
      if (data.status === 'OK') {
        dispatch({
          type: types.CREATE_TASK_SUCCEEDED,
          payload: data.data,
        });
      }
    } catch (err) {
      dispatch({ type: types.CREATE_TASK_FAILED, payload: err });
    }
  };
}
