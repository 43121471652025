import React, { useEffect } from 'react';

import ImportManagerPotentialsTable from './ImportManagerPotentialsTable';
import styles from './ImportManagerPotentials.module.css';

export default function ImportManagerPotentials({ id, source, actions }) {
  useEffect(() => {
    if (id && !source) {
      actions.setActiveSource(id);
    }
    if (source && id !== source.id) {
      actions.setActiveSource(id);
    }
  }, [id]);

  useEffect(() => {
    if (!source) {
      actions.fetchSourcesById(id);
    }
  }, [source]);

  return (
    <main className={styles.potential}>
      <ImportManagerPotentialsTable
        source={source}
      />
    </main>
  );
}
