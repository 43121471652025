import * as types from './type';
import * as statuses from '../../status';

const initialState = {
  status: statuses.IDLE,
  error: null,
  data: [],
  isCreatingTask: false,
  activeTask: null,
};

export default function historyReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_HISTORY_REQUEST:
      return { ...state, status: statuses.LOADING };
    case types.FETCH_HISTORY_SUCCEEDED:
      return {
        ...state,
        status: statuses.SUCCEEDED,
        data: payload,
        activeTask: payload
          .find((task) => ((task.task_type === 'blacklist') && (task.status === 'run')))
          ?.id || null,
      };
    case types.FETCH_ASSEMBLY_FAILED:
      return { ...state, status: statuses.FAILED, error: payload };
    case types.CREATE_TASK_REQUEST:
      return { ...state, isCreatingTask: true };
    case types.CREATE_TASK_SUCCEEDED:
      return {
        ...state,
        isCreatingTask: false,
        data: [...state.data, { ...payload }],
        activeTask: payload.task_id,
      };
    case types.CREATE_TASK_FAILED:
      return {
        ...state,
        isCreatingTask: false,
        error: payload,
        activeTask: null,
      };
    default:
      return state;
  }
}
