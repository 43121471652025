export default function formatContact(contact) {
  const newContact = {
    ...contact,
    emails: contact.emails.join(' / '),
    projects: contact.projects.join(' / '),
    salary: contact.salary.threshold,
    proximity: contact.proximity.threshold,
    Marie: contact.mailSegment.Marie,
    actu1: contact.mailSegment.actu1,
    actu2: contact.mailSegment.actu2,
    actu3: contact.mailSegment.actu3,
    sophie1: contact.mailSegment.sophie1,
    sophie2: contact.mailSegment.sophie2,
    sophie3: contact.mailSegment.sophie3,
    source: contact.source.join(' / '),
  };
  delete newContact.mailSegment;
  delete newContact.exchanges;
  return newContact;
}
