import React from 'react';
import { Delete } from '@material-ui/icons';
import Button from '../../../../components/library/Button/Button';
import styles from './DeletionPopUp.module.css';

export default function DeletionPopUp({ close, deleteAccount }) {
  const handleDelete = () => {
    deleteAccount();
    close();
  };

  return (
    <div className={`fadeIn ${styles.background}`}>
      <section className={styles.modal}>
        <p className={styles.title}>
          Êtes vous sûr de vouloir supprimer ?
        </p>
        <p className={styles.description}>
          Attention, vous ne pourrez plus revenir en arrière
        </p>
        <div className={styles.buttonGroup}>
          <Button
            label="Annuler"
            onButtonClick={close}
          />
          <Button
            type="primary"
            label="Supprimer"
            icon={<Delete />}
            onButtonClick={handleDelete}
          />
        </div>
      </section>
    </div>
  );
}
