import React from 'react';
import Proptypes from 'prop-types';

import ImportManagerSourceTableRowsAccountMailsRow from './AccountMailsRow';
import styles from '../../../ImportManagerSourceTable.module.css';

export default function ImportManagerSourceTableRowsAccountMails({
  id, query, mails, accounts, actions, handleDelete,
}) {
  const getCurrentMails = () => {
    if (!query) return mails;
    return mails.filter((mail) => {
      let mailStr = `${mail.id} ${mail.contacts} ${mail.blacklist}
      ${mail.type} ${mail.source} ${mail.status}`;
      mailStr = mailStr.toLowerCase();
      return (mailStr.search(query.toLowerCase()) !== -1);
    });
  };

  return (
    <ul className={styles.list}>
      {
        (mails)
        && (
          getCurrentMails()
            .map((mail) => (
              <ImportManagerSourceTableRowsAccountMailsRow
                key={mail.importId}
                mail={mail}
                accounts={accounts}
                id={id}
                actions={actions}
                open={() => actions.setTabToDisplay('email')}
                handleDelete={handleDelete}
              />
            )))
      }
    </ul>
  );
}

ImportManagerSourceTableRowsAccountMails.defaultProps = {
  mails: [],
  accounts: [],
  query: undefined,
};

ImportManagerSourceTableRowsAccountMails.propTypes = {
  id: Proptypes.string.isRequired,
  query: Proptypes.string,
  mails: Proptypes.arrayOf(
    Proptypes.shape({
      mail: Proptypes.string,
    }),
  ),
  accounts: Proptypes.arrayOf(
    Proptypes.string,
  ),
  actions: Proptypes.shape({}).isRequired,
};
