import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ContactsDetails from './ContactsDetails';
import { setSelectedContact, setSelectedProject } from '../../services/store/features/contactDetails/actions';

const mapStateToProps = (state) => ({
  status: state.details.status,
  details: state.details.details,
  projects: state.details.projects,
  selectedProject: state.details.selectedProject,
  error: state.details.error,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setSelectedContact,
    setSelectedProject,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactsDetails);
