import React, { useRef, useState } from 'react';
import { Check } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import styles from '../../GlobalTable.module.css';
import stylesRow from '../GlobalTableRow.module.css';
import stylesEdit from './GlobalTableRowEdit.module.css';
import useOutsideClickAlert from '../../../../../components/library/hook/clickOutside';
import * as statuses from '../../../../../services/store/status';
import { setImportStatus } from '../../../../../services/clients/lib/import';
import { addToast } from '../../../../../services/store/features/toasts/actions';

export default function GlobalTableRowEdit({
  item, getStatus, close, statusList, setStatus,
}) {
  const [submitStatus, setSubmitStatus] = useState(statuses.IDLE);
  const [statut, setStatut] = useState(item.statut);
  const [isStatusOpen, setIsStatusOpen] = useState(true);
  const dropdown = useRef();

  useOutsideClickAlert(dropdown, () => setIsStatusOpen(false));
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    if (statut === item.statut) {
      close();
      return;
    }
    setSubmitStatus(statuses.LOADING);
    try {
      const { data } = await setImportStatus(item.importId, statut);
      if (data.status === 'OK') {
        setStatus(item.importId, statut);
      } else {
        dispatch(addToast({
          type: 'error',
          title: 'Impossible de modifier le statut',
          description: data.msg,
        }));
      }
    } catch (err) {
      dispatch(addToast({
        type: 'error',
        title: 'Impossible de modifier le statut',
        description: `${err}`,
      }));
    } finally {
      close();
    }
  };

  return (
    <div
      className={`${styles.grid} ${styles.row} ${stylesRow.issuer}`}
      key={item.importId}
    >
      <p>{ item.time_start }</p>
      <p>{ item.importId }</p>
      <div />
      <div
        role="button"
        tabIndex="-1"
        className={stylesEdit.relative}
        onClick={() => setIsStatusOpen(true)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setIsStatusOpen(true);
          }
        }}
      >
        <p className={`${styles.status} ${styles[getStatus({ ...item, statut })]}`}>{ statut }</p>
        { (statut === 'run')
          && (
            <p className={stylesRow.description}>
              {`Import: ${item.global}%`}
            </p>
          )}
        {
          (isStatusOpen)
          && (
            <div
              ref={dropdown}
              className={stylesEdit.dropdown}
            >
              <p className={stylesEdit.label}>
                Sélectionnez un status :
              </p>
              <div className={stylesEdit.buttonGroup}>
                {
                  statusList.map((status) => (
                    <button
                      type="button"
                      key={status.value}
                      className={`${stylesEdit.button} ${styles[status.color]}`}
                      onClick={() => setStatut(status.value)}
                    >
                      { status.value }
                    </button>
                  ))
                }
              </div>
            </div>
          )
        }
      </div>
      <button
        type="button"
        className={styles.actions}
        onClick={handleSubmit}
        disabled={submitStatus === statuses.LOADING}
      >
        {
        (submitStatus === statuses.LOADING)
          ? <FontAwesomeIcon icon={faSpinner} spin />
          : <Check />
      }
      </button>
    </div>
  );
}
