import React from 'react';

import styles from './HistoryTable.module.css';

export default function HistoryTable({ data }) {
  return (
    <ul className={styles.list}>
      {
        (data.length > 0)
          ? data.map((log) => (
            <li
              key={log.date}
              className={styles.date}
            >
              <p>
                {`${new Date(log.date).toLocaleDateString()} ${new Date(log.date).toLocaleTimeString()}`}
              </p>
              <p className={styles.method}>
                {log.method}
              </p>
              <a href={log.url}>
                {log.url}
              </a>
              <p>
                <span className={styles.authId}>
                  AuthId:
                </span>
                <b>
                  {log.authId}
                </b>
              </p>
            </li>
          ))
          : <p>Aucun logs trouvés</p>
      }
    </ul>
  );
}
