import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import styles from './RowsAccount.module.css';

export default function RowsAccount({ account }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.accountRow}>
      <div className={styles.grid}>
        <button
          type="button"
          onClick={() => setIsOpen((state) => !state)}
        >
          {
          (!isOpen)
            ? <ExpandMoreIcon />
            : <ExpandLessIcon />
        }
        </button>
        <div>
          <p className={styles.email}>
            { account.email }
          </p>
          <p className={styles.description}>
            {`${account.potential.length} compte${(account.potential > 1) ? 's' : ''} potentiel${(account.potential > 1) ? 's' : ''} trouvé`}
          </p>
        </div>
      </div>
      {
        (isOpen)
        && (
          <div className={styles.potential}>
            {
              account.potential.map((mail) => (
                <div
                  className={styles.row}
                  key={mail.email}
                >
                  <div />
                  <p>
                    { mail.email }
                    {
                      (mail.reason !== '')
                      && (
                        <span className={styles.reason}>
                          {`( ${mail.reason} )`}
                        </span>
                      )
                    }
                  </p>
                </div>
              ))
            }
          </div>
        )
      }
    </div>
  );
}
