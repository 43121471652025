import React from 'react';
import shared from '../../styles/shared.module.css';
import styles from './Intraday.module.css';
import IntradayManager from './IntradayManager';
import IntradayTable from './IntradayTable';

export default function Intraday() {
  return (
    <section className={shared.section}>
      <header className={shared.header}>
        <h1 className={styles.title}>
          Intraday
        </h1>
      </header>
      <main className={styles.main}>
        <IntradayManager>
          {
            (data) => (
              <IntradayTable data={data} />
            )
          }
        </IntradayManager>
      </main>
    </section>
  );
}
