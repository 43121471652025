const getDateFromString = (str) => {
  // Get date object from str format DD/MM/YYYY HH:MM
  if (!str) return new Date(1970);
  const [date, time] = str.split(' ');
  const [day, month, year] = date.split('/');
  const [hours, minutes] = time.split(':');
  return new Date(year, month, day, hours, minutes);
};

export default getDateFromString;
