import * as types from './types';

export function addProgressBar(progressBar) {
  return (dispatch) => {
    dispatch({ type: types.ADD_PROGRESS_BAR, payload: progressBar });
  };
}

export function removeProgressBar(id) {
  return (dispatch) => {
    dispatch({ type: types.REMOVE_PROGRESS_BAR, payload: id });
  };
}

export function setProgressBarValues(id, progression) {
  return (dispatch) => {
    dispatch({ type: types.SET_PROGRESS_VALUE, payload: { id, percent: progression } });
    if (progression === 100) {
      window.setTimeout(() => dispatch(removeProgressBar(id)), 2000);
    }
  };
}
