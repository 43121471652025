import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ImportManager from './ImportManager';
import { setTabToDisplay } from '../../services/store/features/ui/actions';
import { setActiveSource } from '../../services/store/features/sources/actions';

const mapStateToProps = (state) => ({
  isSource: state.sources.isOpen,
  source: state.sources.sources?.find((source) => source.id === state.sources?.active)?.status,
  tabToDisplay: state.ui.activeTab,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setTabToDisplay,
    setActiveSource,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportManager);
