import React from 'react';

import styles from './HistoryError.module.css';

export default function HistoryError({ error }) {
  return (
    <div className={styles.container}>
      <p className={`bounceIn ${styles.title}`}>
        Oh non ! Une erreur est survenue lors du chargement de l&apos;historique
      </p>
      <details>
        { error }
      </details>
    </div>
  );
}
