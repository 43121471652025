export const FETCH_GLOBAL_BLACKLIST_REQUEST = 'FETCH_GLOBAL_BLACKLIST_REQUEST';
export const FETCH_GLOBAL_BLACKLIST_SUCCEEDED = 'FETCH_GLOBAL_BLACKLIST_SUCCEEDED';
export const FETCH_GLOBAL_BLACKLIST_FAILED = 'FETCH_GLOBAL_BLACKLIST_FAILED';

export const SEND_BLACKLIST_REQUEST = 'SEND_BLACKLIST_REQUEST';
export const SEND_BLACKLIST_SUCCEEDED = 'SEND_BLACKLIST_SUCCEEDED';
export const SEND_BLACKLIST_FAILED = 'SEND_BLACKLIST_FAILED';

export const DELETE_BLACKLIST_REQUEST = 'DELETE_BLACKLIST_REQUEST';
export const DELETE_BLACKLIST_SUCCEEDED = 'DELETE_BLACKLIST_SUCCEEDED';
export const DELETE_BLACKLIST_FAILED = 'DELETE_BLACKLIST_FAILED';

export const FETCH_BLACKLIST_REQUEST = 'FETCH_BLACKLIST_REQUEST';
export const FETCH_BLACKLIST_SUCCEEDED = 'FETCH_BLACKLIST_SUCCEEDED';
export const FETCH_BLACKLIST_FAILED = 'FETCH_BLACKLIST_FAILED';

export const SEND_BLACKLIST_PROJECT_REQUEST = 'SEND_BLACKLIST_PROJECT_REQUEST';
export const SEND_BLACKLIST_PROJECT_SUCCEEDED = 'SEND_BLACKLIST_PROJECT_SUCCEEDED';
export const SEND_BLACKLIST_PROJECT_FAILED = 'SEND_BLACKLIST_PROJECT_FAILED';

export const DELETE_BLACKLIST_PROJECT_REQUEST = 'DELETE_BLACKLIST_PROJECT_REQUEST';
export const DELETE_BLACKLIST_PROJECT_SUCCEEDED = 'DELETE_BLACKLIST_PROJECT_SUCCEEDED';
export const DELETE_BLACKLIST_PROJECT_FAILED = 'DELETE_BLACKLIST_PROJECT_FAILED';
