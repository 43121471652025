export const FETCH_CONTACTS_REQUEST = 'FETCH_CONTACTS_REQUEST';
export const FETCH_CONTACTS_SUCCEEDED = 'FETCH_CONTACTS_SUCCEEDED';
export const FETCH_CONTACTS_FAILED = 'FETCH_CONTACTS_FAILED';

export const UPDATE_CONTACTS_REQUEST = 'UPDATE_CONTACTS_REQUEST';
export const UPDATE_CONTACTS_SUCCEEDED = 'UPDATE_CONTACTS_SUCCEEDED';
export const UPDATE_CONTACTS_FAILED = 'UPDATE_CONTACTS_FAILED';

export const SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT';
export const SET_QUERY = 'SET_QUERY';
export const SET_NEXT = 'SET_NEXT';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_SORT = 'SET_SORT';
export const RESET_FILTERS = 'RESET_FILTERS';

export const DOWNLOAD_CONTACTS_REQUEST = 'DOWNLOAD_CONTACTS_REQUEST';
export const DOWNLOAD_CONTACTS_SUCCEEDED = 'DOWNLOAD_CONTACTS_SUCCEEDED';
export const DOWNLOAD_CONTACTS_FAILED = 'DOWNLOAD_CONTACTS_FAILED';

export const DELETE_ALL_CONTACTS = 'DELETE_ALL_CONTACTS';
export const DELETE_CONTACTS_BY_IMPORTER = 'DELETE_CONTACTS_BY_IMPORTER';

export const BLACKLIST_CONTACTS_REQUEST = 'BLACKLIST_CONTACTS_REQUEST';
export const BLACKLIST_CONTACTS_SUCCEEDED = 'BLACKLIST_CONTACTS_SUCCEEDED';
export const BLACKLIST_CONTACTS_FAILED = 'BLACKLIST_CONTACTS_FAILED';

export const UNBLACKLIST_CONTACTS_REQUEST = 'UNBLACKLIST_CONTACTS_REQUEST';
export const UNBLACKLIST_CONTACTS_SUCCEEDED = 'UNBLACKLIST_CONTACTS_SUCCEEDED';
export const UNBLACKLIST_CONTACTS_FAILED = 'UNBLACKLIST_CONTACTS_FAILED';

export const BLACKLIST_CONTACTS_PROGRESSION = 'BLACKLIST_CONTACTS_PROGRESSION';

export const SET_SELECT_ALL = 'SET_SELECT_ALL';

export const FETCH_SEGMENT_REQUEST = 'FETCH_SEGMENT_REQUEST';
export const FETCH_SEGMENT_SUCCEEDED = 'FETCH_SEGMENT_SUCCEEDED';
export const FETCH_SEGMENT_FAILED = 'FETCH_SEGMENT_FAILED';
