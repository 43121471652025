import React, { useState } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import styles from './BlacklistType.module.css';

export default function BlacklistTypeList({
  label, blacklist, project, type, actions, query, open = false,
}) {
  const [isOpen, setIsOpen] = useState(open);
  return (
    <div>
      <button
        type="button"
        className={styles.header}
        onClick={() => setIsOpen((state) => !state)}
      >
        <h3>
          { label }
        </h3>
        <span>
          {(isOpen) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </span>
      </button>
      {
        (isOpen || query !== '')
        && (
          (blacklist?.data
            .filter((d) => d.type === type)
            .filter((d) => (label === 'Global') || (d.project === project))
            .filter((d) => {
              if (query && query.trim() !== '') {
                return (d.regex.toLowerCase().search(query.toLowerCase()) !== -1);
              }
              return 1;
            })
            .length > 0)
            ? (
              <ul className={`${styles.list} ${styles.container}`}>
                {
                  (blacklist?.data
                    .filter((d) => d.type === type)
                    .filter((d) => (label === 'Global') || d.project === project)
                    .filter((d) => {
                      if (query && query.trim() !== '') {
                        return (d.regex.toLowerCase().search(query.toLowerCase()) !== -1);
                      }
                      return 1;
                    })
                    .map((i) => (
                      <li key={i.regex}>
                        <p>{ i.regex }</p>
                        {
                        (i.project !== 0)
                        && (
                          <button
                            type="button"
                            onClick={() => actions.unblacklist(type.toLowerCase(), [i.regex])}
                          >
                            <ClearIcon />
                          </button>
                        )
                      }
                      </li>
                    )))
              }
              </ul>
            )
            : (
              <div className={`${styles.emptyWrapper} ${styles.container}`}>
                <p className={styles.empty}> Aucune expression n&apos;a été blacklistée </p>
              </div>
            )
        )
      }
    </div>
  );
}
