import React, { useState } from 'react';

import Button from '../../components/library/Button/Button';
import Loading from '../../components/library/Loading';
import Details from '../../components/ContactDetails';
import * as statuses from '../../services/store/status';

import styles from './ContactsDetails.module.css';
import shared from '../../styles/shared.module.css';

export default function ContactsDetails({
  status, selectedProject, projects, details, actions, error,
}) {
  const [query, setQuery] = useState('');

  const handleSearch = () => {
    if (query !== '' && query.trim() !== '') {
      actions.setSelectedContact(query);
    }
  };

  if (status === statuses.LOADING) {
    return (
      <section className={styles.contacts}>
        <header className={shared.header}>
          <h1 className={shared.title}>
            Données contacts
          </h1>
          <div className={styles.searchbar}>
            <input
              type="text"
              placeholder="Rechercher un contact par e-mail"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <Button
              label="Recherche en cours ..."
              disabled
            />
          </div>
        </header>
        <main className={styles.main}>
          <Loading />
        </main>
      </section>
    );
  }

  if (status === statuses.SUCCEEDED) {
    return (
      <section className={styles.contacts}>
        <header className={shared.header}>
          <h1 className={shared.title}>
            Données contacts
          </h1>
          <div className={styles.searchbar}>
            <input
              type="text"
              placeholder="Rechercher un contact par e-mail"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <Button
              label="Rechercher"
              onButtonClick={handleSearch}
            />
          </div>
        </header>
        <main className={styles.main}>
          <p className={styles.subtitle}>Liste des projets</p>
          <ul className={styles.projectList}>
            {
              projects.map((project) => (
                <li key={project}>
                  <button
                    type="button"
                    onClick={() => actions.setSelectedProject(project)}
                    className={`${(project === selectedProject) ? styles.selected : styles.button}`}
                  >
                    { project }
                  </button>
                </li>
              ))
            }
          </ul>
          {
            (details)
              ? <Details details={details} />
              : <p> Selectionner un projet </p>
          }

        </main>
      </section>
    );
  }

  if (status === statuses.FAILED) {
    return (
      <section className={styles.contacts}>
        <header className={shared.header}>
          <h1 className={shared.title}>
            Données contacts
          </h1>
          <div className={styles.searchbar}>
            <input
              type="text"
              placeholder="Rechercher un contact par e-mail"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <Button
              label="Rechercher"
              onButtonClick={handleSearch}
            />
          </div>
        </header>
        <main className={styles.main}>
          {
            (error === 'List index out of range')
              ? (
                <p className={styles.hint}>
                  Aucun contact correspondant n&apos;a été identifié
                </p>
              )
              : <p className={styles.hint}>{ error }</p>
          }
        </main>
      </section>
    );
  }

  return (
    <section className={styles.contacts}>
      <header className={shared.header}>
        <h1 className={shared.title}>
          Données contacts
        </h1>
        <div className={styles.searchbar}>
          <input
            type="text"
            placeholder="Rechercher un contact par e-mail"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <Button
            label="Rechercher"
            onButtonClick={handleSearch}
          />
        </div>
      </header>
      <main className={styles.main}>
        <p>
          Selectionner un contact
        </p>
      </main>
    </section>
  );
}
