import React from 'react';

import styles from './Homepage.module.css';

export default function Homepage() {
  return (
    <section className={styles.home}>
      <div className={styles.project}>
        <p>
          Sélectionner un projet pour accéder au gestionnaire d’import
        </p>
      </div>
    </section>
  );
}
