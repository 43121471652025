import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import { ArrowForward } from '@material-ui/icons';

import styles from './Searchbar.module.css';

export default function Searchbar({ filter }) {
  const [query, setQuery] = useState('');
  const [hasSearch, setHasSearch] = useState(false);
  const history = useHistory();

  const handleChange = (e) => {
    setQuery(e.target.value);
    filter(e.target.value);
    setHasSearch(true);
  };

  const clear = () => {
    setQuery('');
    filter('');
    setHasSearch(false);
  };

  const handleSearch = () => {
    history.push(`/${query}`);
    clear();
  };

  const handleOnEnter = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className={styles.searchbar}>
      <input
        type="number"
        className={styles.input}
        value={query || ''}
        placeholder="Rechercher un projet"
        onChange={handleChange}
        onKeyDown={handleOnEnter}
      />
      {
        (hasSearch)
          ? (
            <button
              className={styles.button}
              type="button"
              aria-label="Annuler la recherche"
              onClick={clear}
            >
              <ClearIcon className={styles.icon} />
            </button>
          )
          : (
            <button
              className={styles.button}
              type="button"
              aria-label="Accéder au projet"
              onClick={handleSearch}
            >
              <ArrowForward className={styles.icon} />
            </button>
          )
      }
    </div>
  );
}

Searchbar.propTypes = {
  filter: PropTypes.func.isRequired,
};
