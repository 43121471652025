import React, { useState } from 'react';
import { Search, Person } from '@material-ui/icons';

import Input from '../../../components/library/Input/InputText/InputText';
import Button from '../../../components/library/Button/Button';

import styles from './HistorySearch.module.css';
import HistorySearchDate from './HistorySearchDate';

const getDateFromSelection = (id) => {
  const end = new Date();
  const begin = new Date();
  if (id === 'lastHour') {
    begin.setHours(end.getHours() - 1);
    return { begin, end };
  }
  if (id === 'last24hours') {
    begin.setDate(end.getDate() - 1);
    return { begin, end };
  }
  if (id === 'yesterday') {
    begin.setDate(end.getDate() - 1);
    begin.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);
    return { begin, end };
  }
  return { begin: '', end: '' };
};

export default function HistorySearch({ children }) {
  const [request, setRequest] = useState('');
  const [authId, setAuthId] = useState('');
  const [date, setDate] = useState({ selected: 'lastHour', custom: getDateFromSelection('lastHour') });
  const [params, setParams] = useState({ request, authId, ...date.custom });

  return (
    <>
      <div className={styles.search}>
        <Input
          iconFirst={<Search />}
          placeholder="Rechercher par url..."
          className={styles.searchbar}
          valueInput={request}
          onTextChange={(e) => setRequest(e.target.value)}
        />
        <Input
          iconFirst={<Person />}
          placeholder="authId"
          className={styles.auth}
          valueInput={authId}
          onTextChange={(e) => setAuthId(e.target.value)}
        />
        <HistorySearchDate
          selected={date.selected}
          onChange={(e) => {
            setDate((newDate) => ({
              ...newDate,
              selected: e.target.id,
              custom: getDateFromSelection(e.target.id),
            }));
          }}
          date={date.custom}
          setDate={(custom) => {
            setDate((curr) => ({ ...curr, custom }));
          }}
        />
        <Button
          label="Lancer la recherche"
          className={styles.submit}
          onButtonClick={() => setParams({
            request, authId, begin: date?.custom?.begin, end: date?.custom?.end,
          })}
        />
      </div>
      { children(params) }
    </>
  );
}
