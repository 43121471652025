import React, { useEffect } from 'react';

import * as statuses from '../../../services/store/status';
import shared from '../../../styles/shared.module.css';

import styles from './ImportManagerHistory.module.css';

export default function ImportManagerHistory({ id, history, actions }) {
  useEffect(() => {
    if (history.status === statuses.IDLE) {
      actions.fetchHistory(id);
    }
    const interval = setInterval(() => actions.fetchHistory(id), 60 * 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <main className={`${styles.history}`}>
      <div className={`${shared.grid} ${styles.grid} ${shared.title}`}>
        <p>
          Time start
        </p>
        <p>
          Time end
        </p>
        <p>
          Before
        </p>
        <p>
          After
        </p>
        <p>
          Status
        </p>
        <p>
          Global
        </p>
        <p>
          Type
        </p>
        <p>
          Comment
        </p>
      </div>
      <ul className={`${shared.table} ${styles.list}`}>
        {
        history.data.map((task) => (
          <li
            key={task.time_start}
            className={`${shared.tableRow} ${styles.grid} ${styles.row}`}
          >
            <p>
              {task.time_start}
            </p>
            <p>
              {task.time_end}
            </p>
            <p>
              {task.before}
            </p>
            <p>
              {task.after}
            </p>
            <p>
              {task.status}
            </p>
            <p>
              {task.global}
            </p>
            <p>
              {task.task_type}
            </p>
            <p>
              {
                (task.task_type === 'blacklist' && task.nb_blacklist)
                  ? `nb_blacklist: ${task.nb_blacklist}`
                  : ''
              }
            </p>
          </li>
        ))
      }
      </ul>
    </main>
  );
}
