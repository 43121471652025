import * as types from './types';
import {
  getAllContactsByProjects, recalculate,
  updateContactById, blacklistContacts, unblacklistContacts, getSegmentData,
} from '../../../clients/lib/contacts';
import downloadDocument from '../../../../utils/downloadBlob';
import formatContact from '../../../../utils/formatContactForCsv';
import progressBarFactory from '../progressBar/factory';
import { addProgressBar, setProgressBarValues } from '../progressBar/actions';

export function fetchContactsByProjectId(id) {
  return async (dispatch) => {
    dispatch({
      type: types.FETCH_CONTACTS_REQUEST,
      payload: id,
    });
    try {
      const { data } = await getAllContactsByProjects(id);
      if (data.status === 'OK') {
        const languages = Array.from(new Set(data.data.map((contact) => contact.language)));
        dispatch({
          type: types.FETCH_CONTACTS_SUCCEEDED,
          payload: {
            contacts: data.data.map((contact, key) => ({ ...contact, id: key, isSelected: false })),
            id,
            languages,
          },
        });
      } else {
        dispatch({
          type: types.FETCH_CONTACTS_FAILED,
          payload: id,
        });
      }
    } catch (err) {
      dispatch({
        type: types.FETCH_CONTACTS_FAILED,
        payload: id,
      });
    }
  };
}

export function fetchSegmentInfoById(id) {
  return async (dispatch) => {
    dispatch({
      type: types.FETCH_SEGMENT_REQUEST,
      payload: id,
    });
    try {
      const { data } = await getSegmentData(id);
      if (data.status === 'OK') {
        dispatch({
          type: types.FETCH_SEGMENT_SUCCEEDED,
          payload: { id, data: data.data },
        });
      } else {
        dispatch({
          type: types.FETCH_SEGMENT_FAILED,
          payload: { err: data.msg, id },
        });
      }
    } catch (err) {
      dispatch({
        type: types.FETCH_SEGMENT_FAILED,
        payload: { err, id },
      });
    }
  };
}

export function setActiveProject(id) {
  return (dispatch) => {
    dispatch({
      type: types.SET_ACTIVE_PROJECT,
      payload: id,
    });
  };
}

export function setMailFilters(mail) {
  return (dispatch, getState) => {
    const { filters } = getState().contacts;
    const newFilters = {
      ...filters,
      owner: mail,
    };
    dispatch({
      type: types.SET_FILTERS,
      payload: newFilters,
    });
  };
}

export function setQuery(value) {
  return (dispatch) => {
    dispatch({
      type: types.SET_QUERY,
      payload: value,
    });
  };
}

export function setFilters(filters) {
  return (dispatch) => {
    dispatch({
      type: types.SET_FILTERS,
      payload: filters,
    });
  };
}

export function setSort(category) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SORT,
      payload: category,
    });
  };
}

export function next() {
  return (dispatch) => {
    dispatch({
      type: types.SET_NEXT,
    });
  };
}

const SIX_MONTHS = 187;
const EMPTY_FILTER = -1;

function getFilteredContacts(contacts, filters) {
  let newContacts = contacts;
  if (!filters) {
    return newContacts;
  }
  if (filters.segment !== EMPTY_FILTER) {
    newContacts = newContacts
      .filter((contact) => contact.segment === filters.segment);
  }
  if (filters.proximity !== EMPTY_FILTER) {
    newContacts = newContacts
      .filter((contact) => (
        contact.proximity.threshold === ((filters.proximity !== 1) ? filters.proximity : -1)));
  }
  if (filters.owner !== EMPTY_FILTER) {
    newContacts = newContacts
      .filter((contact) => contact.issuer === filters.owner);
  }
  if (filters.amount !== EMPTY_FILTER) {
    newContacts = newContacts
      .filter((contact) => {
        if (filters.amount === 0) {
          return (contact.amount || 0) === filters.amount;
        }
        return (contact.amount || 0) > filters.amount;
      });
  }
  if (filters.language !== EMPTY_FILTER) {
    newContacts = newContacts
      .filter((contact) => contact.language === filters.language);
  }
  if (filters.exchange !== EMPTY_FILTER) {
    if (filters.exchange !== 0) {
      newContacts = newContacts
        .filter((contact) => contact.exchanges_len > filters.exchange);
    } else {
      newContacts = newContacts
        .filter((contact) => contact.exchanges_len === filters.exchange);
    }
  }
  /* Date comparaison
   * https://stackoverflow.com/questions/492994/compare-two-dates-with-javascript
   */
  if (filters.received !== EMPTY_FILTER) {
    if (filters.received === 0) {
      newContacts = newContacts
        .filter((contact) => new Date(contact.lastDateReceivedByIssuer).getTime() === new Date('1970-01-01 00:00:00').getTime());
    } else if (filters.received === SIX_MONTHS) {
      newContacts = newContacts
        .filter((contact) => {
          const today = new Date();
          today.setDate(today.getDate() - filters.received);
          return new Date(contact.lastDateReceivedByIssuer).getTime() < today.getTime();
        });
    } else {
      newContacts = newContacts
        .filter((contact) => {
          const today = new Date();
          today.setDate(today.getDate() - filters.received);
          return new Date(contact.lastDateReceivedByIssuer).getTime() > today.getTime();
        });
    }
  }
  if (filters.send !== EMPTY_FILTER) {
    if (filters.received === 0) {
      newContacts = newContacts
        .filter((contact) => new Date(contact.lastDateSentByIssuer).getTime() === new Date('1970-01-01 00:00:00').getTime());
    } else if (filters.received === SIX_MONTHS) {
      newContacts = newContacts
        .filter((contact) => {
          const today = new Date();
          today.setDate(today.getDate() - filters.received);
          return new Date(contact.lastDateSentByIssuer).getTime() < today.getTime();
        });
    } else {
      newContacts = newContacts
        .filter((contact) => {
          const today = new Date();
          today.setDate(today.getDate() - filters.received);
          return new Date(contact.lastDateSentByIssuer).getTime() > today.getTime();
        });
    }
  }
  if (filters.subscribe !== EMPTY_FILTER) {
    newContacts = newContacts
      .filter((contact) => contact.mailUnsubscribe === filters.subscribe);
  }
  return newContacts;
}

export function downloadFilteredContacts() {
  return async (dispatch, getState) => {
    dispatch({
      type: types.DOWNLOAD_CONTACTS_REQUEST,
    });
    try {
      /* Download file :
       * https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
       */
      const { active } = getState().sources;
      const { filters, contacts } = getState().contacts;
      const activeContacts = getFilteredContacts(
        contacts.find((project) => project.id === active).data,
        filters,
      ).map((contact) => formatContact(contact));
      const contactsRows = activeContacts.map((contact) => Object.values(contact));
      const csvContent = `${Object.keys(activeContacts[0]).join(';')}\n${contactsRows.map((e) => e.join(';')).join('\n')}`;
      downloadDocument(csvContent);
      dispatch({
        type: types.DOWNLOAD_CONTACTS_SUCCEEDED,
      });
    } catch (err) {
      dispatch({
        type: types.DOWNLOAD_CONTACTS_FAILED,
        payload: err,
      });
    }
  };
}

export function recalculateContact() {
  return async () => {
    try {
      await recalculate();
    } catch (err) {
      console.error(err);
    }
  };
}

export function resetFilters() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_FILTERS,
    });
  };
}

const getProgress = (promises, callback, dispatch, progressBar) => {
  let progress = 0;
  callback(progress);
  promises.forEach((p) => {
    p.then(() => {
      progress += 1;
      dispatch(setProgressBarValues(progressBar.id, (progress * 100) / promises.length));
    });
  });
  return Promise.all(promises);
};

export function blacklistContact(id, arrayOfContacts) {
  return async (dispatch) => {
    dispatch({
      type: types.BLACKLIST_CONTACTS_REQUEST,
    });
    try {
      const results = [];
      const contactsList = [...arrayOfContacts];
      const progressBar = progressBarFactory({ name: 'Blacklist', size: arrayOfContacts.length, unit: ' contacts' });
      dispatch(addProgressBar(progressBar));
      while (arrayOfContacts.length) {
        results.push(blacklistContacts(id, arrayOfContacts.splice(0, 10)));
      }
      const responses = await getProgress(
        results,
        (progress) => dispatch({ type: types.BLACKLIST_CONTACTS_PROGRESSION, payload: progress }),
        dispatch,
        progressBar,
      );
      const status = responses.reduce((acc, response) => {
        if (acc === 'KO') return 'KO';
        if (response.data.status === 'OK') {
          return 'OK';
        }
        return 'KO';
      }, 'OK');
      if (status === 'OK') {
        dispatch({
          type: types.BLACKLIST_CONTACTS_SUCCEEDED,
          payload: contactsList,
        });
      } else {
        dispatch({
          type: types.BLACKLIST_CONTACTS_FAILED,
          payload: responses.find((response) => response.data.status === 'KO').msg,
        });
      }
    } catch (err) {
      dispatch({
        type: types.BLACKLIST_CONTACTS_FAILED,
        payload: err,
      });
    }
  };
}

export function unblacklistContact(id, arrayOfContacts) {
  return async (dispatch) => {
    dispatch({
      type: types.UNBLACKLIST_CONTACTS_REQUEST,
    });
    try {
      const results = [];
      const contactsList = [...arrayOfContacts];
      const progressBar = progressBarFactory({ name: 'Blacklist', size: arrayOfContacts.length, unit: ' contacts' });
      dispatch(addProgressBar(progressBar));
      while (arrayOfContacts.length) {
        results.push(unblacklistContacts(id, arrayOfContacts.splice(0, 10)));
      }
      const responses = await getProgress(
        results,
        (progress) => dispatch({ type: types.BLACKLIST_CONTACTS_PROGRESSION, payload: progress }),
        dispatch,
        progressBar,
      );
      const status = responses.reduce((acc, response) => {
        if (acc === 'KO') return 'KO';
        if (response.data.status === 'OK') {
          return 'OK';
        }
        return 'KO';
      }, 'OK');
      if (status === 'OK') {
        dispatch({
          type: types.UNBLACKLIST_CONTACTS_SUCCEEDED,
          payload: contactsList,
        });
      } else {
        dispatch({
          type: types.UNBLACKLIST_CONTACTS_FAILED,
          payload: responses.find((response) => response.data.status === 'KO').msg,
        });
      }
    } catch (err) {
      dispatch({
        type: types.UNBLACKLIST_CONTACTS_FAILED,
        payload: err,
      });
    }
  };
}

export function blacklistContactsSelection(id) {
  return (dispatch, getState) => {
    const { contacts, active } = getState().contacts;
    const selectedContacts = contacts
      .find((list) => list.id === active).data
      .filter((contact) => contact.isSelected === true)
      .map((contact) => contact.emailGeneral);
    if (selectedContacts.length > 0) {
      dispatch(blacklistContact(id, selectedContacts));
    }
  };
}

export function unblacklistContactsSelection(id) {
  return (dispatch, getState) => {
    const { contacts, active } = getState().contacts;
    const selectedContacts = contacts
      .find((list) => list.id === active).data
      .filter((contact) => contact.isSelected === true)
      .map((contact) => contact.emailGeneral);
    if (selectedContacts.length > 0) {
      dispatch(unblacklistContact(id, selectedContacts));
    }
  };
}

export function updateContact(id, contact) {
  return async (dispatch) => {
    dispatch({
      type: types.UPDATE_CONTACTS_REQUEST,
    });
    try {
      const { data } = await updateContactById(id, contact.emailGeneral, contact);
      if (data.status === 'OK') {
        dispatch({
          type: types.UPDATE_CONTACTS_SUCCEEDED,
          payload: { id: Number.parseInt(id, 10), contact },
        });
      } else {
        dispatch({
          type: types.UPDATE_CONTACTS_FAILED,
          payload: data.msg,
        });
      }
    } catch (err) {
      dispatch({
        type: types.UPDATE_CONTACTS_FAILED,
        payload: err,
      });
    }
  };
}

export function selectContactById(id, contact) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_CONTACTS_SUCCEEDED,
      payload: { id: Number.parseInt(id, 10), contact },
    });
  };
}

export function getCurrentContacts(state) {
  const {
    active, contacts, query, filters, sort,
  } = state.contacts;
  if (!contacts) return undefined;
  let currentContacts = contacts.find((list) => list.id === active)?.data || [];
  if (!currentContacts) return undefined;
  if (query) {
    currentContacts = currentContacts.filter((contact) => {
      let contactToStr = `${contact.emailGeneral} ${contact.firstName} ${contact.lastName} ${contact.segment}`;
      contactToStr = contactToStr.toLowerCase();
      return (contactToStr.search(query.toLowerCase()) !== -1);
    });
  }
  currentContacts = getFilteredContacts(currentContacts, filters);
  currentContacts = currentContacts.sort((a, b) => {
    if (sort.category !== 'proximity') {
      const asc = Number.parseFloat(a[sort.category]) - Number.parseFloat(b[sort.category]);
      return (sort.order === 'asc')
        ? asc
        : Number.parseFloat(b[sort.category]) - Number.parseFloat(a[sort.category]);
    }
    const asc = a.proximity.threshold - b.proximity.threshold;
    return (sort.order === 'asc')
      ? asc
      : b.proximity.threshold - a.proximity.threshold;
  });
  return currentContacts;
}

export function blacklistFilteredContactList(id) {
  return (dispatch, getState) => {
    const arrayOfContacts = getCurrentContacts(getState()).map((contact) => contact.emailGeneral);
    dispatch(blacklistContact(id, arrayOfContacts));
  };
}

export function unblacklistFilteredContactList(id) {
  return (dispatch, getState) => {
    const arrayOfContacts = getCurrentContacts(getState()).map((contact) => contact.emailGeneral);
    dispatch(unblacklistContact(id, arrayOfContacts));
  };
}

export function selectAll(e) {
  return (dispatch, getState) => {
    const bool = e.target.checked;
    const { contacts, active } = getState().contacts;
    const allContacts = contacts.find((list) => list.id === active).data;
    const currentContacts = getCurrentContacts(getState()).map((i) => i.id);
    const newContactList = allContacts.map((contact) => {
      if (currentContacts.includes(contact.id)) {
        return {
          ...contact,
          isSelected: bool,
        };
      }
      return contact;
    });
    dispatch({
      type: types.SET_SELECT_ALL,
      payload: { bool, contacts: newContactList },
    });
  };
}
