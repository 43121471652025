import React, { useState, useEffect } from 'react';
import AccountLoading from '../../../components/library/Loading';
import { getIntraday } from '../../../services/clients/lib/import';
import styles from './IntradayManager.module.css';

export default function IntradayManager({ children }) {
  const [status, setStatus] = useState('IDLE');
  const [intraday, setIntraday] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const day = new Date();
      try {
        const { data, statusText, status: code } = await getIntraday(`${day.getDate()}-${(day.getMonth() < 10) ? 0 : ''}${day.getMonth() + 1}-${day.getFullYear()}`);
        if (data.status === 'OK') {
          setIntraday(data.data);
          setStatus('SUCCESS');
        } else {
          setStatus('ERROR');
          setIntraday({ msg: data?.msg, statusText, code });
        }
      } catch (err) {
        setStatus('ERROR');
        setIntraday({ statusText: `${err}` });
      }
    };
    if (status === 'IDLE') {
      fetchData();
    }
  }, []);

  if (status === 'IDLE' || status === 'LOADING') {
    return <AccountLoading />;
  }

  if (status === 'ERROR') {
    return (
      <div className={styles.error}>
        <p className={styles.bold}>Impossible de réccupérer l&apos;intraday</p>
        <p className={styles.description}>{`${intraday?.msg} ${intraday?.code} - ${intraday?.statusText} `}</p>
      </div>
    );
  }

  return (
    <>
      {children(intraday)}
    </>
  );
}
