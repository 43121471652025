import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ImportManagerContact from './ImportManagerContact';
import {
  fetchContactsByProjectId, setActiveProject, getCurrentContacts, next,
  updateContact, selectAll, selectContactById, setSort,
} from '../../../services/store/features/contacts/actions';

const mapStateToProps = (state) => {
  const contacts = state.contacts?.contacts?.find((c) => c.id === state.contacts.active);
  return ({
    status: contacts?.status,
    error: contacts?.error,
    total: getCurrentContacts(state).length,
    contacts: getCurrentContacts(state)
      ?.slice(0, state.contacts.page * state.contacts.contactsPerPage),
    accounts: state?.sources?.sources
      ?.find((project) => project.id === state.contacts.active)?.accounts
      ?.reduce((acc, curr) => [...acc, ...curr.issuer?.map((i) => i.id)], []) || [],
    languages: state.contacts.contacts
      .find((project) => project.id === state.contacts.active)?.languages || [],
    hasMore: (state.contacts.page * state.contacts.contactsPerPage < (contacts?.total || 0)),
    isAllSelected: getCurrentContacts(state).length === getCurrentContacts(state)
      .filter((contact) => contact.isSelected === true).length,
  });
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchContactsByProjectId,
    setActiveProject,
    next,
    updateContact,
    selectAll,
    selectContactById,
    setSort,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportManagerContact);
