import React, { useEffect } from 'react';
import Proptypes from 'prop-types';

import ImportManagerSourceTableTitle from './ImportManagerSourceTableTitle';
import ImportManagerSourceTableRows from './ImportManagerSourceTableRows';

import styles from './ImportManagerSourceTable.module.css';
import * as statuses from '../../../../services/store/status';

export default function ImportManagerSourceTable({
  id, contacts, source, handleDelete, actions,
}) {
  useEffect(() => {
    if (contacts?.status === statuses.IDLE) {
      actions.fetchContactsByProjectId(id);
    }
  }, [contacts]);

  return (
    <div className={styles.table}>
      <div className={styles.tableOverflow}>
        <ImportManagerSourceTableTitle
          source={source}
        />
        {
          (contacts?.status === statuses.LOADING)
          && (
            <div className={styles.loadingContainer}>
              <div className={styles.loader} />
              <div className={styles.loadingText}>Chargement des contacts</div>
            </div>
          )
        }
        {
          (contacts.status === statuses.SUCCEEDED)
          && (
            <ImportManagerSourceTableRows
              source={source}
              handleDelete={handleDelete}
              id={id}
            />
          )
        }
      </div>
    </div>
  );
}

ImportManagerSourceTableRows.propTypes = {
  source: Proptypes.shape({
    accounts: Proptypes.arrayOf(
      Proptypes.shape({
        account: Proptypes.string,
        blacklist: Proptypes.number,
        mails: Proptypes.arrayOf(
          Proptypes.shape({
            mail: Proptypes.string,
            contacts: Proptypes.number,
            type: Proptypes.string,
            status: Proptypes.string,
            progress: Proptypes.number,
          }),
        ),
      }),
    ),
  }).isRequired,
};
