import React from 'react';

import RowsAccount from './RowsAccount';

export default function Rows({ source }) {
  return (
    <div>
      {
        source.accounts.map((account) => (
          <RowsAccount
            key={account.email}
            account={account}
          />
        ))
      }
    </div>
  );
}
