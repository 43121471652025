import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ImportManagerPotentials from './ImportManagerPotentials';
import { setActiveSource, fetchSourcesById } from '../../../services/store/features/sources/actions';

const mapStateToProps = (state) => {
  const active = state.sources?.active;
  const activeSource = state.sources.sources?.find((source) => source.id === active);
  return ({
    source: activeSource,
    query: state.sources.query,
    isModalOpen: state.sources.isUploading,
    percentCompleted: state.sources.percentCompleted,
  });
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setActiveSource,
    fetchSourcesById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportManagerPotentials);
