import downloadDocument from '../../../../utils/downloadBlob';
import * as types from './types';
import { getAllContactsByProjects } from '../../../clients/lib/contacts';
import * as contactTypes from '../contacts/types';

export const createAndDownloadCsv = ({ dispatch, activeContacts }) => {
  /* Download file :
       * https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
       */
  const contactsRows = activeContacts
    .map((contact) => Object.values(contact));
  const csvContent = `${Object.keys(activeContacts[0]).join(',')}\n${contactsRows.map((e) => e.join(',')).join('\n')}`;
  downloadDocument(csvContent);
  dispatch({
    type: types.DOWNLOAD_CONTACTS_SUCCEEDED,
  });
};

export const fetchAllContactsByProjects = async ({ project, dispatch, callback }) => {
  try {
    const { data } = await getAllContactsByProjects(project);
    if (data.status === 'OK') {
      const languages = Array.from(new Set(data.data.map((contact) => contact.language)));
      dispatch({
        type: contactTypes.FETCH_CONTACTS_SUCCEEDED,
        payload: {
          contacts: data.data.map((contact, key) => ({ ...contact, id: key })),
          languages,
        },
      });
      callback();
    } else {
      dispatch({ type: contactTypes.FETCH_CONTACTS_FAILED, payload: data.msg });
    }
  } catch (err) {
    dispatch({ type: contactTypes.FETCH_CONTACTS_FAILED, payload: err });
  }
};

export const getSourcelistAfterFileUpload = ({ state, account, files }) => {
  const { sources } = state.sources;
  const activeSource = sources.find((source) => source.id === sources.active);
  const newSource = {
    ...activeSource,
    accounts: activeSource.accounts.map((currentAccount) => (
      (currentAccount.email === account)
        ? {
          ...currentAccount,
          issuer: [
            ...currentAccount.issuer,
            {
              mail: files.name,
              contacts: 0,
              type: files.name.split('.').pop(),
              status: 'waiting',
              progress: 1,
            },
          ],
        }
        : currentAccount
    )),
  };
  return sources.map((source) => ((source.id === activeSource.id) ? newSource : source));
};

export const deleteMailFromAccount = (mail, account, accounts) => {
  const selectedAccount = accounts.find((a) => a.account === account);
  const accountIndex = accounts.map((a) => a.account).indexOf(account);
  const { mails } = selectedAccount;
  const selectedMail = mails.find((m) => m.mail === mail.mail);
  return {
    list: [
      ...accounts.slice(0, accountIndex),
      {
        ...selectedAccount,
        mails: selectedAccount.mails.filter((m) => m.mail !== mail.mail),
      },
      ...accounts.slice(accountIndex + 1),
    ],
    selectedMail,
  };
};

export const getSourceWithUpdatedOwner = ({
  sources, mail, oldAccount, newAccount, active,
}) => {
  const source = sources.find((s) => s.id === active);
  const { accounts } = source;
  const deletedMail = deleteMailFromAccount(mail, oldAccount, accounts);
  let { list } = deletedMail;
  const { selectedMail } = deletedMail;
  list = list.map((l) => (
    (l.account === newAccount)
      ? {
        ...l,
        mails: [
          ...l.mails,
          {
            ...selectedMail,
            status: 'loading',
            progress: 0,
          },
        ],
      }
      : l
  ));
  return {
    ...source,
    isUpdating: false,
    accounts: list,
  };
};
