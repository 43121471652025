import React, { useState, useRef } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import LoopIcon from '@material-ui/icons/Loop';
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddCircle from '@material-ui/icons/AddCircle';
import { ExpandMore, ExpandLess, Storage } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import AccountDetails from '../AccountDetails';
import styles from '../../../ImportManagerSourceTable.module.css';
import useOutsideClickAlert from '../../../../../../../components/library/hook/clickOutside';
import Dropdown from '../../../../../../../components/Dropdown';
import ServerImapPopUp from '../ServerImapPopUp/index';
import { statusArray } from '../../../../../../../utils/statusList';
import stylesEdit
  from '../../../../../../Global/GlobalTable/GlobalTableRow/GlobalTableRowEdit/GlobalTableRowEdit.module.css';
import * as statuses from '../../../../../../../services/store/status';
import { setImportStatus } from '../../../../../../../services/clients/lib/import';
import { addToast } from '../../../../../../../services/store/features/toasts/actions';

const PROGRESS_STATE = {
  waiting: {
    label: 'En attente',
    style: styles.waiting,
  },
  end: {
    label: 'Terminé',
    style: styles.done,
  },
  run: {
    label: 'En cours',
    style: styles.loading,
  },
};

export default function ImportManagerSourceTableRowsAccountMailsRow({
  mail, id, accounts, actions, open, handleDelete,
}) {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(mail?.account);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const [status, setStatus] = useState(mail?.status);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isServeurImapOpen, setIsServeurImapOpen] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('IDLE');

  const optionsRef = useRef();
  const dropdown = useRef();
  const dispatch = useDispatch();

  useOutsideClickAlert(optionsRef, () => setIsMoreOpen(false));
  useOutsideClickAlert(dropdown, () => setIsStatusOpen(false));

  const getOptionButtons = () => {
    const {
      btnStatus, id: value, account: selected, source, type,
    } = mail;
    let moreButtons = [
      {
        label: 'Flush',
        styles: styles.optionButton,
        action: () => {
          actions.flushByImportId(mail.importId);
          setIsMoreOpen(false);
        },
        icon: <Storage className={styles.icon} />,
      },
      {
        label: 'Supprimer la source',
        styles: styles.importButton,
        action: () => {
          handleDelete({
            status: true,
            function: () => actions.deleteSourceFromAccount(value, selected),
          });
        },
        icon: <DeleteIcon className={styles.icon} />,
      },
      {
        label: 'Supprimer les contacts',
        styles: styles.importButton,
        action: () => {
          handleDelete({
            status: true,
            function: () => actions.deleteSourceFromAccount(value, selected),
          });
        },
        icon: <DeleteIcon className={styles.icon} />,
      },
    ];
    if (source === 'IMAP') {
      moreButtons = [
        {
          label: 'Ajouter imap serveur',
          styles: styles.optionButton,
          action: () => {
            setIsServeurImapOpen(true);
            setIsMoreOpen(false);
          },
          icon: <AddCircle className={styles.icon} />,
        },
        ...moreButtons,
      ];
    }
    if (btnStatus === 'waiting') {
      return moreButtons;
    }
    if (btnStatus === 'run') {
      return [
        {
          label: 'Details',
          styles: styles.optionButton,
          action: () => {
            setIsDetailsOpen(true);
            setIsMoreOpen(false);
          },
          icon: <VisibilityIcon className={styles.icon} />,
        },
        ...moreButtons,
      ];
    }
    return [
      {
        label: 'Relancer',
        styles: styles.optionButton,
        action: () => {
          actions.importContactsByMail(value, selected, type);
          setIsMoreOpen(false);
        },
        icon: <LoopIcon className={styles.icon} />,
      },
      {
        label: 'Details',
        styles: styles.optionButton,
        action: () => {
          setIsDetailsOpen(true);
          setIsMoreOpen(false);
        },
        icon: <VisibilityIcon className={styles.icon} />,
      },
      {
        label: 'Télécharger',
        styles: (mail.contacts < 1) ? styles.disabled : styles.optionButton,
        action: () => actions.downloadAllContactFromMail(value),
        icon: <GetAppIcon className={styles.icon} />,
      },
      ...moreButtons,
    ];
  };

  const close = () => {
    setIsEdit(false);
    setSubmitStatus('IDLE');
  };

  const handleSubmit = async () => {
    if (mail.status === 'done' && mail?.account !== selectedAccount) {
      actions.modifySourceOwner(mail, id, selectedAccount);
    }
    if (status !== mail.status) {
      setSubmitStatus(statuses.LOADING);
      try {
        const { data } = await setImportStatus(mail.importId, status);
        actions.setImportStatus(selectedAccount, mail.importId, status);
        if (data.status !== 'OK') {
          dispatch(addToast({
            type: 'error',
            title: 'Impossible de modifier le statut',
            description: data.msg,
          }));
          setStatus(mail.status);
        }
      } catch (err) {
        dispatch(addToast({
          type: 'error',
          title: 'Impossible de modifier le statut',
          description: `${err}`,
        }));
        setStatus(mail.status);
      } finally {
        setSubmitStatus('IDLE');
        close();
      }
    }
  };

  const navigateToContact = () => {
    actions.setMailFilters(mail.id);
    open();
  };

  return (
    <div>
      {
        (isDetailsOpen) && (
          <AccountDetails
            importId={mail.importId}
            close={() => setIsDetailsOpen(false)}
          />
        )
      }
      {
        (isServeurImapOpen) && (
          <ServerImapPopUp
            importId={mail.importId}
            close={() => setIsServeurImapOpen(false)}
          />
        )
      }
      <li
        key={mail.mail}
        className={`${styles.grid} ${styles.row} ${styles.relative}`}
      >
        <div />
        <button
          type="button"
          className={styles.mail}
          onClick={navigateToContact}
        >
          { mail.id }
        </button>
        <p>{ mail.contacts }</p>
        <p>{ mail.blacklist }</p>
        <p className={(mail.blacklist < ((mail.contacts !== 0) ? ((100 * mail.blacklisted) / mail.contacts).toFixed(2) : 0)) ? styles.blacklistWarn : ''}>
          { `${(mail.contacts !== 0) ? ((100 * mail.blacklisted) / mail.contacts).toFixed(2) : 0}%` }
        </p>
        <p>{ mail.source }</p>
        {
          (!isEdit)
            ? (
              <button
                type="button"
                className={styles.owner}
                onDoubleClick={() => setIsEdit(true)}
              >
                { selectedAccount }
              </button>
            )
            : (
              <select
                value={selectedAccount}
                className={(mail.status !== 'done') ? styles.disabled : ''}
                onChange={(e) => setSelectedAccount(e.target.value)}
              >
                {
                  (accounts) && accounts.map((account) => (
                    <option
                      key={account}
                      value={account}
                    >
                      { account }
                    </option>
                  ))
                }
              </select>
            )
        }
        {
            (!isEdit)
              ? (
                <button
                  type="button"
                  className={styles.status}
                  onDoubleClick={() => setIsEdit(true)}
                >
                  <span className={PROGRESS_STATE[status]?.style || styles.error} />
                  <p>{`${PROGRESS_STATE[status]?.label || status} ${(status === 'run') ? `( ${mail.global}% )` : ''}`}</p>
                </button>
              )
              : (
                <div className={styles.relative}>
                  <button
                    type="button"
                    className={styles.select}
                    onClick={() => setIsStatusOpen((state) => !state)}
                  >
                    <span>
                      {`${PROGRESS_STATE[status]?.label || status}`}
                    </span>
                    {
                      (!isStatusOpen)
                        ? <ExpandMore className={styles.icon} />
                        : <ExpandLess className={styles.icon} />
                    }
                  </button>
                  {
                    (isStatusOpen)
                    && (
                      <div
                        ref={dropdown}
                        className={styles.statusDropdown}
                      >
                        <p className={stylesEdit.label}>
                          Sélectionnez un status :
                        </p>
                        <div className={stylesEdit.buttonGroup}>
                          {
                            statusArray.map((currentStatus) => (
                              <button
                                type="button"
                                key={currentStatus.value}
                                className={`${stylesEdit.button} ${stylesEdit[currentStatus.color]}`}
                                onClick={() => {
                                  setStatus(currentStatus.value);
                                }}
                              >
                                { currentStatus.value }
                              </button>
                            ))
                          }
                        </div>
                      </div>
                    )
                  }
                </div>
              )
          }
        {
          (!isEdit)
            ? (
              <div ref={optionsRef}>
                <button
                  type="button"
                  className={styles.button}
                  onClick={() => setIsMoreOpen((state) => !state)}
                >
                  <MoreVertIcon />
                </button>
                {
                  (isMoreOpen) && <Dropdown buttons={getOptionButtons()} />
                }
              </div>
            )
            : (
              <button
                type="button"
                className={styles.button}
                onClick={handleSubmit}
                disabled={submitStatus === 'LOADING'}
              >
                {
                  (submitStatus === 'LOADING')
                    ? <FontAwesomeIcon icon={faSpinner} spin />
                    : <CheckIcon />
                }
              </button>
            )
        }
      </li>
    </div>
  );
}
