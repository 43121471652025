import React, { useEffect } from 'react';

import Loading from '../../components/library/Loading';
import BlacklistType from '../../components/BlacklistType';
import * as status from '../../services/store/status';

import shared from '../../styles/shared.module.css';
import styles from './Blacklist.module.css';

export default function Blacklist({ blacklist, actions }) {
  useEffect(() => {
    if (blacklist.status === status.IDLE) {
      actions.fetchGlobaLBlacklist();
    }
  }, []);
  if (blacklist.status === status.LOADING) {
    return (
      <section className={shared.section}>
        <header className={shared.header}>
          <h1 className={shared.title}>
            Blacklist globale
          </h1>
        </header>
        <main>
          <Loading />
        </main>
      </section>
    );
  }
  if (blacklist.status === status.FAILED) {
    return (
      <section className={shared.section}>
        <header className={shared.header}>
          <h1 className={shared.title}>
            Blacklist globale
          </h1>
        </header>
        <main>
          <p>
            Une erreur est survenue lors du chargement des données
          </p>
          <p>
            { `${blacklist.error}` }
          </p>
        </main>
      </section>
    );
  }
  if (blacklist.status === status.SUCCEEDED) {
    return (
      <section className={shared.section}>
        <header className={shared.header}>
          <h1 className={shared.title}>
            Blacklist globale
          </h1>
        </header>
        <main className={styles.main}>
          <BlacklistType
            type="DOMAIN"
            blacklist={blacklist?.data?.filter((d) => d.type === 'DOMAIN')}
            actions={{
              blacklist: actions.blacklistGlobalByType,
              unblacklist: actions.unblacklistGlobalByType,
            }}
            status={blacklist.domain}
          />
          <BlacklistType
            type="PREFIX"
            blacklist={blacklist?.data?.filter((d) => d.type === 'PREFIX')}
            actions={{
              blacklist: actions.blacklistGlobalByType,
              unblacklist: actions.unblacklistGlobalByType,
            }}
            status={blacklist.prefix}
          />
          <BlacklistType
            type="EMAIL"
            blacklist={blacklist?.data?.filter((d) => d.type === 'EMAIL')}
            actions={{
              blacklist: actions.blacklistGlobalByType,
              unblacklist: actions.unblacklistGlobalByType,
            }}
            status={blacklist.email}
          />
        </main>
      </section>
    );
  }
  return (
    <section className={shared.section}>
      <header className={shared.header}>
        <h1 className={shared.title}>
          Blacklist globale
        </h1>
      </header>
      <p>
        Blacklist
      </p>
    </section>
  );
}
