import { useEffect, useState } from 'react';
import { getDetailsByImportId } from '../../../services/clients/lib/sources';
import { rerunImportByMail } from '../../../services/clients/lib/import';

export default function AccountDetailsManager({ importId, children }) {
  const [details, setDetails] = useState({ status: 'IDLE', data: {}, error: null });
  const [relaunch, setRelaunch] = useState('IDLE');
  const [error, setError] = useState();

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const { data } = await getDetailsByImportId(importId);
        if (data.status === 'OK') {
          setDetails({ status: 'SUCCESS', data: data.data, error: null });
        } else {
          setDetails({ status: 'ERROR', data: null, error: data.msg });
        }
      } catch (err) {
        setDetails({ status: 'ERROR', data: null, error: err });
      }
    };
    fetchDetails();
  }, []);

  const handleRelaunch = async () => {
    setRelaunch('LOADING');
    try {
      const { data } = await rerunImportByMail({
        id: details.data?.projectId,
        account: details.data?.details?.email,
        issuer: details.data?.details?.id,
        type: details.data?.details?.type,
      });
      if (data.status === 'OK') {
        setRelaunch('SUCCESS');
      } else {
        setRelaunch('FAIL');
        setError(data.msg || 'Une erreur est survenue');
      }
    } catch (err) {
      setRelaunch('FAIL');
      setError(`${err || 'ERREUR'}`);
    }
  };

  return (children({
    details, relaunch, error, handleRelaunch,
  }));
}
